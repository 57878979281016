.button {
  align-items: center;
  background-color: var(--color-white);
  border: var(--dimension-border) solid var(--color-white);
  color: var(--color-black);
  cursor: pointer;
  display: inline-flex;
  height: var(--dimension-button);
  justify-content: space-between;
  outline: 0;
  padding-left: var(--space-between-small);
  padding-right: var(--space-between-small);
  position: relative;
}

.button:hover {
  background: none;
  background-color: var(--color-black);
  border: var(--dimension-border) solid var(--color-white);
  color: var(--color-white);
}

.button--center {
  justify-content: center;
  text-align: center;
}

.button:disabled {
  opacity: 0.25;
  pointer-events: none;
}

.button__content {
  display: inline-flex;
  white-space: nowrap;
}

.button__content:not(:first-child) {
  margin-left: var(--grid-row-half-gutter);
}

@media screen and (min-width: 1024px) {
  .button__content {
    white-space: normal;
  }
}

@media screen and (min-width: 1440px) {
  .button__content {
    white-space: nowrap;
  }
}
