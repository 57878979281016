.section__pre-title {
  display: flex;
  flex-direction: column;
}

.section__header {
  margin-bottom: var(--space-between-small);
}

.section__separator .column,
.section__content {
  width: 100%;
}

.section__separator {
  margin-bottom: var(--space-between-small);
  margin-top: var(--space-between-xxsmall);
}

@media screen and (min-width: 1280px) {
  .section__separator {
    margin-bottom: 0;
    margin-top: 0;
    order: -1;
  }
}
