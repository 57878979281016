.resources__hero {
  margin-bottom: var(--space-between-xxlarge);
}

.resources__list {
  margin-bottom: calc(var(--space-between-xlarge) * -1);
}

.resources__list-item {
  margin-bottom: var(--space-between-xlarge);
}

.resources__generic-items {
  margin-top: var(--space-between-xxlarge);
}

.resources__filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 12px;
  gap: 12px;
  margin-bottom: var(--space-between-large);
}
