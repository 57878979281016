.homepage__element {
  margin-bottom: var(--space-between-xxlarge);
}

.homepage__element-content {
  padding-top: var(--space-between-medium);
  position: relative;
}

.homepage__element-content::before {
  background: var(--color-white);
  content: '';
  height: 1px;
  left: var(--grid-row-half-gutter);
  position: absolute;
  top: 0;
  width: calc(100% - var(--grid-row-gutter));
}
