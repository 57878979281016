.slider {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.slider--with-gradient {
  overflow: hidden;
  position: relative;
}

.slider__carousel {
  outline: none;
  padding-bottom: var(--space-between-small);
}

.slider .is-fullscreen {
  padding-bottom: 0;
}

.slider__card {
  height: auto;
  margin-right: var(--grid-row-quarter-gutter);
  max-width: 100vw;
}

.slider__card--no-margin {
  margin-right: 0;
}

.body .flickity-enabled.is-fullscreen {
  overflow: hidden;
}

.slider .flickity-enabled.is-fullscreen {
  background: var(--color-black);
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}

.slider .flickity-enabled.is-fullscreen .slider__card {
  height: 100vh;
}

.slider .flickity-enabled.is-fullscreen .video-component {
  margin: auto;
  width: auto;
}

.slider .flickity-enabled.is-fullscreen .video-component iframe {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.slider .flickity-viewport {
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: height 0.2s;
}

.slider--with-gradient .flickity-viewport {
  overflow: visible;
}

.slider .is-fullscreen .flickity-viewport {
  height: 100vh;
}

.flickity-fullscreen-button-view,
.flickity-fullscreen-button-exit {
  display: none;
}

.slider__carousel-nav .slider__card {
  width: 50%;
}

.slider__buttons {
  display: flex;
  margin-bottom: var(--space-between-xxsmall);
  width: calc(var(--dimension-button) * 2);
}

.slider__buttons .button {
  height: var(--dimension-button);
  width: var(--dimension-button);
}

.slider__buttons .button:first-child {
  border-right: none;
}

.slider__fullscreen {
  position: absolute;
  right: 0;
  top: calc(var(--space-between-large) * -1.5);
  width: var(--dimension-button);
  z-index: 9;
}

.slider__fullscreen .iconography {
  fill: var(--color-black);
}

.slider__fullscreen svg {
  display: flex;
}

.slider .flickity-enabled.is-fullscreen .slider__fullscreen {
  right: 50px;
  top: 0;
}

.slider__progress {
  background: var(--color-black);
}

.slider .flickity-page-dots {
  list-style: none;
  margin: var(--space-between-medium) 0 0;
  padding: 0;
  width: 100%;
}

.slider .flickity-page-dots .dot {
  cursor: pointer;
  display: inline-block;
}

.slider .flickity-page-dots .dot:not(:last-child) {
  margin-right: var(--grid-row-quarter-gutter);
}

.slider .flickity-page-dots .dot::before {
  content: '\25CB';
}

.slider .flickity-page-dots .dot:hover::before,
.slider .flickity-page-dots .dot.is-selected::before {
  content: '\25CF';
}

.slider__buttons .button {
  transition: none;
}

.slider__buttons span.button__content span {
  font-size: var(--font-size-20);
  font-weight: lighter;
  height: 32px;
  line-height: var(--line-height-for-26);
  width: 26px;
}

@media screen and (min-width: 768px) {
  .slider--with-gradient::after {
    background: var(--gradient-to-left);
    content: '';
    display: block;
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: calc((var(--grid-row-half-gutter) + var(--grid-full-row-margin)) * -1);
    top: 0;
    width: calc((100vw - var(--grid-row-width) - (var(--grid-full-row-margin))) / 2);
    z-index: var(--z-index-slider-gradient);
  }

  /* stylelint-disable  */
  .slider--thinner .slider__carousel {
    margin-bottom: calc(var(--space-between-medium) + 16px);
    max-width: calc(
      var(--grid-row-width) +
        (100vw - var(--grid-row-width) - (var(--grid-row-gutter) + var(--grid-full-row-margin)) * 2) / 2
    );
  }
  /* stylelint-enable  */

  .slider__carousel {
    flex-grow: 1;
    order: 2;
  }

  .slider__card {
    height: auto;
    margin-right: var(--grid-row-gutter);
  }

  .slider .flickity-enabled.is-fullscreen .slider__card {
    margin-right: 0;
    max-height: 100vh;
    max-width: 100vw;
  }

  .slider__carousel-nav .slider__card {
    width: 20%;
  }

  .slider__buttons {
    margin-top: 0;
  }

  .slider .flickity-page-dots {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--grid-row-width);
    padding-left: var(--grid-row-half-gutter);
    padding-right: var(--grid-row-half-gutter);
  }

  .slider--thinner .flickity-page-dots {
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

@media screen and (min-width: 1280px) {
  .slider__fullscreen {
    top: calc(var(--space-between-large) * -1);
  }

  .slider__carousel {
    padding-bottom: 0;
  }

  .slider .flickity-enabled.is-fullscreen .slider__card {
    top: 100px;
  }
}

@media screen and (min-width: 1440px) {
  .slider {
    flex-direction: row;
  }

  .slider__buttons {
    flex-direction: column;
    height: calc(var(--dimension-button) * 2);
    margin-right: var(--grid-row-gutter);
    width: var(--dimension-button);
  }

  .slider__buttons .button:first-child {
    border-bottom: none;
    border-right: var(--dimension-border) solid var(--color-black);
  }

  .slider__fullscreen {
    display: none;
    top: 0;
  }

  .slider__card:hover .slider__fullscreen {
    display: flex;
  }
}

@media screen and (min-width: 1920px) {
  .slider .flickity-enabled.is-fullscreen .slider__card {
    top: 50px;
  }
}
