.cms-contact-form__title {
  margin-bottom: var(--space-between-small);
  padding-top: var(--space-between-small);
  position: relative;
}

.cms-contact-form__title:empty {
  display: none;
}

.cms-contact-form__title::before {
  background: var(--color-white);
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.cms-contact-form__column {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .full-row .cms-contact-form__column {
    display: grid;
    grid-template-columns: 2fr 8fr 2fr;
  }

  .full-row .cms-contact-form__column .cms-contact-form__content {
    grid-column-start: 2;
  }
}