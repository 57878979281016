.case-studies__hero {
  margin-bottom: var(--space-between-xxlarge);
}

.case-studies__list {
  margin-bottom: var(--space-between-xlarge);
}

.case-studies__list-item {
  margin-bottom: var(--space-between-xlarge);
}

.case-studies__generic-items {
  margin-top: var(--space-between-xxlarge);
}

.case-studies .resource-preview {
  max-width: 100%;
  padding-left: var(--grid-row-half-gutter);
  padding-right: var(--grid-row-half-gutter);
}

@media screen and (min-width: 640px) {
  .resource-preview .card-image__wrapper {
    width: 40%;
  }

  .resource-preview .card-image {
    flex-wrap: nowrap;
  }

  .resource-preview .card-image__content {
    flex-grow: 1;
    max-width: 60%;
  }
}
