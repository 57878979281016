.modal {
  box-shadow: var(--box-shadow);
  height: 100%;
  margin: auto;
  max-width: var(--body-max-width);
  overflow-y: scroll;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
  z-index: var(--z-index-modal);
}

.modalClose {
  cursor: pointer;
  margin-right: var(--spacer-06);
  margin-top: var(--spacer-04);
  position: absolute;
  right: 0;
  z-index: var(--z-index-modal);
}

.modalOpened {
  overflow: hidden;
}

.modal:focus {
  outline: none;
}

.modalBody {
  --grid-width: 100%;

  background-color: var(--color-grey-000);
  border-radius: 20px;
  height: 100%;
  position: relative;
}

.modalContentChild {
  height: 100%;
  padding: var(--spacer-10) var(--spacer-08);
}

.ReactModal__Overlay {
  background: none !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: auto;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  z-index: 3;
}

@media screen and (min-width: 1024px) {
  .modal {
    height: auto;
    max-height: 100vh;
    overflow-y: auto;
  }

  .modalBody {
    --grid-width: 960px;
  }

  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    margin-bottom: var(--space-between-small);
    margin-left: auto;
    margin-right: var(--space-between-small);
  }
}

@media screen and (min-width: 1280px) {
  .modalBody {
    --grid-width: 1096px;
  }
}

@media screen and (min-width: 1440px) {
  .modalBody {
    --grid-width: 1288px;
    --grid-item-span: 12;
  }
}
