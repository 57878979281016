.logo-repeater__title {
  display: block;
  margin-bottom: var(--space-between-medium);
}

.logo-repeater__grid {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: calc(var(--space-between-small) * -1);
}

.logo-repeater__grid-item {
  margin-bottom: var(--space-between-small);
  padding-left: var(--grid-row-half-gutter);
  padding-right: var(--grid-row-half-gutter);
  width: 50%;
}

.logo-repeater__picture img {
  max-width: 100%;
  width: auto;
}

@media screen and (min-width: 768px) {
  .logo-repeater__grid-item {
    width: 25%;
  }
}