/* CSS Tokens */
:root {
  /* Cookiebot Banner */

  --cookie-banner-logo: url('/images/logo.png') no-repeat;
  --cookie-banner-logo-aspect-ratio: 1/1;
  --cookie-banner-logo-width: 114px;
  --show-logo-in-popup: none;
  --cookie-banner-background: var(--color-white);
  --cookie-banner-popup-background: rgba(255, 255, 255, 1);
  --cookie-banner-typography-title: 600 24px/29px var(--font-family-default), sans-serif;
  --cookie-banner-title-color: var(--color-black);
  --cookie-banner-text-color: var(--color-black);
  --cookie-banner-link-color: var(--color-black);
  --cookie-banner-typography-text: 16px/20px var(--font-family-default), sans-serif;
  --cookie-banner-button-case: initial;
  --cookie-banner-case-title: initial;

  /* Banner Button Main */
  --cookie-banner-button-case: initial;
  --cookie-banner-button-text: 600 16px/20px var(--font-family-default), sans-serif;
  --cookie-banner-button-border: 1px solid var(--color-black);
  --cookie-banner-button-background: var(--color-black);
  --cookie-banner-button-text-color: var(--color-white);
  --cookie-banner-button-border-radius: 0;
  --cookie-banner-button-padding: 18px 16px;
  --cookie-banner-button-width: 240px;

  /* Banner Button Customize Level */
  --cookie-banner-button-body-text: 600 16px/20px var(--font-family-default), sans-serif;
  --cookie-banner-button-body-border: 1px solid var(--color-black);
  --cookie-banner-button-body-background: transparent;
  --cookie-banner-button-body-text-color: var(--color-black);
  --cookie-banner-button-body-border-radius: 0;
  --cookie-banner-button-body-padding: 18px 0;

  /* Cookiebot Popup */
  --cookie-popup-typography-text: 16px/24px var(--font-family-default), sans-serif;
  --cookie-popup-text-color: var(--color-black);
  --cookie-popup-link-color: var(--color-black);
  --cookie-banner-tabs-color: var(--color-black);
  --cookie-popup-button-text: 600 16px/20px var(--font-family-default), sans-serif;
  --cookie-popup-button-background: var(--color-black);
  --cookie-popup-button-text-color: var(--color-white);
  --cookie-popup-button-body-text: 600 16px/20px var(--font-family-default), sans-serif;
  --cookie-popup-button-border: 1px solid var(--color-black);
  --cookie-popup-button-body-border: 1px solid var(--color-black);
  --cookie-popup-button-body-border-radius: 0;
  --cookie-popup-button-body-text-color: var(--color-black);
  --cookie-popup-button-body-padding: 18px 16px;
  --cookie-popup-button-body-background: transparent;
  --cookie-banner-container: 1142px;
  --cookie-banner-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.2);
  --cookie-banner-height: 35%;
}

/* Styles */

body #CybotCookiebotDialogPoweredbyImage {
  display: none;
}

body #CybotCookiebotDialogHeader {
  display: none;
}

body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogPoweredbyLink {
  display: none;
  display: var(--show-logo-in-popup);
  margin-top: var(--spacer-02);
}

body #CybotCookiebotDialogBodyContentTitle {
  color: var(--color-black);
  color: var(--cookie-banner-title-color);
  font: 600 24px/29px var(--font-family-default), sans-serif;
  font: var(--cookie-banner-typography-title);
  letter-spacing: -0.8px;
  margin: 16px 0 16px;
  text-transform: initial;
  text-transform: var(--cookie-banner-case-title);
}

body #CybotCookiebotDialogBodyContentText {
  color: var(--color-black);
  color: var(--cookie-banner-text-color);
  font: 16px/20px var(--font-family-default), sans-serif;
  font: var(--cookie-banner-typography-text);
  letter-spacing: normal;
}

body #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a {
  color: var(--color-black);
  color: var(--cookie-banner-link-color);
  text-decoration: underline;
  transition: all 0.2s ease-in-out;
}

body #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a:hover {
  opacity: 0.75;
}

body #CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper {
  max-width: 1142px;
  max-width: var(--cookie-banner-container);
  align-items: center;
  justify-items: center;
  place-items: center;
}

body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogBodyContentTitle {
  color: var(--color-black);
  color: var(--cookie-popup-text-color);
}

body #CybotCookiebotDialog[data-template="bottom"] a,
body #CybotCookiebotDialog[data-template="bottom"] div,
body #CybotCookiebotDialogBodyContentControls[data-template="bottom"],
body #CybotCookiebotDialog[data-template="slideup"] a,
body #CybotCookiebotDialog[data-template="slideup"] div,
body #CybotCookiebotDialogBodyContentControls[data-template="slideup"] {
  color: var(--color-black);
  color: var(--cookie-banner-text-color);
  font: 16px/20px var(--font-family-default), sans-serif;
  font: var(--cookie-banner-typography-text);
}

body #CybotCookiebotDialog[data-template="popup"] div,
body #CybotCookiebotDialogBodyContentControls[data-template="popup"] {
  color: var(--color-black);
  color: var(--cookie-popup-text-color);
  font: 16px/24px var(--font-family-default), sans-serif;
  font: var(--cookie-popup-typography-text);
}

body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogBodyContentText a {
  color: var(--color-black);
  color: var(--cookie-popup-link-color);
  font: 16px/24px var(--font-family-default), sans-serif;
  font: var(--cookie-popup-typography-text);
  font-weight: 600;
}

body #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-bottom: 1px solid var(--color-black);
  border-bottom: 1px solid var(--cookie-banner-tabs-color);
  color: var(--color-black);
  color: var(--cookie-banner-tabs-color);
}

body #CybotCookiebotDialog[data-template="bottom"],
body #CybotCookiebotDialog[data-template="slideup"] {
  background: var(--color-white);
  background: var(--cookie-banner-background);
  border-radius: 0;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: var(--cookie-banner-shadow);
  height: 35%;
  height: var(--cookie-banner-height);
  align-items: center;
  justify-items: center;
  place-items: center;
}

body #CybotCookiebotDialog[data-template="popup"] {
  background: rgba(255, 255, 255, 1);
  background: var(--cookie-banner-popup-background);
}

body #CybotCookiebotDialogPoweredbyCybot,
body #CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogBodyBottomWrapper {
  display: none;
}

body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background-color: var(--color-black);
  background-color: var(--cookie-banner-button-background);
  border: 1px solid var(--color-black);
  border: var(--cookie-banner-button-border);
  border-radius: 0;
  border-radius: var(--cookie-banner-button-border-radius);
  color: var(--color-white);
  color: var(--cookie-banner-button-text-color);
  font: 600 16px/20px var(--font-family-default), sans-serif;
  font: var(--cookie-banner-button-text);
  padding: 18px 16px;
  padding: var(--cookie-banner-button-padding);
  text-transform: initial;
  text-transform: var(--cookie-banner-button-case);
  transition: all 0.25s ease-in-out;
}

body #CybotCookiebotDialog[data-template="bottom"] #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
body #CybotCookiebotDialog[data-template="bottom"] #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
body #CybotCookiebotDialog[data-template="slideup"] #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
body #CybotCookiebotDialog[data-template="slideup"] #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background-color: var(--color-black);
  background-color: var(--cookie-banner-button-background);
  color: var(--color-white);
  color: var(--cookie-banner-button-text-color);
  text-transform: initial;
  text-transform: var(--cookie-banner-button-case);
}

body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogBodyButtonAccept,
body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background-color: var(--color-black);
  background-color: var(--cookie-popup-button-background);
  color: var(--color-white);
  color: var(--cookie-popup-button-text-color);
  text-transform: initial;
  text-transform: var(--cookie-banner-button-case);
}

body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogBodyLevelButtonCustomize,
body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonDecline {
  background-color: transparent;
  background-color: var(--cookie-popup-button-body-background);
  border: 1px solid var(--color-black);
  border: var(--cookie-popup-button-body-border);
  color: var(--color-black);
  color: var(--cookie-popup-button-body-text-color);
  text-transform: initial;
  text-transform: var(--cookie-banner-button-case);
}

body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
body #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  border: 1px solid var(--color-black);
  border: var(--cookie-banner-button-body-border);
  border-radius: 0;
  border-radius: var(--cookie-banner-button-body-border-radius);
  color: var(--color-black);
  color: var(--cookie-banner-button-body-text-color);
  font: 600 16px/20px var(--font-family-default), sans-serif;
  font: var(--cookie-banner-button-body-text);
  padding: 18px 0;
  padding: var(--cookie-banner-button-body-padding);
  text-transform: initial;
  text-transform: var(--cookie-banner-case-title);
  width: 240px;
  width: var(--cookie-banner-button-width);
}

body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  border: 1px solid var(--color-black);
  border: var(--cookie-popup-button-body-border);
  border-radius: 0;
  border-radius: var(--cookie-popup-button-body-border-radius);
  color: var(--color-black);
  color: var(--cookie-banner-button-body-text-color);
  font: 600 16px/20px var(--font-family-default), sans-serif;
  font: var(--cookie-popup-button-body-text);
  padding: 18px 16px;
  padding: var(--cookie-popup-button-body-padding);
  text-transform: initial;
  text-transform: var(--cookie-banner-button-case);
}

body #CybotCookiebotDialog .CybotCookiebotScrollContainer,
body #CybotCookiebotDialogHeader {
  border-bottom: none;
}

body #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:not(:first-of-type) {
  margin-bottom: 0;
  margin-right: 0.5rem;
  width: 240px;
  width: var(--cookie-banner-button-width);
}

body #CybotCookiebotDialogBodyContent {
  padding-top: 0;
}

body #CybotCookiebotDialogNav a.CybotCookiebotDialogNavItemLink {
  font-weight: bold;
}

body #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.active {
  border-bottom: 1px solid var(--color-black);
  border-bottom: 1px solid var(--cookie-banner-tabs-color);
  color: var(--color-black);
  color: var(--cookie-banner-tabs-color);
}

body #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
  color: var(--color-black);
  color: var(--cookie-banner-tabs-color);
}

body #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover {
  color: var(--color-black);
  color: var(--cookie-banner-tabs-color);
}

body #CybotCookiebotDialogTabContent input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: var(--color-black);
  background-color: var(--cookie-banner-tabs-color);
}

body .CybotCookiebotDialogArrow {
  display: none;
}

body #CybotCookiebotDialogPoweredByText {
  display: none;
}

body #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  margin-top: var(--space-between-xxsmall);
  max-width: none;
  width: 240px;
  width: var(--cookie-banner-button-width);
}

@media screen and (max-width: 1280px) {
  body #CybotCookiebotDialog[data-template="bottom"],
  body #CybotCookiebotDialog[data-template="slideup"] {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    max-width: 100%;
    min-height: 35%;
    min-height: var(--cookie-banner-height);
    position: fixed;
    right: 0;
    top: auto;
    -webkit-transform: none;
            transform: none;
    width: 100%;
  }

  body #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyButtons,
  body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper {
    justify-content: flex-start;
  }

  body #CybotCookiebotDialogBodyLevelButtonCustomize {
    order: 1;
  }

}

@media screen and (max-width: 600px) {
  body #CybotCookiebotDialogHeader {
    justify-content: center;
  }

  body #CybotCookiebotDialogHeader > div {
    justify-content: center;
    text-align: center;
  }

  body #CybotCookiebotDialogBodyLevelButtonCustomize {
    order: initial;
  }

  body #CybotCookiebotDialogBodyButtonsWrapper {
    --cookie-banner-button-width: 100%;
  }
}
