.stats-summaries__selector {
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .stats-summaries .row.stats-summaries__selector-wrapper .column {
    padding: 0;
  }
}

@media screen and (min-width: 1440px) {
  .stats-summaries__selector-wrapper {
    align-content: flex-start;
    flex-direction: column;
  }

  .stats-summaries__selector:not(:last-child) {
    margin-bottom: var(--space-between-xxsmall);
  }
}
