.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: var(--space-between-xxxxlarge);
  position: relative;
  z-index: 1;
}

.hero--with-media {
  height: 100vh;
  padding-top: 0;
}

.hero--active {
  z-index: var(--z-index-menu);
}

.hero__content {
  position: relative;
  z-index: 100;
}

.hero__logo {
  height: var(--dimension-logo-height);
  position: absolute;
  top: -60%;
  width: var(--dimension-logo-width);
}

.hero__logo-icon {
  height: 100%;
  width: 100%;
}

.hero__content > *:not(:first-child) {
  display: inline-block;
  margin-top: var(--space-between-medium);
}

.hero__media {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: all var(--transition-timing-slow);
  width: 100%;
  z-index: -1;
}

.hero__media::before,
.hero__media::after {
  content: '';
  height: 50vh;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.hero__media::before {
  background: linear-gradient(
    to bottom,
    hsl(0, 0%, 7%) 0%,
    hsla(0, 0%, 7%, 0.987) 1.3%,
    hsla(0, 0%, 7%, 0.951) 4.9%,
    hsla(0, 0%, 7%, 0.896) 10.4%,
    hsla(0, 0%, 7%, 0.825) 17.5%,
    hsla(0, 0%, 7%, 0.741) 25.9%,
    hsla(0, 0%, 7%, 0.648) 35.2%,
    hsla(0, 0%, 7%, 0.55) 45%,
    hsla(0, 0%, 7%, 0.45) 55%,
    hsla(0, 0%, 7%, 0.352) 64.8%,
    hsla(0, 0%, 7%, 0.259) 74.1%,
    hsla(0, 0%, 7%, 0.175) 82.5%,
    hsla(0, 0%, 7%, 0.104) 89.6%,
    hsla(0, 0%, 7%, 0.049) 95.1%,
    hsla(0, 0%, 7%, 0.013) 98.7%,
    hsla(0, 0%, 7%, 0) 100%
  );
  height: 100vh;
  top: 0;
}

.hero__media::after {
  background: var(--gradient-to-top);
  bottom: -1px;
}

.hero__picture,
.hero__picture-picture,
.hero__picture-image {
  height: 100%;
  width: 100%;
}

.hero__video {
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  width: 100%;
}

.hero__video iframe {
  box-sizing: border-box;
  height: 56.25vw;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 177.77777778vh;
}

.hero--active .hero__media {
  z-index: 1;
}

.hero__media-close {
  cursor: pointer;
  position: absolute;
  right: var(--grid-row-half-gutter);
  top: var(--space-between-small);
  z-index: 1;
}

@media screen and (min-width: 1440px) {
  .hero .hero__label-name {
    margin-top: var(--space-between-xxsmall);
  }
}
