.page__hero {
  margin-bottom: var(--space-between-xxlarge);
}

:root {
  --hero-ty: 0%;
  --hero-rt: 0deg;
}

.page-landing {
  --color-landing: var(--color-red);
}

.page-landing-donations-water {
  --color-landing: var(--color-blue);
}

.page__hero--donations {
  background: var(--color-red);
  box-sizing: border-box;
  outline: 0;
  overflow: hidden;
  padding-bottom: var(--space-between-xxxxlarge);
  position: relative;
}

.page__hero--donations-water {
  background: var(--color-blue);
}

.page__hero--donations::after {
  background-color: var(--color-black);
  border-radius: 30%;
  bottom: -50%;
  box-sizing: border-box;
  content: '';
  height: 200%;
  left: -50%;
  position: absolute;

  -webkit-transform: translateY(0%) rotate(0deg);

          transform: translateY(0%) rotate(0deg);

  -webkit-transform: translateY(var(--hero-ty)) rotate(var(--hero-rt));

          transform: translateY(var(--hero-ty)) rotate(var(--hero-rt));
  width: 200%;
}

@media screen and (max-width: 767px) {
  .page__hero--donations::after {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .page__hero--donations::after {
    background-color: var(--color-black);
    border-radius: 30%;
    bottom: -50%;
    box-sizing: border-box;
    content: '';
    height: 200vw;
    left: -50%;
    position: absolute;

    -webkit-transform: translateY(0%) rotate(0deg);

            transform: translateY(0%) rotate(0deg);

    -webkit-transform: translateY(var(--hero-ty)) rotate(var(--hero-rt));

            transform: translateY(var(--hero-ty)) rotate(var(--hero-rt));
    width: 200vw;
  }
}
