.card-image {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
}

.card-image__wrapper {
  margin-bottom: var(--space-between-small);
  width: 100%;
}

.card-image__content {
  background: var(--color-black);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.card-image__text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-image__text *:not(:last-child) {
  margin-bottom: var(--space-between-xxsmall);
}

.card-image__footer {
  margin-top: var(--space-between-small);
}

.card-image__footer button {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .card-image--horizontal {
    flex-direction: row;
    flex-wrap: inherit;
    grid-gap: var(--space-between-large);
    gap: var(--space-between-large);
  }

  .card-image--horizontal-reverse {
    flex-direction: row-reverse;
  }

  .card-image--horizontal .card-image__wrapper,
  .card-image--horizontal-reverse .card-image__wrapper {
    margin-bottom: 0;
    width: 50%;
  }

  .card-image--horizontal .card-image__content,
  .card-image--horizontal-reverse .card-image__content {
    width: 50%;
  }

  .card-image--horizontal .card-image__text,
  .card-image--horizontal-reverse .card-image__text {
    justify-content: flex-start;
    margin-top: 0;
    padding: 0;
    position: relative;
  }
}

@media (hover: hover) {
  .card-image--with-hover .picture img {
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: var(--transition-timing-default);
  }

  .card-image--with-hover:hover .picture img {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
  }
}
