.gallery {
  flex-direction: column;
}

.gallery__column {
  width: 100%;
}

.gallery__title {
  margin-bottom: var(--space-between-small);
}

.gallery__video {
  cursor: pointer;
  position: relative;
}

.gallery__video-play {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
