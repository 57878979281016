.full-row {
  display: flex;
  flex-flow: row wrap;
  margin-left: var(--grid-full-row-margin);
  margin-right: var(--grid-full-row-margin);
}

.full-row .full-row {
  margin-left: calc(var(--grid-row-half-gutter) * -1);
  margin-right: calc(var(--grid-row-half-gutter) * -1);
  width: calc(100% + var(--grid-row-gutter));
}

.full-row--inverted {
  background-color: var(--color-white);
}
