@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
.typography {
  font-family: var(--font-family-default);
  font-style: normal;
  font-weight: var(--font-weight-regular);
}

.typography--h1,
.typography--h2,
.typography--h3,
.typography--h4,
.typography--h5,
.typography--h6,
.typography--big-body,
.typography--big-h1 {
  font-weight: var(--font-weight-bold);
}

.typography--h1 {
  font-size: var(--font-size-72);
  letter-spacing: var(--letter-spacing-for-72);
  line-height: var(--line-height-for-72);
}

.typography--big-h1 {
  font-size: var(--font-size-40);
  letter-spacing: var(--letter-spacing-for-40);
  line-height: var(--line-height-for-40);
}

.typography--h2 {
  font-size: var(--font-size-28);
  letter-spacing: var(--letter-spacing-for-28);
  line-height: var(--line-height-for-28);
}

.typography--h3,
.typography--h3-regular,
.typography--h4,
.typography--h5,
.typography--h6 {
  font-size: var(--font-size-24);
  letter-spacing: var(--letter-spacing-for-24);
  line-height: var(--line-height-for-24);
}

.typography--big-body {
  font-size: var(--font-size-20);
  letter-spacing: var(--letter-spacing-for-20);
  line-height: var(--line-height-for-20);
}

.typography--body {
  font-size: var(--font-size-18);
  letter-spacing: var(--letter-spacing-for-18);
  line-height: var(--line-height-for-18);
}

.typography--small-body {
  font-size: var(--font-size-16);
  letter-spacing: var(--letter-spacing-for-16);
  line-height: var(--line-height-for-16);
}

@media screen and (min-width: 320px) {
  .typography--h1 {
    font-size: calc(var(--font-size-40) + 56 * var(--proportional-width-for-responsive));
    letter-spacing: calc(var(--letter-spacing-for-40) - 2.4 * var(--proportional-width-for-responsive));
    line-height: calc(var(--line-height-for-40) + 63 * var(--proportional-width-for-responsive));
  }

  .typography--h2 {
    font-size: calc(var(--font-size-28) + 28 * var(--proportional-width-for-responsive));
    letter-spacing: calc(var(--letter-spacing-for-28) - 0.75 * var(--proportional-width-for-responsive));
    line-height: calc(var(--line-height-for-28) + 36 * var(--proportional-width-for-responsive));
  }

  .typography--h3,
  .typography--h3-regular,
  .typography--h4,
  .typography--h5,
  .typography--h6 {
    font-size: calc(var(--font-size-24) + 16 * var(--proportional-width-for-responsive));
    letter-spacing: calc(var(--letter-spacing-for-24) - 0.4 * var(--proportional-width-for-responsive));
    line-height: calc(var(--line-height-for-24) + 20 * var(--proportional-width-for-responsive));
  }

  .typography--big-body {
    font-size: calc(var(--font-size-20) + 4 * var(--proportional-width-for-responsive));
    letter-spacing: var(--letter-spacing-for-20);
    line-height: calc(var(--line-height-for-20) + 5 * var(--proportional-width-for-responsive));
  }

  .typography--body {
    font-size: calc(var(--font-size-18) + 2 * var(--proportional-width-for-responsive));
    letter-spacing: var(--letter-spacing-for-18);
    line-height: var(--line-height-for-18);
  }

  .typography--big-h1 {
    font-size: calc(var(--font-size-72) + 56 * var(--proportional-width-for-responsive));
    letter-spacing: calc(var(--letter-spacing-for-72) - 2.4 * var(--proportional-width-for-responsive));
    line-height: calc(var(--line-height-for-72) + 63 * var(--proportional-width-for-responsive));
  }
}

@media screen and (min-width: 1920px) {
  .typography--h1 {
    font-size: var(--font-size-96);
    letter-spacing: var(--letter-spacing-for-96);
    line-height: var(--line-height-for-96);
  }

  .typography--big-h1 {
    font-size: var(--font-size-136);
    letter-spacing: var(--letter-spacing-for-136);
    line-height: var(--line-height-for-136);
  }

  .typography--h2 {
    font-size: var(--font-size-56);
    letter-spacing: var(--letter-spacing-for-56);
    line-height: var(--line-height-for-56);
  }

  .typography--h3,
  .typography--h3-regular,
  .typography--h4,
  .typography--h5,
  .typography--h6 {
    font-size: var(--font-size-40);
    letter-spacing: var(--letter-spacing-for-40);
    line-height: var(--line-height-for-40);
  }

  .typography--big-body {
    font-size: var(--font-size-24);
    letter-spacing: var(--letter-spacing-for-24);
    line-height: var(--line-height-for-24);
  }

  .typography--body {
    font-size: var(--font-size-20);
    letter-spacing: var(--letter-spacing-for-20);
    line-height: var(--line-height-for-20);
  }
}

.inline-button {
  background-color: transparent;
  border: none;
  color: var(--color-white);
  cursor: pointer;
  display: inline-block;
  margin: -2px 0 2px;
  outline: 0;
  padding: 0;
}

.inline-button--with-hover {
  cursor: pointer;
  position: relative;
}

.inline-button--with-hover:before {
  background-color: var(--color-white);
  bottom: -8px;
  content: '';
  height: 1px;
  left: 0%;
  position: absolute;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: right;
          transform-origin: right;
  transition: var(--transition-all);
  visibility: visible;
  width: 100%;
}

a:hover .inline-button--with-hover:before,
.inline-button--with-hover:hover:before {
  -webkit-animation: testingAnimation 1s;
          animation: testingAnimation 1s;
}

.body--inverted .header .inline-button--with-hover:before {
  background-color: var(--color-black);
}

.inline-button--inverted.inline-button--with-hover:before {
  background-color: var(--color-black);
}

.inline-button--inverted {
  color: var(--color-black);
}

.inline-button:disabled {
  opacity: 0.25;
  pointer-events: none;
}

.inline-button--line-on-hover::before {
  visibility: hidden;
}

a:hover .inline-button--line-on-hover:before,
.inline-button--line-on-hover:hover:before {
  visibility: visible;
}

@-webkit-keyframes testingAnimation {
  0% {
    left: 0;
    width: 100%;
  }

  49% {
    left: 100%;
    width: 0;
  }

  50% {
    left: 0;
    width: 0;
  }

  100% {
    left: 0;
    width: 100%;
  }
}

@keyframes testingAnimation {
  0% {
    left: 0;
    width: 100%;
  }

  49% {
    left: 100%;
    width: 0;
  }

  50% {
    left: 0;
    width: 0;
  }

  100% {
    left: 0;
    width: 100%;
  }
}

.row {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.row .row {
  margin-left: calc(var(--grid-row-half-gutter) * -1);
  margin-right: calc(var(--grid-row-half-gutter) * -1);
  width: calc(100% + var(--grid-row-gutter));
}

.row--top-32 {
  margin-top: var(--space-between-medium);
}

.row--bottom-32 {
  margin-bottom: var(--space-between-medium);
}

.row--top-80 {
  margin-top: var(--space-between-xlarge);
}

@media screen and (min-width: 768px) {
  .row {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--grid-row-width);
  }
}

.column {
  max-width: 100%;
  padding-left: var(--grid-row-half-gutter);
  padding-right: var(--grid-row-half-gutter);
}

@media screen and (min-width: 1280px) {
  .column--50 {
    width: 50%;
  }
}
.not-found {
  margin-bottom: var(--space-between-xxxlarge);
  margin-top: var(--space-between-xxlarge);
  padding-top: var(--dimension-header-height);
}

.not-found__title {
  margin-bottom: var(--space-between-large);
}

.button {
  align-items: center;
  background-color: var(--color-white);
  border: var(--dimension-border) solid var(--color-white);
  color: var(--color-black);
  cursor: pointer;
  display: inline-flex;
  height: var(--dimension-button);
  justify-content: space-between;
  outline: 0;
  padding-left: var(--space-between-small);
  padding-right: var(--space-between-small);
  position: relative;
}

.button:hover {
  background: none;
  background-color: var(--color-black);
  border: var(--dimension-border) solid var(--color-white);
  color: var(--color-white);
}

.button--center {
  justify-content: center;
  text-align: center;
}

.button:disabled {
  opacity: 0.25;
  pointer-events: none;
}

.button__content {
  display: inline-flex;
  white-space: nowrap;
}

.button__content:not(:first-child) {
  margin-left: var(--grid-row-half-gutter);
}

@media screen and (min-width: 1024px) {
  .button__content {
    white-space: normal;
  }
}

@media screen and (min-width: 1440px) {
  .button__content {
    white-space: nowrap;
  }
}

.social-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.social-links__item:not(:last-child) {
  margin-bottom: var(--space-between-xsmall);
  margin-right: var(--grid-row-quarter-gutter);
}

.social-links__link {
  padding: var(--space-between-xxsmall) var(--grid-row-quarter-gutter) 0 0;
}
.contact-form .hs-form-field:not(.hs-fieldtype-booleancheckbox) {
  padding-bottom: var(--space-between-small);
}

.contact-form > .inline-button {
  margin-bottom: var(--space-between-small);
  margin-top: var(--space-between-small);
}

.contact-form .hs-form-field input:not([type='file']),
.contact-form .hs-form-field textarea {
  background: none;
  border: none;
  border-bottom: 1px solid var(--color-white);
  border-radius: 0;
  color: var(--color-white);
  font-family: var(--font-family-default);
  font-size: var(--font-size-20);
  letter-spacing: var(--letter-spacing-for-20-2);
  line-height: 1.2;
  outline: none;
  padding-bottom: var(--space-between-xxxsmall);
  padding-left: 0;
  width: 100%;
}

.contact-form .hs-form-field input::-webkit-input-placeholder {
  color: var(--color-white);
  opacity: 1;
}

.contact-form .hs-form-field input::-ms-input-placeholder {
  color: var(--color-white);
  opacity: 1;
}

.contact-form .hs-form-field input::placeholder {
  color: var(--color-white);
  opacity: 1;
}

.contact-form .hs-form-field textarea {
  background-image: linear-gradient(transparent, transparent calc(32px - 1px), var(--color-white) 0px);
  background-size: 100% 32px;
  border-bottom: 0;
  line-height: 32px;
  min-height: 100px;
}

.contact-form .hs-form-field textarea::-webkit-input-placeholder {
  opacity: 1;
}

.contact-form .hs-form-field textarea::-ms-input-placeholder {
  opacity: 1;
}

.contact-form .hs-form-field textarea::placeholder {
  opacity: 1;
}

.contact-form--inverted .hs-form-field input {
  border-bottom: 1px solid var(--color-white);
  color: var(--color-white);
}

.contact-form label.hs-error-msg,
.contact-form .hs-error-msgs {
  color: #f2545b;
  display: block;
  font-family: var(--font-family-default);
  font-size: var(--font-size-16);
  margin: 10px 0 0;
}

.contact-form .hs-richtext p,
.contact-form--inverted .hs-richtext p {
  font-size: 16px;
  line-height: 30px;
}

.contact-form .hs-richtext {
  color: var(--color-black);
  font-family: var(--font-family-default);
  letter-spacing: var(--letter-spacing-for-16);
}

.contact-form--inverted .hs-richtext {
  color: var(--color-white);
}

.contact-form .hs-button {
  background-color: var(--color-white);
  border: var(--dimension-border) solid var(--color-white);
  color: var(--color-black);
  cursor: pointer;
  font-family: var(--font-family-default);
  font-size: var(--font-size-18);
  font-weight: 300;
  height: 64px;
  letter-spacing: var(--letter-spacing-for-18);
  line-height: var(--line-height-for-18);
  padding-left: var(--space-between-xsmall);
  padding-right: var(--space-between-xsmall);
  transition: background var(--transition-opacity-button);
}

.contact-form .hs-button:hover {
  background: none;
  background-color: var(--color-black);
  border: var(--dimension-border) solid var(--color-white);
  color: var(--color-white);
  transition: background var(--transition-opacity-button);
}

.contact-form .form-columns-1 .hs-phone .input {
  margin-right: 16px;
}

.contact-form .form-columns-1 .input input[type='tel'] {
  width: 50%;
}

.contact-form .hs-form-field .input input[type='file'] {
  cursor: pointer;
  font-family: var(--font-family-default);
  font-size: var(--font-size-20);
  letter-spacing: var(--letter-spacing-for-20-2);
  padding-bottom: var(--space-between-xxxsmall);
  padding-top: var(--space-between-xxxsmall);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.contact-form .hbspt-form form > fieldset:nth-child(6) > div > div:nth-child(2) > div {
  margin-top: 10px;
}

.contact-form fieldset.form-columns-1 .hs-input:not([type='checkbox']),
.contact-form fieldset.form-columns-2 .hs-input:not([type='checkbox']) {
  border-radius: 0;
  width: 100% !important;
}

.contact-form ul.inputs-list {
  margin-top: var(--space-between-xxsmall);
  padding-left: 0;
}

.contact-form .inputs-list label {
  align-items: center;
  color: var(--color-white);
  display: flex;
  position: relative;
}

.contact-form .inputs-list input {
  /* opacity: 0; */
  position: absolute;
}

.contact-form .inputs-list {
  cursor: pointer;
  display: block;
  padding-left: 35px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkmark {
  background-color: var(--color-white);
  border-radius: 50%;
  cursor: pointer;
  height: 25px;
  left: 0;
  position: absolute;
  top: 0;
  width: 25px;
}

.contact-form .inputs-list input:checked ~ .checkmark {
  background-color: var(--color-white);
}

.checkmark:after {
  content: '';
  cursor: pointer;
  display: none;
  position: absolute;
}

.contact-form .inputs-list input:checked ~ .checkmark:after {
  display: block;
}

.contact-form .checkmark:after {
  background: var(--color-black);
  border-radius: 50%;
  height: 8px;
  left: 9px;
  top: 9px;
  width: 8px;
}

.contact-form fieldset.form-columns-1 .input {
  margin-right: 0 !important;
}

.contact-form fieldset.form-columns-2 div:not(:last-child) .input {
  margin-right: 40px !important;
}

.contact-form fieldset select {
  -webkit-appearance: none;
  background: none;
  border: none;
  border-bottom: 1px solid black;
  font-size: var(--font-size-20);
  outline: none;
  padding: var(--space-between-xxxsmall) 0;
  position: relative;
}

.contact-form.contact-form--inverted fieldset select {
  border-bottom: 1px solid var(--color-white);
  color: var(--color-white);
}

.contact-form fieldset .input {
  align-items: center;
  display: flex;
}

.contact-form fieldset .input:has(select) {
  position: relative;
}

.contact-form fieldset .input:has(select)::after {
  content: '+';
  font-size: var(--font-size-24);
  position: absolute;
  right: 0;
}

.contact-form .hs-form-field > label {
  display: block;
  padding-bottom: 5px;
}

.hs-button.primary.large {
  display: flex;
  height: 64px;
  justify-content: flex-start;
  padding: 0 0 0 var(--space-between-xsmall);
  width: 240px;
}

.hs-button.primary.large::before {
  color: white;
  content: '\2192';
  width: 400px;
}

.hs_submit input:not([value='→']) {
  margin-top: var(--space-between-small);
  min-width: 180px;
}

.contact-form .hs-submit input {
  font-size: var(--font-size-20);
  position: relative;
  text-align: left;
  top: var(--space-between-xxxxsmall);
}

@media (max-width: 1024px) {
  .contact-form fieldset.form-columns-2 .hs-form-field,
  .contact-form fieldset.form-columns-1 .hs-input[type='email'] {
    width: 100% !important;
  }

  .contact-form form fieldset {
    margin-bottom: 15px;
  }

  .contact-form fieldset.form-columns-2 div:not(:last-child) .input {
    margin-right: 0px !important;
  }

  .form-columns-2 div:first-child {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .contact-form .contact-form__content .hbspt-form form fieldset .hs-form-field .hs-input:not([type='checkbox']),
  .contact-form .form-columns-1 .input input[type='tel'] {
    width: 100%;
  }

  .contact-form .hs-form-field .input input[type='file'] {
    font-size: var(--font-size-16);
    width: 100%;
  }

  .hs_firstname {
    margin-bottom: 50px;
  }

  .hs_email {
    margin-bottom: 0;
  }

  .contact-form .hbspt-form form fieldset .input,
  .contact-form .hbspt-form fieldset .hs-form-field .hs-input,
  .contact-form .form-columns-1 .hs-phone .input {
    margin: 0;
  }
}

/* Change Autocomplete styles in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-black);
}

/* Change Automcomplete Styles for inverted form */
.contact-form--inverted input:-webkit-autofill,
.contact-form--inverted input:-webkit-autofill:hover,
.contact-form--inverted input:-webkit-autofill:focus,
.contact-form--inverted textarea:-webkit-autofill,
.contact-form--inverted textarea:-webkit-autofill:hover,
.contact-form--inverted textarea:-webkit-autofill:focus,
.contact-form--inverted select:-webkit-autofill,
.contact-form--inverted select:-webkit-autofill:hover,
.contact-form--inverted select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-white);
}

/* Fixed checkbox */
.contact-form__content .hs-form-field input[type='checkbox'] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: 1px solid var(--color-white);
  border-radius: 90%;
  cursor: pointer;
  height: 18px;
  margin-left: 0;
  margin-right: -10px;
  margin-top: 0;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 8px;
  position: relative;
  transition: background 0.2s ease-in-out;
}

.contact-form__content .hs-form-field input[type='checkbox']:checked {
  background: var(--color-white);
}

/* Form width 100% */
.hbspt-form fieldset {
  max-width: 100% !important;
}

.footer {
  --color-white: #131313;
  --color-black: #fff;
  --color-error: #fff;

  background: var(--color-black);
  border-top: 1px solid var(--color-white);
  color: var(--color-white);
  margin-left: calc(var(--grid-full-row-margin) + var(--grid-row-half-gutter));
  margin-right: calc(var(--grid-full-row-margin) + var(--grid-row-half-gutter));
  padding-top: var(--space-between-xlarge);
}

.body--inverted .footer {
  --color-white: #fff;
  --color-black: #131313;
  --color-error: #131313;
}

.footer__title {
  padding-bottom: var(--space-between-medium);
}

.footer__row {
  padding-bottom: 0;
}

.footer__column {
  padding-bottom: var(--space-between-large);
}

/* .button */
.footer__row .footer__column .button {
  height: 64px;
  justify-content: flex-start;
  padding: 0 0 0 var(--space-between-xsmall);
  width: 240px;
}

/* gdpr */
.footer__gdpr {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: var(--space-between-large);
  padding-top: var(--space-between-medium);
  position: relative;
}

.footer__gdpr::before {
  background: var(--color-white);
  content: '';
  height: 1px;
  left: var(--grid-row-half-gutter);
  position: absolute;
  top: 0;
  width: calc(100% - var(--grid-row-gutter));
}

.footer__copyright {
  padding-right: var(--space-between-small);
}

.footer__legal-link {
  padding-right: var(--space-between-small);
}

/* social links */
.footer__social-links {
  justify-content: flex-start;
  padding-top: var(--space-between-xxsmall);
}

.footer__link {
  display: block;
  padding-top: var(--space-between-xxsmall);
}

/* contact form */
.footer__contact-form {
  padding-top: var(--space-between-xxsmall);
}

.footer__contact-form .contact-form__content {
  margin-top: 0;
}

.footer__contact-form .hs-form {
  font-size: var(--font-size-20);
  position: relative;
}

.footer__contact-form .hs-form .hs_email .hs-input {
  padding-bottom: var(--space-between-xxsmall);
  width: 100%;
}

.footer__contact-form .hs-form .hs_email .hs-input::-webkit-input-placeholder {
  color: var(--color-white);
}

.footer__contact-form .hs-form .hs_email .hs-input::-ms-input-placeholder {
  color: var(--color-white);
}

.footer__contact-form .hs-form .hs_email .hs-input::placeholder {
  color: var(--color-white);
}

.footer__contact-form .hs-form .legal-consent-container {
  font-size: var(--font-size-16);
}

.footer__contact-form .hs-form-field:not(.hs-fieldtype-booleancheckbox) {
  padding-bottom: var(--space-between-xxsmall);
}

.footer__contact-form .hs-form .legal-consent-container input[type='checkbox'] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 1px solid var(--color-white);
  border-radius: 90%;
  cursor: pointer;
  height: 18px;
  margin-left: 0;
  margin-right: -10px;
  margin-top: 0;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 8px;
  position: relative;
  top: 2px;
  transition: ease-in-out 0.2s;
}

.footer__contact-form .hs-form .legal-consent-container input[type='checkbox']:checked {
  background: var(--color-white);
}

.footer__contact-form .hs-form .hs-submit {
  position: absolute;
  right: 0;
  top: 0;
}

.footer__contact-form .hs-form .hs-submit .actions .hs-button {
  background: none;
  border: 0;
  color: var(--color-white);
  height: auto;
  padding-right: 0;
  transition: none;
  width: auto;
  z-index: 1;
}

.footer--hide-footer-fields {
  border-top: none;
  padding-top: var(--space-between-xxsmall);
}

@media screen and (min-width: 769px) {
  .footer__row {
    padding-bottom: var(--space-between-large);
  }

  .footer__column {
    padding-bottom: 0;
  }
}

.full-row {
  display: flex;
  flex-flow: row wrap;
  margin-left: var(--grid-full-row-margin);
  margin-right: var(--grid-full-row-margin);
}

.full-row .full-row {
  margin-left: calc(var(--grid-row-half-gutter) * -1);
  margin-right: calc(var(--grid-row-half-gutter) * -1);
  width: calc(100% + var(--grid-row-gutter));
}

.full-row--inverted {
  background-color: var(--color-white);
}

.content-with-sidebar__left:not(:empty) {
  margin-bottom: var(--space-between-xxsmall);
}

.content-with-sidebar__left,
.content-with-sidebar__right,
.content-with-sidebar__content {
  width: 100%;
}

@media screen and (min-width: 1440px) {
  .content-with-sidebar__left:not(:empty) {
    margin-bottom: 0;
  }

  .content-with-sidebar__left,
  .content-with-sidebar__right {
    flex: 1;
    flex-direction: column;
    width: auto;
  }

  .content-with-sidebar__content {
    flex-grow: 1;
    flex-shrink: 0;
    max-width: var(--grid-row-width);
  }
}

.iconography {
  display: inline-block;
  fill: var(--color-white);
  flex-shrink: 0;
  height: var(--iconography-size-default);
  transition: var(--transition-fill);
  width: var(--iconography-size-default);
}

.iconography--big {
  height: var(--iconography-size-big);
  width: var(--iconography-size-big);
}

.header-menu-toggle .iconography:not(.header-menu-toggle__logo) {
  cursor: pointer;
}

.header-menu-toggle__menu,
.header-menu-toggle__logo {
  height: var(--dimension-logo-height);
  width: var(--dimension-logo-width);
}

.header-menu-toggle__menu svg :first-child {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
  transition: var(--transition-transform-fast);
}

.header-menu-toggle__menu svg :last-child {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  transition: var(--transition-transform-fast);
}

.header-menu-toggle__menu:hover svg :first-child {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.header-menu-toggle__menu:hover svg :last-child {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.language-selector {
  text-align: center;
}

.language-selector__option {
  color: var(--color-alto);
  cursor: pointer;
  position: relative;
}

.language-selector__option:before {
  background-color: var(--color-white);
  bottom: -2px;
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left;
          transform-origin: left;
  transition: var(--transition-all);
  visibility: hidden;
  width: 100%;
}

.language-selector__option--selected:before,
.language-selector__option:hover:before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  visibility: visible;
}

.language-selector__option:not(:last-child) {
  margin-right: var(--grid-row-quarter-gutter);
}

.language-selector__icon {
  display: inline-block;
  margin-right: calc(var(--grid-row-quarter-gutter) / 2);
  -webkit-transform: rotate(-45deg) translateY(2%);
          transform: rotate(-45deg) translateY(2%);
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.language-selector__icon--open {
  -webkit-transform: rotate(0) translateY(0);
          transform: rotate(0) translateY(0);
}

@media screen and (min-width: 1280px) {
  .language-selector {
    margin-bottom: 0;
    text-align: left;
  }
}

.header-menu {
  align-items: center;
  background: var(--color-black);
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: space-around;
  margin-top: calc(var(--dimension-header-height) * -1);
  overflow: auto;
  padding-bottom: 0;
  padding-top: var(--space-between-xlarge);
}

.header-menu > div {
  width: 100%;
}

.header-menu__top-wrapper {
  align-items: center;
  height: calc(var(--dimension-header-height) / 2);
  justify-content: space-between;
  margin-top: var(--space-between-xxxxsmall);
}

.header--inverted .header-menu {
  background-color: var(--color-white);
}

.header-menu__footer-wrapper {
  flex-direction: column;
  flex-wrap: nowrap;
  grid-gap: var(--space-between-medium);
  gap: var(--space-between-medium);
  justify-content: space-between;
}

.header-menu::before {
  background: var(--color-black);
  content: '';
  height: 101vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: -1;
}

.header--inverted .header-menu::before {
  background: var(--color-white);
}

.header-menu__logo {
  height: var(--dimension-logo-height);
  margin-top: var(--space-between-xxxsmall);
  width: var(--dimension-logo-width);
}

.header-menu__items {
  width: 100%;
}

.header-menu__item-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.header-menu__item {
  margin-bottom: var(--space-between-small);
}

.header-menu__email {
  margin-right: var(--grid-row-quarter-gutter);
  padding: var(--space-between-xxsmall) var(--grid-row-quarter-gutter) 0;
}

.header-menu__footer-wrapper-contact {
  text-align: center;
}

.header--inverted.header--opened * {
  color: var(--color-black);
  fill: var(--color-black);
}

.header-menu__contact-title,
.header-menu__social-title {
  display: block;
  margin-bottom: var(--space-between-xsmall);
}

@media screen and (min-width: 768px) {
  .header-menu {
    height: 80vh;
    padding-bottom: var(--space-between-xlarge);
    padding-top: calc(var(--space-between-medium) + var(--dimension-header-height));
  }
  .header-menu__item {
    margin-bottom: var(--space-between-medium);
  }

  .header-menu__item a {
    text-indent: calc(var(--space-between-xxxxsmall) * -1);
  }

  .header-menu__top-wrapper {
    height: var(--dimension-header-height);
    margin-top: calc(var(--dimension-header-height) * -1);
  }

  .header-menu__item-wrapper {
    align-items: center;
    flex-direction: row;
  }

  .header-menu__footer-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .header-menu__footer-wrapper .column {
    width: 40%;
  }

  .header-menu__footer-wrapper .column:last-child {
    width: 60%;
  }
}

@media screen and (min-width: 1280px) {
  .header-menu__footer-wrapper .column {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .header-menu {
    --header-menu-padding-top: 90px;
    --header-menu-min-height: 300px;
    height: 55vh;
    min-height: calc(var(--header-menu-min-height) + var(--header-menu-padding-top));
    padding-top: var(--header-menu-padding-top);
  }
  .header-menu__contact-title,
  .header-menu__social-title {
    font-size: var(--font-size-20);
  }

  .header-menu__footer-wrapper {
    grid-gap: 45px;
    gap: 45px;
  }
}

@media screen and (max-width: 767px) and (max-height: 700px) {
  .header-menu__social {
    display: none;
  }
}

.header {
  position: fixed;
  top: 0;
  transition: var(--transition-transform);
  width: 100%;
  z-index: var(--z-index-menu);
}

.header--inverted {
  position: absolute;
}

.header::before {
  background: linear-gradient(
    to bottom,
    hsl(0, 0%, 0%) 0%,
    hsla(0, 0%, 0%, 0.987) 2.4%,
    hsla(0, 0%, 0%, 0.951) 6%,
    hsla(0, 0%, 0%, 0.896) 10.7%,
    hsla(0, 0%, 0%, 0.825) 16.3%,
    hsla(0, 0%, 0%, 0.741) 22.6%,
    hsla(0, 0%, 0%, 0.648) 29.6%,
    hsla(0, 0%, 0%, 0.55) 37.1%,
    hsla(0, 0%, 0%, 0.45) 45%,
    hsla(0, 0%, 0%, 0.352) 53.1%,
    hsla(0, 0%, 0%, 0.259) 61.4%,
    hsla(0, 0%, 0%, 0.175) 69.7%,
    hsla(0, 0%, 0%, 0.104) 77.8%,
    hsla(0, 0%, 0%, 0.049) 85.6%,
    hsla(0, 0%, 0%, 0.013) 93.1%,
    hsla(0, 0%, 0%, 0) 100%
  );
  content: '';

  /* height: calc(var(--dimension-header-height) * 2.5); */
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: var(--transition-opacity);
  width: 100%;
  z-index: -1;
}

.header--inverted::before {
  content: none;
}

.header--hidden {
  -webkit-transform: translateY(calc(var(--dimension-header-height) * -1));
          transform: translateY(calc(var(--dimension-header-height) * -1));
}

.header--hidden::before {
  opacity: 0;
}

.header__nav {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  height: var(--dimension-header-height);
  justify-content: center;
}

.header__nav > .column {
  z-index: 1;
}

.header--opened {
  height: calc(var(--dimension-header-height) * 2.5);
}

@media screen and (min-width: 768px) {
  .header--opened {
    cursor: url(/static/media/close-wide.aeb97eab.svg) 32 32, auto;
  }
}

.loader {
  height: 100vh;
  width: 100vw;
}

.picture {
  overflow: hidden;
  position: relative;
}

.picture picture {
  display: block;
  transition: opacity var(--transition-timing-slow) ease-in-out var(--transition-timing-slow);
}

.picture img {
  width: 100%;
}

.picture--mounted picture {
  opacity: 0;
}

.picture--mounted .picture__preload {
  opacity: 1;
}

.picture--loaded picture {
  opacity: 1;
}

.picture__preload {
  display: flex;
  -webkit-filter: blur(8px);
          filter: blur(8px);
  height: 100%;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.5s ease-in-out 0.5s;
  width: 100%;
  z-index: 10;
}

.picture--loaded .picture__preload {
  opacity: 0;
}

.picture[class*='picture--ratio-'] picture::before {
  content: '';
  display: block;
  height: 0;
  position: relative;
}

.picture[class*='picture--ratio-'] img {
  height: auto;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.picture--ratio-9-16 picture::before {
  padding-bottom: calc(var(--aspect-ratio-9-16) * 100%);
}

.picture--ratio-3-4 picture::before {
  padding-bottom: calc(var(--aspect-ratio-3-4) * 100%);
}

.picture--ratio-16-9 picture::before {
  padding-bottom: calc(var(--aspect-ratio-16-9) * 100%);
}

.picture--ratio-4-3 picture::before {
  padding-bottom: calc(var(--aspect-ratio-4-3) * 100%);
}

.picture--ratio-3-2 picture::before {
  padding-bottom: calc(var(--aspect-ratio-3-2) * 100%);
}

.card-image {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
}

.card-image__wrapper {
  margin-bottom: var(--space-between-small);
  width: 100%;
}

.card-image__content {
  background: var(--color-black);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.card-image__text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-image__text *:not(:last-child) {
  margin-bottom: var(--space-between-xxsmall);
}

.card-image__footer {
  margin-top: var(--space-between-small);
}

.card-image__footer button {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .card-image--horizontal {
    flex-direction: row;
    flex-wrap: inherit;
    grid-gap: var(--space-between-large);
    gap: var(--space-between-large);
  }

  .card-image--horizontal-reverse {
    flex-direction: row-reverse;
  }

  .card-image--horizontal .card-image__wrapper,
  .card-image--horizontal-reverse .card-image__wrapper {
    margin-bottom: 0;
    width: 50%;
  }

  .card-image--horizontal .card-image__content,
  .card-image--horizontal-reverse .card-image__content {
    width: 50%;
  }

  .card-image--horizontal .card-image__text,
  .card-image--horizontal-reverse .card-image__text {
    justify-content: flex-start;
    margin-top: 0;
    padding: 0;
    position: relative;
  }
}

@media (hover: hover) {
  .card-image--with-hover .picture img {
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: var(--transition-timing-default);
  }

  .card-image--with-hover:hover .picture img {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
  }
}

.video--with-ratio {
  position: relative;
}

.video--with-ratio::before {
  content: '';
  display: block;
  padding-top: calc(var(--aspect-ratio-16-9) * 100%);
}

.video--with-ratio .video__player {
  left: 0;
  position: absolute;
  top: 0;
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--space-between-xxsmall);
}

.content h1 {
  font-size: var(--font-size-56);
  letter-spacing: var(--letter-spacing-for-56);
  line-height: var(--line-height-for-56);
}

.content h2 {
  font-size: var(--font-size-40);
  letter-spacing: var(--letter-spacing-for-40);
  line-height: var(--line-height-for-40);
}

.content h3,
.content h4,
.content h5,
.content h6 {
  font-size: var(--font-size-32);
  letter-spacing: var(--letter-spacing-for-32);
  line-height: var(--line-height-for-32);
}

.content dd:not(:last-child),
.content ul:not(:last-child),
.content ol:not(:last-child),
.content p:not(:last-child) {
  margin-bottom: var(--space-between-small);
}

.content strong {
  font-weight: var(--font-weight-bold);
}

.content dt {
  font-size: var(--font-size-16);
  letter-spacing: var(--letter-spacing-for-16);
  line-height: var(--line-height-for-16);
  margin-bottom: var(--space-between-xxsmall);
}

.content hr {
  border: 1px solid var(--color-alto);
  margin-bottom: var(--space-between-xxsmall);
  margin-top: var(--space-between-xxsmall);
}

.content ul {
  list-style-type: disc;
  list-style-type: initial;
  padding-left: var(--grid-row-half-gutter);
}

.content ol {
  list-style-type: decimal;
  padding-left: var(--grid-row-half-gutter);
}

.content li:not(:last-child) {
  margin-bottom: var(--space-between-xxxsmall);
}

.content sup {
  font-size: smaller;
  position: relative;
  top: -6px;
  vertical-align: baseline;
}

.content a {
  cursor: pointer;
  position: relative;
}

.content a:before {
  background-color: var(--color-white);
  bottom: 0;
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left;
          transform-origin: left;
  transition: var(--transition-all);
  visibility: hidden;
  width: 100%;
}

.content a:hover:before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  visibility: visible;
}

@media screen and (min-width: 320px) {
  .content h1 {
    font-size: calc(var(--font-size-40) + 56 * var(--proportional-width-for-responsive));
    letter-spacing: calc(var(--letter-spacing-for-40) - 2.4 * var(--proportional-width-for-responsive));
    line-height: calc(var(--line-height-for-40) + 63 * var(--proportional-width-for-responsive));
  }

  .content h2 {
    font-size: calc(var(--font-size-28) + 28 * var(--proportional-width-for-responsive));
    letter-spacing: calc(var(--letter-spacing-for-28) - 0.75 * var(--proportional-width-for-responsive));
    line-height: calc(var(--line-height-for-28) + 36 * var(--proportional-width-for-responsive));
  }

  .content h3,
  .content h4,
  .content h5,
  .content h6 {
    font-size: calc(var(--font-size-24) + 16 * var(--proportional-width-for-responsive));
    letter-spacing: calc(var(--letter-spacing-for-24) - 0.4 * var(--proportional-width-for-responsive));
    line-height: calc(var(--line-height-for-24) + 20 * var(--proportional-width-for-responsive));
  }

  .content dt {
    font-size: calc(var(--font-size-16) + 4 * var(--proportional-width-for-responsive));
    letter-spacing: calc(var(--letter-spacing-for-16) - 0.24 * var(--proportional-width-for-responsive));
    line-height: calc(var(--line-height-for-16) + 4 * var(--proportional-width-for-responsive));
  }
}

@media screen and (min-width: 1920px) {
  .content h1 {
    font-size: var(--font-size-96);
    letter-spacing: var(--letter-spacing-for-96);
    line-height: var(--line-height-for-96);
  }

  .content h2 {
    font-size: var(--font-size-56);
    letter-spacing: var(--letter-spacing-for-56);
    line-height: var(--line-height-for-56);
  }

  .content h3,
  .content h4,
  .content h5 {
    font-size: var(--font-size-40);
    letter-spacing: var(--letter-spacing-for-40);
    line-height: var(--line-height-for-40);
  }

  .content dt {
    font-size: var(--font-size-20);
    letter-spacing: var(--letter-spacing-for-20);
    line-height: var(--line-height-for-20);
  }
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: var(--space-between-xxxxlarge);
  position: relative;
  z-index: 1;
}

.hero--with-media {
  height: 100vh;
  padding-top: 0;
}

.hero--active {
  z-index: var(--z-index-menu);
}

.hero__content {
  position: relative;
  z-index: 100;
}

.hero__logo {
  height: var(--dimension-logo-height);
  position: absolute;
  top: -60%;
  width: var(--dimension-logo-width);
}

.hero__logo-icon {
  height: 100%;
  width: 100%;
}

.hero__content > *:not(:first-child) {
  display: inline-block;
  margin-top: var(--space-between-medium);
}

.hero__media {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: all var(--transition-timing-slow);
  width: 100%;
  z-index: -1;
}

.hero__media::before,
.hero__media::after {
  content: '';
  height: 50vh;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.hero__media::before {
  background: linear-gradient(
    to bottom,
    hsl(0, 0%, 7%) 0%,
    hsla(0, 0%, 7%, 0.987) 1.3%,
    hsla(0, 0%, 7%, 0.951) 4.9%,
    hsla(0, 0%, 7%, 0.896) 10.4%,
    hsla(0, 0%, 7%, 0.825) 17.5%,
    hsla(0, 0%, 7%, 0.741) 25.9%,
    hsla(0, 0%, 7%, 0.648) 35.2%,
    hsla(0, 0%, 7%, 0.55) 45%,
    hsla(0, 0%, 7%, 0.45) 55%,
    hsla(0, 0%, 7%, 0.352) 64.8%,
    hsla(0, 0%, 7%, 0.259) 74.1%,
    hsla(0, 0%, 7%, 0.175) 82.5%,
    hsla(0, 0%, 7%, 0.104) 89.6%,
    hsla(0, 0%, 7%, 0.049) 95.1%,
    hsla(0, 0%, 7%, 0.013) 98.7%,
    hsla(0, 0%, 7%, 0) 100%
  );
  height: 100vh;
  top: 0;
}

.hero__media::after {
  background: var(--gradient-to-top);
  bottom: -1px;
}

.hero__picture,
.hero__picture-picture,
.hero__picture-image {
  height: 100%;
  width: 100%;
}

.hero__video {
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  width: 100%;
}

.hero__video iframe {
  box-sizing: border-box;
  height: 56.25vw;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 177.77777778vh;
}

.hero--active .hero__media {
  z-index: 1;
}

.hero__media-close {
  cursor: pointer;
  position: absolute;
  right: var(--grid-row-half-gutter);
  top: var(--space-between-small);
  z-index: 1;
}

@media screen and (min-width: 1440px) {
  .hero .hero__label-name {
    margin-top: var(--space-between-xxsmall);
  }
}

.business-branch__title-wrapper {
  margin-bottom: var(--space-between-small);
  position: relative;
}

.business-branch__title-wrapper::before {
  content: '';
  display: block;
  padding-bottom: 63%;
  position: relative;
}

.business-branch__title {
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.business-branch__title-background {
  fill: none;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  stroke: var(--color-white);
  top: 0;
  width: 100%;
}

.business-branch__title-background svg {
  height: 100%;
  width: 100%;
}

.business-branch__element:not(:last-child) {
  margin-bottom: var(--space-between-xxsmall);
}

@media screen and (min-width: 1024px) {
  .business-branch__title-wrapper::before {
    padding-bottom: 110%;
  }
}

.business-branches {
  margin-bottom: calc(var(--space-between-medium) * -1);
}

.business-branches__item {
  margin-bottom: var(--space-between-medium);
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .column.grid__item.business-branches__item:nth-child(odd) {
    padding-left: 0;
  }
}

@media screen and (min-width: 1023px) and (max-width: 1279px) {
  .column.grid__item.business-branches__item:nth-child(even) {
    padding-right: 0;
  }
  .column.grid__item.business-branches__item:nth-child(odd) {
    padding-left: 0;
  }
}

@media screen and (min-width: 1280px) {
  .column.grid__item.business-branches__item:first-child {
    padding-left: 0;
  }

  .column.grid__item.business-branches__item:last-child {
    padding-right: 0;
  }
}

.image-full {
  display: flex;
  flex-direction: column;
}

.image-full__caption {
  padding-bottom: 0;
  padding-top: var(--space-between-xxsmall);
}

.image-full__caption p:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .image-full--with-caption {
    flex-direction: row;
  }

  .image-full--with-caption .image-full__picture {
    width: 75%;
  }

  .image-full--with-caption .image-full__caption {
    padding: 0 0 0 var(--grid-row-half-gutter);
    width: 25%;
  }
}

.section__pre-title {
  display: flex;
  flex-direction: column;
}

.section__header {
  margin-bottom: var(--space-between-small);
}

.section__separator .column,
.section__content {
  width: 100%;
}

.section__separator {
  margin-bottom: var(--space-between-small);
  margin-top: var(--space-between-xxsmall);
}

@media screen and (min-width: 1280px) {
  .section__separator {
    margin-bottom: 0;
    margin-top: 0;
    order: -1;
  }
}

.list-grid__description:not(:last-child),
.list-grid__item {
  margin-bottom: var(--space-between-small);
}

.list-grid__grid-wrapper {
  margin-bottom: calc(var(--space-between-small) * -1);
}

.list-grid__cta {
  margin-top: var(--space-between-small);
}

.list-grid .content-with-sidebar__content > .column {
  padding: 0;
}
.subtitle__row {
  position: relative;
}

.subtitle--with-separator .subtitle__row {
  padding-top: var(--space-between-small);
}

.subtitle--with-separator .subtitle__row::before {
  background: var(--color-white);
  content: '';
  height: 1px;
  left: var(--grid-row-half-gutter);
  position: absolute;
  top: 0;
  width: calc(100% - var(--grid-row-gutter));
}

.subtitle__row .column {
  width: 100%;
}

.subtitle__cta {
  margin-top: var(--space-between-small);
}

.subtitle__aside-title:not(:first-of-type) {
  display: block;
  margin-top: var(--space-between-xsmall);
  width: 100%;
}

.subtitle__aside-text {
  display: block;
  margin-top: var(--space-between-xxxsmall);
  width: 100%;
}

@media screen and (max-width: 1439px) {
  .subtitle .content-with-sidebar__left {
    margin-bottom: 0;
    margin-top: var(--space-between-xxlarge);
  }
}

@media screen and (max-width: 1439px) {
  .case-study .subtitle {
    flex-direction: column-reverse;
  }
}
.white-list__wrapper {
  background: var(--color-white);
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  padding: var(--space-between-large) 0;
}

.white-list__wrapper .white-list__form {
  --color-black: #fff;
  --color-white: #000;
}

.white-list__form .contact-form--inverted {
  --color-black: #000;
  --color-white: #fff;
}

.white-list__column {
  padding-left: var(--grid-row-half-gutter);
  padding-right: var(--grid-row-half-gutter);
  width: 100%;
}

.white-list__wrapper .contact-form__title {
  display: block;
  margin-bottom: var(--space-between-xxsmall);
  margin-top: var(--space-between-small);
}

.white-list__title,
.white-list__element:not(:last-child) {
  margin-bottom: var(--space-between-large);
}

.white-list__element-wrapper {
  margin-bottom: calc(var(--space-between-small) * -1);
  margin-top: var(--space-between-small);
}

.white-list__element-item {
  border-bottom: var(--dimension-border) solid var(--color-black);
  padding: var(--space-between-xsmall) 0;
  position: relative;
  z-index: 1;
}

.white-list__element-item-text {
  display: inline-block;
  transition: padding-left var(--transition-timing-default) cubic-bezier(0.4, 0, 0, 1);
  width: 100%;
}

.white-list__element-item::after {
  background: var(--color-black);
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: top center;
          transform-origin: top center;
  transition: -webkit-transform var(--transition-timing-default) cubic-bezier(0.4, 0, 0, 1);
  transition: transform var(--transition-timing-default) cubic-bezier(0.4, 0, 0, 1);
  transition: transform var(--transition-timing-default) cubic-bezier(0.4, 0, 0, 1), -webkit-transform var(--transition-timing-default) cubic-bezier(0.4, 0, 0, 1);
  width: 100%;
  z-index: -1;
}

.white-list__element-item:hover .typography {
  color: var(--color-white);
  z-index: 2;
}

.white-list__element-item:hover .white-list__element-item-text {
  padding-left: var(--grid-row-half-gutter);
}

.white-list__element-item:hover::after {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.white-list__element-wrapper a {
  display: block;
}

.white-list__element-wrapper a:first-child {
  border-top: var(--dimension-border) solid var(--color-black);
}

#oportunidades > .white-list__column {
  padding-left: 0;
  padding-right: 0;
}

@media screen and (min-width: 768px) {
  #oportunidades > .white-list__column {
    margin-left: var(--grid-row-half-gutter);
    margin-right: var(--grid-row-half-gutter);
  }
  .white-list__element-item-text {
    width: 75%;
  }
}

.cms-contact-form__title {
  margin-bottom: var(--space-between-small);
  padding-top: var(--space-between-small);
  position: relative;
}

.cms-contact-form__title:empty {
  display: none;
}

.cms-contact-form__title::before {
  background: var(--color-white);
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.cms-contact-form__column {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .full-row .cms-contact-form__column {
    display: grid;
    grid-template-columns: 2fr 8fr 2fr;
  }

  .full-row .cms-contact-form__column .cms-contact-form__content {
    grid-column-start: 2;
  }
}
.image-content__content {
  padding-left: var(--grid-row-half-gutter);
  padding-right: var(--grid--half-gutter);
}

.image-content__title {
  display: block;
  padding-bottom: var(--space-between-medium);
  padding-top: var(--space-between-xlarge);
}

.image-content__links {
  padding-top: var(--space-between-large);
}

.image-content__link {
  margin-bottom: var(--space-between-small);
}

@media screen and (min-width: 1024px) {
  .image-content {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
  }

  .image-content__wrapper {
    width: calc((5 / 12) * 100%);
  }

  .image-content__content {
    width: calc((6 / 12) * 100%);
  }

  .image-content__links {
    display: flex;
  }

  .image-content__links > * {
    width: calc((1 / 3) * 100%);
  }
}

.list-image-content__title {
  display: block;
  padding-bottom: var(--space-between-xlarge);
}

.list-image-content__grid-item {
  margin-bottom: var(--space-between-xlarge);
}

.list-image-content__wrapper {
  height: var(--dimension-list-image-content-image);
  margin-bottom: var(--space-between-xsmall);
  width: var(--dimension-list-image-content-image);
}

.list-image-content__item-title {
  display: block;
  margin-bottom: var(--space-between-xsmall);
}

.counter-component__column-content {
  font-family: 'JetBrains Mono', monospace;
}

.counter-component__column-pretitle,
.counter-component__column-content,
.counter-component__column-postitle {
  display: block;
}

.counter-component__colum-people {
  margin-bottom: var(--space-between-large);
}

.counter-component__column-content {
  color: var(--color-landing);
}

.counter-component__row-top {
  margin-bottom: var(--space-between-large);
}

.counter-component__drop-list-item {
  margin: var(--space-between-xxxsmall);
  position: relative;
}

.counter-component__drop-list-item,
.counter-component__drop-list-item-icon {
  --height: calc(var(--width) * 589 / 384);

  fill: var(--color-landing);
  height: var(--height);
  width: var(--width);
}

.counter-component__drop-list-item-tooltip {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  visibility: hidden;
  z-index: 1;
}

.counter-component__drop-list-item:hover .counter-component__drop-list-item-tooltip {
  visibility: visible;
}

.counter-component__column-content-items {
  display: flex;
  flex-wrap: wrap;
}

.counter-component__column-content-item {
  min-width: 50%;
  width: 50%;
}

@media screen and (min-width: 375px) {
  .counter-component__colum-people,
  .counter-component__colum-counter {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .counter-component__column-content-item {
    min-width: 25%;
    width: 25%;
  }
}

@media screen and (min-width: 1024px) {
  .counter-component-two-columns .counter-component__colum-people,
  .counter-component-two-columns .counter-component__colum-counter {
    width: 50%;
  }
}
.location-component__title {
  margin-bottom: var(--space-between-small);
}

.location-component__places-item {
  display: flex;
  padding-bottom: var(--space-between-xsmall);
}

.location-component__place-icon {
  height: 16px;
  margin-right: var(--space-between-xxxsmall);
  padding-top: var(--space-between-xxxxsmall);
  width: 10px;
}

.location-component__place-title {
  font-weight: bold;
}

.location-component__place-title,
.location-component__place-place {
  margin-bottom: var(--space-between-xxxsmall);
}

.location-component__place-title,
.location-component__place-place,
.location-component__place-schedule {
  display: block;
  width: 100%;
}

.join-we-share__title {
  display: block;
  margin-bottom: var(--space-between-medium);
}

.form-generic__content-bottom.cookieList {
  margin-top: 0;
  padding-top: 0;
}
.donotmiss__label {
  padding-top: var(--space-between-xxsmall);
  text-transform: uppercase;
}

.donotmiss__title {
  padding-bottom: var(--space-between-medium);
}

.donotmiss__item {
  padding-bottom: var(--space-between-large);
  padding-top: var(--space-between-xsmall);
  position: relative;
}

.donotmiss__item::before {
  background: var(--color-white);
  content: '';
  height: 1px;
  left: var(--grid-row-half-gutter);
  position: absolute;
  top: 0;
  width: calc(100% - var(--grid-row-gutter));
}

.donotmiss__content {
  padding-top: var(--space-between-xxsmall);
  position: relative;
}

.donotmiss .divider__item {
  padding-top: var(--space-between-small);
}

@media screen and (min-width: 769px) {
  .donotmiss__label {
    padding-top: var(--space-between-xsmall);
  }

  .donotmiss__title {
    padding-bottom: var(--space-between-xlarge);
  }

  .donotmiss__item {
    padding-top: var(--space-between-xsmall);
  }

  .donotmiss__content {
    padding-top: var(--space-between-xsmall);
  }

  .donotmiss .divider__item {
    padding-bottom: var(--space-between-xlarge);
    padding-top: var(--space-between-medium);
  }
}
.slider {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.slider--with-gradient {
  overflow: hidden;
  position: relative;
}

.slider__carousel {
  outline: none;
  padding-bottom: var(--space-between-small);
}

.slider .is-fullscreen {
  padding-bottom: 0;
}

.slider__card {
  height: auto;
  margin-right: var(--grid-row-quarter-gutter);
  max-width: 100vw;
}

.slider__card--no-margin {
  margin-right: 0;
}

.body .flickity-enabled.is-fullscreen {
  overflow: hidden;
}

.slider .flickity-enabled.is-fullscreen {
  background: var(--color-black);
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}

.slider .flickity-enabled.is-fullscreen .slider__card {
  height: 100vh;
}

.slider .flickity-enabled.is-fullscreen .video-component {
  margin: auto;
  width: auto;
}

.slider .flickity-enabled.is-fullscreen .video-component iframe {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.slider .flickity-viewport {
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: height 0.2s;
}

.slider--with-gradient .flickity-viewport {
  overflow: visible;
}

.slider .is-fullscreen .flickity-viewport {
  height: 100vh;
}

.flickity-fullscreen-button-view,
.flickity-fullscreen-button-exit {
  display: none;
}

.slider__carousel-nav .slider__card {
  width: 50%;
}

.slider__buttons {
  display: flex;
  margin-bottom: var(--space-between-xxsmall);
  width: calc(var(--dimension-button) * 2);
}

.slider__buttons .button {
  height: var(--dimension-button);
  width: var(--dimension-button);
}

.slider__buttons .button:first-child {
  border-right: none;
}

.slider__fullscreen {
  position: absolute;
  right: 0;
  top: calc(var(--space-between-large) * -1.5);
  width: var(--dimension-button);
  z-index: 9;
}

.slider__fullscreen .iconography {
  fill: var(--color-black);
}

.slider__fullscreen svg {
  display: flex;
}

.slider .flickity-enabled.is-fullscreen .slider__fullscreen {
  right: 50px;
  top: 0;
}

.slider__progress {
  background: var(--color-black);
}

.slider .flickity-page-dots {
  list-style: none;
  margin: var(--space-between-medium) 0 0;
  padding: 0;
  width: 100%;
}

.slider .flickity-page-dots .dot {
  cursor: pointer;
  display: inline-block;
}

.slider .flickity-page-dots .dot:not(:last-child) {
  margin-right: var(--grid-row-quarter-gutter);
}

.slider .flickity-page-dots .dot::before {
  content: '\25CB';
}

.slider .flickity-page-dots .dot:hover::before,
.slider .flickity-page-dots .dot.is-selected::before {
  content: '\25CF';
}

.slider__buttons .button {
  transition: none;
}

.slider__buttons span.button__content span {
  font-size: var(--font-size-20);
  font-weight: lighter;
  height: 32px;
  line-height: var(--line-height-for-26);
  width: 26px;
}

@media screen and (min-width: 768px) {
  .slider--with-gradient::after {
    background: var(--gradient-to-left);
    content: '';
    display: block;
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: calc((var(--grid-row-half-gutter) + var(--grid-full-row-margin)) * -1);
    top: 0;
    width: calc((100vw - var(--grid-row-width) - (var(--grid-full-row-margin))) / 2);
    z-index: var(--z-index-slider-gradient);
  }

  /* stylelint-disable  */
  .slider--thinner .slider__carousel {
    margin-bottom: calc(var(--space-between-medium) + 16px);
    max-width: calc(
      var(--grid-row-width) +
        (100vw - var(--grid-row-width) - (var(--grid-row-gutter) + var(--grid-full-row-margin)) * 2) / 2
    );
  }
  /* stylelint-enable  */

  .slider__carousel {
    flex-grow: 1;
    order: 2;
  }

  .slider__card {
    height: auto;
    margin-right: var(--grid-row-gutter);
  }

  .slider .flickity-enabled.is-fullscreen .slider__card {
    margin-right: 0;
    max-height: 100vh;
    max-width: 100vw;
  }

  .slider__carousel-nav .slider__card {
    width: 20%;
  }

  .slider__buttons {
    margin-top: 0;
  }

  .slider .flickity-page-dots {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--grid-row-width);
    padding-left: var(--grid-row-half-gutter);
    padding-right: var(--grid-row-half-gutter);
  }

  .slider--thinner .flickity-page-dots {
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

@media screen and (min-width: 1280px) {
  .slider__fullscreen {
    top: calc(var(--space-between-large) * -1);
  }

  .slider__carousel {
    padding-bottom: 0;
  }

  .slider .flickity-enabled.is-fullscreen .slider__card {
    top: 100px;
  }
}

@media screen and (min-width: 1440px) {
  .slider {
    flex-direction: row;
  }

  .slider__buttons {
    flex-direction: column;
    height: calc(var(--dimension-button) * 2);
    margin-right: var(--grid-row-gutter);
    width: var(--dimension-button);
  }

  .slider__buttons .button:first-child {
    border-bottom: none;
    border-right: var(--dimension-border) solid var(--color-black);
  }

  .slider__fullscreen {
    display: none;
    top: 0;
  }

  .slider__card:hover .slider__fullscreen {
    display: flex;
  }
}

@media screen and (min-width: 1920px) {
  .slider .flickity-enabled.is-fullscreen .slider__card {
    top: 50px;
  }
}

.whatsnew .divider__item {
  padding-bottom: var(--space-between-small);
  padding-top: var(--space-between-small);
}

.whatsnew .slider__buttons {
  display: none;
}

.whatsnew .row {
  margin-bottom: var(--space-between-medium);
}

.whatsnew .whatsnew__slider {
  width: 100%;
}

.whatsnew .whatsnew__title {
  padding-bottom: var(--space-between-medium);
}

@media screen and (min-width: 769px) {
  .whatsnew .slider__buttons {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 34%;
    width: 100%;
  }

  .whatsnew .slider__buttons .slider__button-next {
    left: 100%;
    -webkit-transform: translateX(calc(-100% - var(--space-between-xxlarge)));
            transform: translateX(calc(-100% - var(--space-between-xxlarge)));
    z-index: 1;
  }

  .whatsnew .slider__buttons .slider__button-previous {
    left: 0;
    -webkit-transform: translateX(var(--space-between-large));
            transform: translateX(var(--space-between-large));
    z-index: 1;
  }

  .whatsnew .slider__buttons .slider__button-next.is-disabled,
  .whatsnew .slider__buttons .slider__button-previous.is-disabled {
    visibility: hidden;
  }

  .whatsnew .slider__buttons .button {
    background-color: var(--color-white);
    border: var(--dimension-border) solid var(--color-white);
    border-radius: 50%;
    color: var(--color-black);
    padding: var(--space-between-medium);
  }

  .whatsnew .slider__buttons .button:hover {
    background-color: var(--color-black);
    border: var(--dimension-border) solid var(--color-black);
    color: var(--color-white);
  }

  .whatsnew .column {
    width: 100%;
  }

  .whatsnew .row {
    grid-column-start: 2;
    margin-bottom: var(--space-between-xlarge);
  }

  .whatsnew .whatsnew__title {
    padding-bottom: var(--space-between-xlarge);
  }

  .whatsnew .divider__item {
    padding-bottom: var(--space-between-medium);
    padding-top: var(--space-between-medium);
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .whatsnew .slider__buttons {
    top: 30%;
  }
}

.teamtailor .teamtailor__column {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.teamtailor__wrapper {
  background: var(--color-white);
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  padding: var(--space-between-large) 0;
}

.teamtailor__title {
  margin-bottom: var(--space-between-large);
}

.teamtailor__wrapper .contact-form__title {
  display: block;
  margin-bottom: var(--space-between-xxsmall);
  margin-top: var(--space-between-small);
}

.teamtailor-jobs__filters {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.teamtailor__form .teamtailor-jobs__select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: var(--space-between-xxxsmall) var(--space-between-small);
}

.teamtailor__form .teamtailor-jobs__select-wrapper {
  position: relative;
}

.teamtailor__form .teamtailor-jobs__select.focus-visible {
  outline: none;
}

.teamtailor__form .teamtailor-jobs__select:focus-visible {
  outline: none;
}

.teamtailor__form .teamtailor-jobs__select-wrapper::before {
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: '';
  height: 8px;
  position: absolute;
  right: 4px;
  top: 35%;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  width: 8px;
  z-index: -1;
}

.teamtailor__form .teamtailor-jobs__job-wrapper {
  border-bottom: 1px solid var(--color-black);
  border-top: 1px solid var(--color-black);
  min-height: var(--space-between-medium);
  width: 100%;
}

/* .teamtailor__form .teamtailor-jobs__job-wrapper .teamtailor-jobs__job:nth-child(even) {
  background-color: var(--color-black);
  color: var(--color-white);
} */

.teamtailor__form .teamtailor-jobs__job-wrapper .teamtailor-jobs__job:hover {
  padding-left: var(--space-between-small);
}

.teamtailor__form .teamtailor-jobs__job-wrapper .teamtailor-jobs__job:hover {
  background-color: var(--color-black);
  color: var(--color-white);
}

/* .teamtailor__form .teamtailor-jobs__job-wrapper .teamtailor-jobs__job:nth-child(even):hover {
  background-color: var(--color-white);
  color: var(--color-black);
} */

/* .teamtailor__form .teamtailor-jobs__job-wrapper .teamtailor-jobs__job:nth-child(odd):hover {
  background-color: var(--color-black);
  color: var(--color-white);
} */

.teamtailor__form .teamtailor-jobs__job {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
  padding: var(--space-between-xsmall) 0 var(--space-between-xsmall) var(--space-between-xsmall);
  position: relative;
  transition: all var(--transition-timing-default);
  width: 100%;
  z-index: 1;
}

.teamtailor__form .teamtailor-jobs__job:not(:first-child) {
  border-top: 1px solid var(--color-black);
}

.teamtailor__form .teamtailor-jobs__job::after {
  background: var(--color-black);
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: top center;
          transform-origin: top center;
  transition: -webkit-transform var(--transition-timing-default) cubic-bezier(0.4, 0, 0, 1);
  transition: transform var(--transition-timing-default) cubic-bezier(0.4, 0, 0, 1);
  transition: transform var(--transition-timing-default) cubic-bezier(0.4, 0, 0, 1), -webkit-transform var(--transition-timing-default) cubic-bezier(0.4, 0, 0, 1);
  width: 100%;
  z-index: -1;
}

.teamtailor__form .teamtailor-jobs__job:hover::after {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

/* .teamtailor__form .teamtailor-jobs__job-wrapper .teamtailor-jobs__job:nth-child(even)::after {
  background: var(--color-white);
  color: var(--color-black);
} */

.teamtailor__form .teamtailor-jobs__job-title {
  cursor: pointer;
  font-size: calc(var(--font-size-18) + 2 * var(--proportional-width-for-responsive));
  letter-spacing: var(--letter-spacing-for-18);
  line-height: var(--line-height-for-18);
  margin-bottom: var(--space-between-xxsmall);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.teamtailor__form .teamtailor-jobs__job-info {
  font-size: var(--font-size-16);
  letter-spacing: var(--letter-spacing-for-16);
  line-height: var(--line-height-for-16);
}

.teamtailor__form {
  margin-bottom: calc(var(--space-between-small));
  margin-top: var(--space-between-small);
  width: 100%;
}

.white-list__content-teamtailor {
  margin-bottom: var(--space-between-small);
  margin-top: var(--space-between-small);
}

.teamtailor .inline-button,
.teamtailor .inline-button--with-hover:before {
  --color-black: #fff;
  --color-white: #131313;
}

@media screen and (min-width: 1920px) {
  .teamtailor__form .teamtailor-jobs__select {
    font-size: var(--font-size-20);
    letter-spacing: var(--letter-spacing-for-20);
    line-height: var(--line-height-for-20);
  }
}

@media screen and (min-width: 768px) {
  .teamtailor-jobs__filters {
    flex-direction: row;
  }

  .teamtailor .teamtailor__column {
    padding-left: var(--grid-row-half-gutter);
    padding-right: var(--grid-row-half-gutter);
  }
}

@media screen and (min-width: 320px) {
  .teamtailor__form .teamtailor-jobs__select {
    font-size: calc(var(--font-size-18) + 2 * var(--proportional-width-for-responsive));
    letter-spacing: var(--letter-spacing-for-18);
    line-height: var(--line-height-for-18);
  }
}

.custom-content .column {
  width: 100%;
}

.custom-content__title {
  margin-bottom: var(--space-between-xlarge);
  margin-top: var(--space-between-xlarge);
}

.custom-content__card-title {
  margin-bottom: var(--space-between-xxxsmall);
  margin-top: var(--space-between-xxsmall);
}

.custom-content__grid {
  display: flex;
  flex-flow: row wrap;
}

.custom-content__grid-item {
  margin-bottom: var(--space-between-xlarge);
}

.custom-content__card-content {
  line-height: 32px;
}

.custom-content--inverted .locations {
  background-color: var(--color-white);
  color: var(--color-black);
}

.body--inverted .contact-form .hs-button {
  background-color: var(--color-white);
  border: var(--dimension-border) solid var(--color-white);
  color: var(--color-black);
}

.body--inverted .contact-form .hs-button:hover {
  background-color: var(--color-black);
  color: var(--color-white);
}

.body--inverted input:hover,
.body--inverted input:focus,
.body--inverted input::selection,
.body--inverted input:-webkit-autofill {
  background: none;
  transition: background 0.6s ease-in-out 0s;
}
.logo-repeater__title {
  display: block;
  margin-bottom: var(--space-between-medium);
}

.logo-repeater__grid {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: calc(var(--space-between-small) * -1);
}

.logo-repeater__grid-item {
  margin-bottom: var(--space-between-small);
  padding-left: var(--grid-row-half-gutter);
  padding-right: var(--grid-row-half-gutter);
  width: 50%;
}

.logo-repeater__picture img {
  max-width: 100%;
  width: auto;
}

@media screen and (min-width: 768px) {
  .logo-repeater__grid-item {
    width: 25%;
  }
}
.stats-summary {
  margin-bottom: calc(var(--space-between-small) * -1);
}

.stats-summary__item {
  margin-bottom: var(--space-between-small);
}

.stats-summary__item-title {
  border-top: var(--dimension-border) solid var(--color-white);
  margin-bottom: var(--space-between-xxsmall);
  padding-top: var(--space-between-xxxsmall);
}

.stats-summaries__selector {
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .stats-summaries .row.stats-summaries__selector-wrapper .column {
    padding: 0;
  }
}

@media screen and (min-width: 1440px) {
  .stats-summaries__selector-wrapper {
    align-content: flex-start;
    flex-direction: column;
  }

  .stats-summaries__selector:not(:last-child) {
    margin-bottom: var(--space-between-xxsmall);
  }
}

.divider__column {
  width: 100%;
}

.divider__item {
  border: none;
  border-top: 1px solid var(--color-white);
  margin: 0;
}

.gallery {
  flex-direction: column;
}

.gallery__column {
  width: 100%;
}

.gallery__title {
  margin-bottom: var(--space-between-small);
}

.gallery__video {
  cursor: pointer;
  position: relative;
}

.gallery__video-play {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.relation__grid {
  margin-bottom: calc(var(--space-between-small) * -1);
}

.relation .section__header {
  margin-bottom: var(--space-between-xxlarge);
}

.relation__grid-item {
  margin-bottom: var(--space-between-small);
}

.generic-items > * {
  margin-bottom: var(--space-between-xxlarge);
  margin-top: var(--space-between-xxlarge);
}

.generic-items .divider {
  margin-bottom: var(--space-between-large);
  margin-top: var(--space-between-xxxlarge);
}

.generic-items .divider + * {
  margin-top: var(--space-between-large);
}

.generic-items > :first-child {
  margin-top: 0;
}

.inline-tag {
  background-color: transparent;
  border: 1px solid var(--color-white);
  border-radius: 16px;
  color: var(--color-white);
  cursor: pointer;
  display: inline-block;
  margin: 2px 0 2px;
  outline: 0;
  padding: 2px 12px;
  text-transform: uppercase;
}

.inline-tag--with-hover {
  cursor: pointer;
  position: relative;
}

.body--inverted .header .inline-tag--with-hover:before {
  background-color: var(--color-black);
}

.header a:hover .inline-tag--with-hover:before,
.footer a:hover .inline-tag--with-hover:before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  visibility: visible;
}

.inline-tag--inverted.inline-tag--with-hover:before {
  background-color: var(--color-black);
}

.inline-tag--with-hover:hover {
  background-color: var(--color-white);
  color: var(--color-black);
}

.inline-tag--inverted {
  background-color: var(--color-white);
  color: var(--color-black);
}

.inline-tag:disabled {
  opacity: 0.25;
  pointer-events: none;
}

.resource-preview-title {
  text-transform: uppercase;
}

.resource-preview-container {
  padding-bottom: var(--space-between-large);
  padding-top: var(--space-between-medium);
  position: relative;
}

.resource-preview-container::before {
  background: var(--color-white);
  content: '';
  height: 1px;
  left: var(--grid-row-half-gutter);
  position: absolute;
  top: 0;
  width: calc(100% - var(--grid-row-gutter));
}
.resource__title {
  margin-bottom: var(--space-between-xlarge);
  margin-top: var(--space-between-xlarge);
}

.case-study .resource__view-all {
  margin-bottom: var(--space-between-xxxlarge);
}

.case-studies__hero {
  margin-bottom: var(--space-between-xxlarge);
}

.case-studies__list {
  margin-bottom: var(--space-between-xlarge);
}

.case-studies__list-item {
  margin-bottom: var(--space-between-xlarge);
}

.case-studies__generic-items {
  margin-top: var(--space-between-xxlarge);
}

.case-studies .resource-preview {
  max-width: 100%;
  padding-left: var(--grid-row-half-gutter);
  padding-right: var(--grid-row-half-gutter);
}

@media screen and (min-width: 640px) {
  .resource-preview .card-image__wrapper {
    width: 40%;
  }

  .resource-preview .card-image {
    flex-wrap: nowrap;
  }

  .resource-preview .card-image__content {
    flex-grow: 1;
    max-width: 60%;
  }
}

.case-study__generic-items {
  margin-top: var(--space-between-xxlarge);
}

.resource-preview-container--first::before {
  display: none;
}
.resources__hero {
  margin-bottom: var(--space-between-xxlarge);
}

.resources__list {
  margin-bottom: calc(var(--space-between-xlarge) * -1);
}

.resources__list-item {
  margin-bottom: var(--space-between-xlarge);
}

.resources__generic-items {
  margin-top: var(--space-between-xxlarge);
}

.resources__filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 12px;
  gap: 12px;
  margin-bottom: var(--space-between-large);
}

.resource__label {
  text-transform: uppercase;
}

.resource__text {
  padding-bottom: var(--space-between-xxlarge);
  padding-top: var(--space-between-medium);
}

.resource__generic-items {
  margin-top: var(--space-between-xxlarge);
}

.resource__backlink {
  margin-bottom: var(--space-between-xxxlarge);
  padding-bottom: var(--space-between-medium);
  padding-top: var(--space-between-medium);
  position: relative;
}

.resource__backlink::after {
  background: var(--color-white);
  bottom: 0;
  content: '';
  height: 1px;
  left: var(--grid-row-half-gutter);
  position: absolute;
  width: calc(100% - var(--grid-row-gutter));
}

.resource__backlink::before {
  background: var(--color-white);
  content: '';
  height: 1px;
  left: var(--grid-row-half-gutter);
  position: absolute;
  top: 0;
  width: calc(100% - var(--grid-row-gutter));
}

.resource__form-title {
  padding-bottom: var(--space-between-medium);
  padding-top: var(--space-between-xlarge);
  position: relative;
}

.resource__form-title::before {
  background: var(--color-white);
  content: '';
  height: 1px;
  left: var(--grid-row-half-gutter);
  position: absolute;
  top: 0;
  width: calc(100% - var(--grid-row-gutter));
}

.resource__form {
  margin-bottom: var(--space-between-xxxlarge);
  padding-top: var(--space-between-xlarge);
}

@media screen and (min-width: 1440px) {
  .resource__backlink .content-with-sidebar__left:not(:empty) {
    margin-bottom: 0;
  }

  .resource__backlink .content-with-sidebar__left,
  .resource__backlink .content-with-sidebar__right,
  .resource__backlink .content-with-sidebar__content {
    flex: auto;
    width: 100%;
  }
}
.homepage__element {
  margin-bottom: var(--space-between-xxlarge);
}

.homepage__element-content {
  padding-top: var(--space-between-medium);
  position: relative;
}

.homepage__element-content::before {
  background: var(--color-white);
  content: '';
  height: 1px;
  left: var(--grid-row-half-gutter);
  position: absolute;
  top: 0;
  width: calc(100% - var(--grid-row-gutter));
}

.homepage .hero {
  margin-bottom: var(--space-between-xxlarge);
}
.job-position__generic-items {
  margin-top: var(--space-between-xxlarge);
}

.job-position__form {
  background: var(--color-white);
  justify-content: center;
  margin-left: calc(var(--grid-full-row-margin) + var(--grid-row-half-gutter));
  margin-right: calc(var(--grid-full-row-margin) + var(--grid-row-half-gutter));
  padding-bottom: var(--space-between-xxlarge);
  padding-top: var(--space-between-xlarge);
}

.job-position__form > div {
  width: 100%;
}

.job-position__form .inline-button {
  margin-bottom: var(--space-between-medium);
}

@media (min-width: 1440px) {
  .job-position__form .white-list__column {
    padding-left: var(--grid-row-half-gutter);
    padding-right: var(--grid-row-half-gutter);
  }
  .job-position__form .white-list__column > div {
    width: 100%;
  }
}
.page__hero {
  margin-bottom: var(--space-between-xxlarge);
}

:root {
  --hero-ty: 0%;
  --hero-rt: 0deg;
}

.page-landing {
  --color-landing: var(--color-red);
}

.page-landing-donations-water {
  --color-landing: var(--color-blue);
}

.page__hero--donations {
  background: var(--color-red);
  box-sizing: border-box;
  outline: 0;
  overflow: hidden;
  padding-bottom: var(--space-between-xxxxlarge);
  position: relative;
}

.page__hero--donations-water {
  background: var(--color-blue);
}

.page__hero--donations::after {
  background-color: var(--color-black);
  border-radius: 30%;
  bottom: -50%;
  box-sizing: border-box;
  content: '';
  height: 200%;
  left: -50%;
  position: absolute;

  -webkit-transform: translateY(0%) rotate(0deg);

          transform: translateY(0%) rotate(0deg);

  -webkit-transform: translateY(var(--hero-ty)) rotate(var(--hero-rt));

          transform: translateY(var(--hero-ty)) rotate(var(--hero-rt));
  width: 200%;
}

@media screen and (max-width: 767px) {
  .page__hero--donations::after {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .page__hero--donations::after {
    background-color: var(--color-black);
    border-radius: 30%;
    bottom: -50%;
    box-sizing: border-box;
    content: '';
    height: 200vw;
    left: -50%;
    position: absolute;

    -webkit-transform: translateY(0%) rotate(0deg);

            transform: translateY(0%) rotate(0deg);

    -webkit-transform: translateY(var(--hero-ty)) rotate(var(--hero-rt));

            transform: translateY(var(--hero-ty)) rotate(var(--hero-rt));
    width: 200vw;
  }
}

.lin3rs {
  margin-bottom: calc(var(--space-between-medium) * -1);
}

.lin3rs__item {
  margin-bottom: var(--space-between-medium);
}

.people__hero {
  margin-bottom: var(--space-between-xxlarge);
}

.modal {
  box-shadow: var(--box-shadow);
  height: 100%;
  margin: auto;
  max-width: var(--body-max-width);
  overflow-y: scroll;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
  z-index: var(--z-index-modal);
}

.modalClose {
  cursor: pointer;
  margin-right: var(--spacer-06);
  margin-top: var(--spacer-04);
  position: absolute;
  right: 0;
  z-index: var(--z-index-modal);
}

.modalOpened {
  overflow: hidden;
}

.modal:focus {
  outline: none;
}

.modalBody {
  --grid-width: 100%;

  background-color: var(--color-grey-000);
  border-radius: 20px;
  height: 100%;
  position: relative;
}

.modalContentChild {
  height: 100%;
  padding: var(--spacer-10) var(--spacer-08);
}

.ReactModal__Overlay {
  background: none !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: auto;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  z-index: 3;
}

@media screen and (min-width: 1024px) {
  .modal {
    height: auto;
    max-height: 100vh;
    overflow-y: auto;
  }

  .modalBody {
    --grid-width: 960px;
  }

  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    margin-bottom: var(--space-between-small);
    margin-left: auto;
    margin-right: var(--space-between-small);
  }
}

@media screen and (min-width: 1280px) {
  .modalBody {
    --grid-width: 1096px;
  }
}

@media screen and (min-width: 1440px) {
  .modalBody {
    --grid-width: 1288px;
    --grid-item-span: 12;
  }
}

.newsletter-popup {
  background-color: var(--color-white);
  border-color: var(--color-black);
  border-radius: var(--space-between-small);
  color: var(--color-black);
  max-width: 510px;
  width: 100%;
}

.newsletter-popup:focus,
.newsletter-popup.focus-visible {
  border: none;
}

.newsletter-popup:focus,
.newsletter-popup:focus-visible {
  border: none;
}

.newsletter-popup .iconography {
  fill: var(--color-black);
}

.newsletter-popup div.grid .modal-top {
  display: flex;
  justify-content: space-between;
}

.newsletter-popup .modal-top__title > * {
  display: block;
  margin-bottom: var(--space-between-xxxsmall);
}

.newsletter-popup .modal-top__title .typography--big-body {
  font-size: var(--font-size-20);
}

.newsletter-popup .modalClose {
  cursor: pointer;
}

.newsletter-popup div.grid:nth-child(1) {
  padding: var(--space-between-small);
}

/* newsletter popup contact form */
.newsletter-popup__contact-form {
  color: var(--color-black);
  padding-top: var(--space-between-xxsmall);
}

.newsletter-popup__contact-form .contact-form__content {
  margin-top: 0;
}

.newsletter-popup__contact-form .hs-form {
  font-size: var(--font-size-20);
  position: relative;
}

.newsletter-popup__contact-form .hs-form .hs_email .hs-input {
  border-bottom: 1px solid var(--color-black);
  color: var(--color-black);
  padding-bottom: var(--space-between-xxsmall);
  width: 100%;
}

.newsletter-popup__contact-form .hs-form .hs_email .hs-input::-webkit-input-placeholder {
  color: var(--color-black);
}

.newsletter-popup__contact-form .hs-form .hs_email .hs-input::-ms-input-placeholder {
  color: var(--color-black);
}

.newsletter-popup__contact-form .hs-form .hs_email .hs-input::placeholder {
  color: var(--color-black);
}

.newsletter-popup__contact-form .hs-form .legal-consent-container {
  font-size: var(--font-size-16);
}

.newsletter-popup__contact-form .hs-form .legal-consent-container .hs-form-booleancheckbox-display p {
  color: var(--color-black);
}

.newsletter-popup__contact-form .hs-form .legal-consent-container .hs-form-booleancheckbox-display .hs-form-required {
  color: var(--color-black);
}

.newsletter-popup__contact-form .hs-form .hs-submit {
  position: absolute;
  right: 0;
  top: 0;
}

.newsletter-popup__contact-form .hs-form .hs-submit .actions .hs-button {
  background: none;
  border: 0;
  color: var(--color-black);
  font-size: 24px;
  height: auto;
  height: 37px;
  min-width: 32px;
  padding-right: 0;
  transition: none;
  z-index: 1;
}

.newsletter-popup__contact-form .hs-form-field input[type="checkbox"] {
  border-color: var(--color-black);
}

.newsletter-popup__contact-form .hs-form-field input[type="checkbox"]:checked {
  background: var(--color-black);
}

.newsletter-popup .hs-form-field:not(.hs-fieldtype-booleancheckbox) {
  padding-bottom: 16px;
}

.newsletter-popup .hs-submit {
  margin-top: 0;
}

.contact {
  background: var(--color-white);
  color: var(--color-black);
  margin-top: var(--space-between-xxxlarge);
  padding-bottom: var(--space-between-xxlarge);
  padding-top: var(--space-between-large);
}

.contact > * {
  width: 100%;
}

div:has(#find-us) {
  margin-bottom: 0;
}

#oportunidades {
  margin-bottom: -1px;
}

div:has(#oportunidades) {
  margin-bottom: 0px;
}
/* Eric Meyer's Reset CSS v2.0
/ (http://meyerweb.com/eric/tools/css/reset/) http://cssreset.com */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font-size: 100%;
  font-style: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

textarea {
  resize: none;
}

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}

a {
  color: inherit;
  outline: 0;
  text-decoration: none;
}

img {
  display: inline-block;
  height: auto;
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
  vertical-align: middle;
}

input:hover,
input:focus,
input::selection,
input:-webkit-autofill {
  background: none;
  transition: background 5000s ease-in-out 0s;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

input[placeholder],
::-moz-placeholder,
input:-moz-placeholder {
  text-overflow: ellipsis;
}

:root {
  --color-white: #fff;
  --color-black: #131313;
  --color-pomegranate: #f44336;
  --color-alto: #d8d8d8;
  --color-dusty-gray: #979797;
  --color-mine-shaft: #333;
  --color-error: #f44336;
  --color-red: #f44336;
  --color-blue: #04caf5;

  --font-family-default: 'Inter', sans-serif;

  --font-weight-regular: 400;
  --font-weight-bold: 700;

  --font-size-136: 136px;
  --font-size-96: 96px;
  --font-size-72: 72px;
  --font-size-56: 56px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-28: 28px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;

  --line-height-for-136: 142px;
  --line-height-for-96: 112px;
  --line-height-for-72: 84px;
  --line-height-for-56: 72px;
  --line-height-for-40: 49px;
  --line-height-for-32: 38px;
  --line-height-for-28: 36px;
  --line-height-for-26: 32px;
  --line-height-for-24: 29px;
  --line-height-for-20: 24px;
  --line-height-for-18: 24px;
  --line-height-for-16: 20px;
  --line-height-for-14: 16px;

  --letter-spacing-for-136-2: -8px;
  --letter-spacing-for-136: -5.67px;
  --letter-spacing-for-96: -4px;
  --letter-spacing-for-72: -3.2px;
  --letter-spacing-for-56: -2.15px;
  --letter-spacing-for-40: -1.6px;
  --letter-spacing-for-32: -1.4px;
  --letter-spacing-for-28: -1.4px;
  --letter-spacing-for-24: -1.2px;
  --letter-spacing-for-20-2: -0.8px;
  --letter-spacing-for-20: -1.2px;
  --letter-spacing-for-18: -1.2px;
  --letter-spacing-for-16: -0.96px;
  --letter-spacing-for-14: -0.6px;

  --grid-row-gutter: 32px;
  --grid-row-half-gutter: calc(var(--grid-row-gutter) / 2);
  --grid-row-quarter-gutter: calc(var(--grid-row-gutter) / 4);

  --grid-row-width: 100%;
  --grid-full-row-margin: 0;

  --space-between-xxxxsmall: 4px;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 8px;
  --space-between-xsmall: 16px;
  --space-between-small: 16px;
  --space-between-medium: 32px;
  --space-between-large: 40px;
  --space-between-xlarge: 64px;
  --space-between-xxlarge: 80px;
  --space-between-xxxlarge: 160px;
  --space-between-xxxxlarge: 225px;

  --aspect-ratio-9-16: 16 / 9;
  --aspect-ratio-3-4: 4 / 3;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;

  --dimension-border: 1px;
  --dimension-header-height: 80px;
  --dimension-button: 64px;

  --dimension-logo-height: 32px;
  --dimension-logo-width: 131px;
  --dimension-list-image-content-image: 84px;

  --transition-timing-none: 0s;
  --transition-timing-default: 0.3s;
  --transition-timing-fast: 0.2s;
  --transition-timing-slow: 0.5s;
  --transition-timing-slow-2: 0.6s;
  --transition-ease-default: ease-in-out;
  --transition-all: all var(--transition-timing-fast) var(--transition-ease-default);
  --transition-background-color: background-color var(--transition-timing-default) var(--transition-ease-default);
  --transition-color: color var(--transition-timing-default) var(--transition-ease-default);
  --transition-fill: fill var(--transition-timing-default) var(--transition-ease-default);
  --transition-transform: transform var(--transition-timing-default) var(--transition-ease-default);
  --transition-transform-fast: transform var(--transition-timing-fast) var(--transition-ease-default);
  --transition-height: height var(--transition-timing-fast) var(--transition-ease-default);
  --transition-opacity: opacity var(--transition-timing-default) var(--transition-ease-default);
  --transition-opacity-button: var(--transition-timing-slow-2) var(--transition-ease-default)
    var(--transition-timing-none);

  --iconography-size-default: 24px;
  --iconography-size-big: 32px;

  --z-index-menu: 30;
  --z-index-slider-gradient: 40;
  --z-index-slider-fullscreen: 50;
  --z-index-cookie-banner: 100;

  --proportional-width-for-responsive: calc((100vw - 320px) / (1920 - 320));

  --gradient-to-bottom: linear-gradient(
    to bottom,
    hsl(0, 0%, 7%) 0%,
    hsla(0, 0%, 7%, 0.987) 8.1%,
    hsla(0, 0%, 7%, 0.951) 15.5%,
    hsla(0, 0%, 7%, 0.896) 22.5%,
    hsla(0, 0%, 7%, 0.825) 29%,
    hsla(0, 0%, 7%, 0.741) 35.3%,
    hsla(0, 0%, 7%, 0.648) 41.2%,
    hsla(0, 0%, 7%, 0.55) 47.1%,
    hsla(0, 0%, 7%, 0.45) 52.9%,
    hsla(0, 0%, 7%, 0.352) 58.8%,
    hsla(0, 0%, 7%, 0.259) 64.7%,
    hsla(0, 0%, 7%, 0.175) 71%,
    hsla(0, 0%, 7%, 0.104) 77.5%,
    hsla(0, 0%, 7%, 0.049) 84.5%,
    hsla(0, 0%, 7%, 0.013) 91.9%,
    hsla(0, 0%, 7%, 0) 100%
  );
  --gradient-to-left: linear-gradient(
    to left,
    hsl(0, 0%, 7%) 0%,
    hsla(0, 0%, 7%, 0.987) 8.1%,
    hsla(0, 0%, 7%, 0.951) 15.5%,
    hsla(0, 0%, 7%, 0.896) 22.5%,
    hsla(0, 0%, 7%, 0.825) 29%,
    hsla(0, 0%, 7%, 0.741) 35.3%,
    hsla(0, 0%, 7%, 0.648) 41.2%,
    hsla(0, 0%, 7%, 0.55) 47.1%,
    hsla(0, 0%, 7%, 0.45) 52.9%,
    hsla(0, 0%, 7%, 0.352) 58.8%,
    hsla(0, 0%, 7%, 0.259) 64.7%,
    hsla(0, 0%, 7%, 0.175) 71%,
    hsla(0, 0%, 7%, 0.104) 77.5%,
    hsla(0, 0%, 7%, 0.049) 84.5%,
    hsla(0, 0%, 7%, 0.013) 91.9%,
    hsla(0, 0%, 7%, 0) 100%
  );
  --gradient-to-top: linear-gradient(
    to top,
    hsl(0, 0%, 7%) 0%,
    hsla(0, 0%, 7%, 0.987) 8.1%,
    hsla(0, 0%, 7%, 0.951) 15.5%,
    hsla(0, 0%, 7%, 0.896) 22.5%,
    hsla(0, 0%, 7%, 0.825) 29%,
    hsla(0, 0%, 7%, 0.741) 35.3%,
    hsla(0, 0%, 7%, 0.648) 41.2%,
    hsla(0, 0%, 7%, 0.55) 47.1%,
    hsla(0, 0%, 7%, 0.45) 52.9%,
    hsla(0, 0%, 7%, 0.352) 58.8%,
    hsla(0, 0%, 7%, 0.259) 64.7%,
    hsla(0, 0%, 7%, 0.175) 71%,
    hsla(0, 0%, 7%, 0.104) 77.5%,
    hsla(0, 0%, 7%, 0.049) 84.5%,
    hsla(0, 0%, 7%, 0.013) 91.9%,
    hsla(0, 0%, 7%, 0) 100%
  );
}

@media screen and (min-width: 320px) {
  :root {
    --space-between-xxsmall: calc(8px + 8 * var(--proportional-width-for-responsive));
    --space-between-xsmall: calc(16px + 8 * var(--proportional-width-for-responsive));
    --space-between-small: calc(16px + 16 * var(--proportional-width-for-responsive));
    --space-between-medium: calc(32px + 8 * var(--proportional-width-for-responsive));
    --space-between-large: calc(40px + 24 * var(--proportional-width-for-responsive));
    --space-between-xxlarge: calc(80px + 80 * var(--proportional-width-for-responsive));
    --space-between-xxxlarge: calc(160px + 40 * var(--proportional-width-for-responsive));
    --space-between-xxxxlarge: calc(225px + 115 * var(--proportional-width-for-responsive));
  }
}

@media screen and (min-width: 768px) {
  :root {
    --grid-row-gutter: 48px;

    --grid-row-width: calc(736px + 316 * calc((100vw - 768px) / (1440 - 768)));
    --grid-full-row-margin: calc(0px + 84 * calc((100vw - 768px) / (1440 - 768)));
  }
}

@media screen and (min-width: 1440px) {
  :root {
    --grid-row-width: calc(992px + 160 * calc((100vw - 1440px) / (1920 - 1440)));
    --grid-full-row-margin: calc(42px + 30 * calc((100vw - 1440px) / (1920 - 1440)));
  }
}

@media screen and (min-width: 1920px) {
  :root {
    --space-between-xxsmall: 16px;
    --space-between-xsmall: 24px;
    --space-between-small: 32px;
    --space-between-medium: 40px;
    --space-between-large: 64px;
    --space-between-xxlarge: 160px;
    --space-between-xxxlarge: 200px;
    --space-between-xxxxlarge: 340px;

    --dimension-header-height: 128px;

    --grid-row-width: calc(1152px + 384 * calc((100vw - 1920px) / (2560 - 1920)));
    --grid-full-row-margin: calc(72px + 24 * calc((100vw - 1920px) / (2560 - 1920)));
  }
}

@media screen and (min-width: 2560px) {
  :root {
    --grid-row-width: 1536px;
    --grid-full-row-margin: 96px;
  }
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body,
.body {
  background-color: #131313;
  background-color: var(--color-black);
  color: #fff;
  color: var(--color-white);
  font-family: 'Inter', sans-serif;
  font-family: var(--font-family-default);
  min-height: 100vh;

  /* overflow-x: hidden; */
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  transition: var(--transition-background-color), var(--transition-color);
}

.body--inverted {
  --color-white: #131313;
  --color-black: #fff;
  --color-error: #fff;

  --gradient-to-bottom: linear-gradient(
    to bottom,
    hsl(0, 0%, 100%) 0%,
    hsla(0, 0%, 100%, 0.987) 8.1%,
    hsla(0, 0%, 100%, 0.951) 15.5%,
    hsla(0, 0%, 100%, 0.896) 22.5%,
    hsla(0, 0%, 100%, 0.825) 29%,
    hsla(0, 0%, 100%, 0.741) 35.3%,
    hsla(0, 0%, 100%, 0.648) 41.2%,
    hsla(0, 0%, 100%, 0.55) 47.1%,
    hsla(0, 0%, 100%, 0.45) 52.9%,
    hsla(0, 0%, 100%, 0.352) 58.8%,
    hsla(0, 0%, 100%, 0.259) 64.7%,
    hsla(0, 0%, 100%, 0.175) 71%,
    hsla(0, 0%, 100%, 0.104) 77.5%,
    hsla(0, 0%, 100%, 0.049) 84.5%,
    hsla(0, 0%, 100%, 0.013) 91.9%,
    hsla(0, 0%, 100%, 0) 100%
  );

  --gradient-to-left: linear-gradient(
    to left,
    hsl(0, 0%, 100%) 0%,
    hsla(0, 0%, 100%, 0.987) 8.1%,
    hsla(0, 0%, 100%, 0.951) 15.5%,
    hsla(0, 0%, 100%, 0.896) 22.5%,
    hsla(0, 0%, 100%, 0.825) 29%,
    hsla(0, 0%, 100%, 0.741) 35.3%,
    hsla(0, 0%, 100%, 0.648) 41.2%,
    hsla(0, 0%, 100%, 0.55) 47.1%,
    hsla(0, 0%, 100%, 0.45) 52.9%,
    hsla(0, 0%, 100%, 0.352) 58.8%,
    hsla(0, 0%, 100%, 0.259) 64.7%,
    hsla(0, 0%, 100%, 0.175) 71%,
    hsla(0, 0%, 100%, 0.104) 77.5%,
    hsla(0, 0%, 100%, 0.049) 84.5%,
    hsla(0, 0%, 100%, 0.013) 91.9%,
    hsla(0, 0%, 100%, 0) 100%
  );

  --gradient-to-top: linear-gradient(
    to top,
    hsl(0, 0%, 100%) 0%,
    hsla(0, 0%, 100%, 0.987) 8.1%,
    hsla(0, 0%, 100%, 0.951) 15.5%,
    hsla(0, 0%, 100%, 0.896) 22.5%,
    hsla(0, 0%, 100%, 0.825) 29%,
    hsla(0, 0%, 100%, 0.741) 35.3%,
    hsla(0, 0%, 100%, 0.648) 41.2%,
    hsla(0, 0%, 100%, 0.55) 47.1%,
    hsla(0, 0%, 100%, 0.45) 52.9%,
    hsla(0, 0%, 100%, 0.352) 58.8%,
    hsla(0, 0%, 100%, 0.259) 64.7%,
    hsla(0, 0%, 100%, 0.175) 71%,
    hsla(0, 0%, 100%, 0.104) 77.5%,
    hsla(0, 0%, 100%, 0.049) 84.5%,
    hsla(0, 0%, 100%, 0.013) 91.9%,
    hsla(0, 0%, 100%, 0) 100%
  );
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Inter-Regular.52d3d95b.woff2) format('woff2'),
    url(/static/media/Inter-Regular.d1d3f24b.woff) format('woff'),
    url(/static/media/Inter-Regular.5f34e0d2.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/Inter-Bold.caf24a65.woff2) format('woff2'),
    url(/static/media/Inter-Bold.21a0f4cd.woff) format('woff'),
    url(/static/media/Inter-Bold.f5493099.ttf) format('truetype');
}

/* CSS Tokens */
:root {
  /* Cookiebot Banner */

  --cookie-banner-logo: url('/images/logo.png') no-repeat;
  --cookie-banner-logo-aspect-ratio: 1/1;
  --cookie-banner-logo-width: 114px;
  --show-logo-in-popup: none;
  --cookie-banner-background: var(--color-white);
  --cookie-banner-popup-background: rgba(255, 255, 255, 1);
  --cookie-banner-typography-title: 600 24px/29px var(--font-family-default), sans-serif;
  --cookie-banner-title-color: var(--color-black);
  --cookie-banner-text-color: var(--color-black);
  --cookie-banner-link-color: var(--color-black);
  --cookie-banner-typography-text: 16px/20px var(--font-family-default), sans-serif;
  --cookie-banner-button-case: initial;
  --cookie-banner-case-title: initial;

  /* Banner Button Main */
  --cookie-banner-button-case: initial;
  --cookie-banner-button-text: 600 16px/20px var(--font-family-default), sans-serif;
  --cookie-banner-button-border: 1px solid var(--color-black);
  --cookie-banner-button-background: var(--color-black);
  --cookie-banner-button-text-color: var(--color-white);
  --cookie-banner-button-border-radius: 0;
  --cookie-banner-button-padding: 18px 16px;
  --cookie-banner-button-width: 240px;

  /* Banner Button Customize Level */
  --cookie-banner-button-body-text: 600 16px/20px var(--font-family-default), sans-serif;
  --cookie-banner-button-body-border: 1px solid var(--color-black);
  --cookie-banner-button-body-background: transparent;
  --cookie-banner-button-body-text-color: var(--color-black);
  --cookie-banner-button-body-border-radius: 0;
  --cookie-banner-button-body-padding: 18px 0;

  /* Cookiebot Popup */
  --cookie-popup-typography-text: 16px/24px var(--font-family-default), sans-serif;
  --cookie-popup-text-color: var(--color-black);
  --cookie-popup-link-color: var(--color-black);
  --cookie-banner-tabs-color: var(--color-black);
  --cookie-popup-button-text: 600 16px/20px var(--font-family-default), sans-serif;
  --cookie-popup-button-background: var(--color-black);
  --cookie-popup-button-text-color: var(--color-white);
  --cookie-popup-button-body-text: 600 16px/20px var(--font-family-default), sans-serif;
  --cookie-popup-button-border: 1px solid var(--color-black);
  --cookie-popup-button-body-border: 1px solid var(--color-black);
  --cookie-popup-button-body-border-radius: 0;
  --cookie-popup-button-body-text-color: var(--color-black);
  --cookie-popup-button-body-padding: 18px 16px;
  --cookie-popup-button-body-background: transparent;
  --cookie-banner-container: 1142px;
  --cookie-banner-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.2);
  --cookie-banner-height: 35%;
}

/* Styles */

body #CybotCookiebotDialogPoweredbyImage {
  display: none;
}

body #CybotCookiebotDialogHeader {
  display: none;
}

body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogPoweredbyLink {
  display: none;
  display: var(--show-logo-in-popup);
  margin-top: var(--spacer-02);
}

body #CybotCookiebotDialogBodyContentTitle {
  color: var(--color-black);
  color: var(--cookie-banner-title-color);
  font: 600 24px/29px var(--font-family-default), sans-serif;
  font: var(--cookie-banner-typography-title);
  letter-spacing: -0.8px;
  margin: 16px 0 16px;
  text-transform: initial;
  text-transform: var(--cookie-banner-case-title);
}

body #CybotCookiebotDialogBodyContentText {
  color: var(--color-black);
  color: var(--cookie-banner-text-color);
  font: 16px/20px var(--font-family-default), sans-serif;
  font: var(--cookie-banner-typography-text);
  letter-spacing: normal;
}

body #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a {
  color: var(--color-black);
  color: var(--cookie-banner-link-color);
  text-decoration: underline;
  transition: all 0.2s ease-in-out;
}

body #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a:hover {
  opacity: 0.75;
}

body #CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper {
  max-width: 1142px;
  max-width: var(--cookie-banner-container);
  align-items: center;
  justify-items: center;
  place-items: center;
}

body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogBodyContentTitle {
  color: var(--color-black);
  color: var(--cookie-popup-text-color);
}

body #CybotCookiebotDialog[data-template="bottom"] a,
body #CybotCookiebotDialog[data-template="bottom"] div,
body #CybotCookiebotDialogBodyContentControls[data-template="bottom"],
body #CybotCookiebotDialog[data-template="slideup"] a,
body #CybotCookiebotDialog[data-template="slideup"] div,
body #CybotCookiebotDialogBodyContentControls[data-template="slideup"] {
  color: var(--color-black);
  color: var(--cookie-banner-text-color);
  font: 16px/20px var(--font-family-default), sans-serif;
  font: var(--cookie-banner-typography-text);
}

body #CybotCookiebotDialog[data-template="popup"] div,
body #CybotCookiebotDialogBodyContentControls[data-template="popup"] {
  color: var(--color-black);
  color: var(--cookie-popup-text-color);
  font: 16px/24px var(--font-family-default), sans-serif;
  font: var(--cookie-popup-typography-text);
}

body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogBodyContentText a {
  color: var(--color-black);
  color: var(--cookie-popup-link-color);
  font: 16px/24px var(--font-family-default), sans-serif;
  font: var(--cookie-popup-typography-text);
  font-weight: 600;
}

body #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-bottom: 1px solid var(--color-black);
  border-bottom: 1px solid var(--cookie-banner-tabs-color);
  color: var(--color-black);
  color: var(--cookie-banner-tabs-color);
}

body #CybotCookiebotDialog[data-template="bottom"],
body #CybotCookiebotDialog[data-template="slideup"] {
  background: var(--color-white);
  background: var(--cookie-banner-background);
  border-radius: 0;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: var(--cookie-banner-shadow);
  height: 35%;
  height: var(--cookie-banner-height);
  align-items: center;
  justify-items: center;
  place-items: center;
}

body #CybotCookiebotDialog[data-template="popup"] {
  background: rgba(255, 255, 255, 1);
  background: var(--cookie-banner-popup-background);
}

body #CybotCookiebotDialogPoweredbyCybot,
body #CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogBodyBottomWrapper {
  display: none;
}

body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background-color: var(--color-black);
  background-color: var(--cookie-banner-button-background);
  border: 1px solid var(--color-black);
  border: var(--cookie-banner-button-border);
  border-radius: 0;
  border-radius: var(--cookie-banner-button-border-radius);
  color: var(--color-white);
  color: var(--cookie-banner-button-text-color);
  font: 600 16px/20px var(--font-family-default), sans-serif;
  font: var(--cookie-banner-button-text);
  padding: 18px 16px;
  padding: var(--cookie-banner-button-padding);
  text-transform: initial;
  text-transform: var(--cookie-banner-button-case);
  transition: all 0.25s ease-in-out;
}

body #CybotCookiebotDialog[data-template="bottom"] #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
body #CybotCookiebotDialog[data-template="bottom"] #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
body #CybotCookiebotDialog[data-template="slideup"] #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
body #CybotCookiebotDialog[data-template="slideup"] #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background-color: var(--color-black);
  background-color: var(--cookie-banner-button-background);
  color: var(--color-white);
  color: var(--cookie-banner-button-text-color);
  text-transform: initial;
  text-transform: var(--cookie-banner-button-case);
}

body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogBodyButtonAccept,
body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background-color: var(--color-black);
  background-color: var(--cookie-popup-button-background);
  color: var(--color-white);
  color: var(--cookie-popup-button-text-color);
  text-transform: initial;
  text-transform: var(--cookie-banner-button-case);
}

body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogBodyLevelButtonCustomize,
body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonDecline {
  background-color: transparent;
  background-color: var(--cookie-popup-button-body-background);
  border: 1px solid var(--color-black);
  border: var(--cookie-popup-button-body-border);
  color: var(--color-black);
  color: var(--cookie-popup-button-body-text-color);
  text-transform: initial;
  text-transform: var(--cookie-banner-button-case);
}

body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
body #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  border: 1px solid var(--color-black);
  border: var(--cookie-banner-button-body-border);
  border-radius: 0;
  border-radius: var(--cookie-banner-button-body-border-radius);
  color: var(--color-black);
  color: var(--cookie-banner-button-body-text-color);
  font: 600 16px/20px var(--font-family-default), sans-serif;
  font: var(--cookie-banner-button-body-text);
  padding: 18px 0;
  padding: var(--cookie-banner-button-body-padding);
  text-transform: initial;
  text-transform: var(--cookie-banner-case-title);
  width: 240px;
  width: var(--cookie-banner-button-width);
}

body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
body #CybotCookiebotDialog[data-template="popup"] #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  border: 1px solid var(--color-black);
  border: var(--cookie-popup-button-body-border);
  border-radius: 0;
  border-radius: var(--cookie-popup-button-body-border-radius);
  color: var(--color-black);
  color: var(--cookie-banner-button-body-text-color);
  font: 600 16px/20px var(--font-family-default), sans-serif;
  font: var(--cookie-popup-button-body-text);
  padding: 18px 16px;
  padding: var(--cookie-popup-button-body-padding);
  text-transform: initial;
  text-transform: var(--cookie-banner-button-case);
}

body #CybotCookiebotDialog .CybotCookiebotScrollContainer,
body #CybotCookiebotDialogHeader {
  border-bottom: none;
}

body #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:not(:first-of-type) {
  margin-bottom: 0;
  margin-right: 0.5rem;
  width: 240px;
  width: var(--cookie-banner-button-width);
}

body #CybotCookiebotDialogBodyContent {
  padding-top: 0;
}

body #CybotCookiebotDialogNav a.CybotCookiebotDialogNavItemLink {
  font-weight: bold;
}

body #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.active {
  border-bottom: 1px solid var(--color-black);
  border-bottom: 1px solid var(--cookie-banner-tabs-color);
  color: var(--color-black);
  color: var(--cookie-banner-tabs-color);
}

body #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
  color: var(--color-black);
  color: var(--cookie-banner-tabs-color);
}

body #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover {
  color: var(--color-black);
  color: var(--cookie-banner-tabs-color);
}

body #CybotCookiebotDialogTabContent input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: var(--color-black);
  background-color: var(--cookie-banner-tabs-color);
}

body .CybotCookiebotDialogArrow {
  display: none;
}

body #CybotCookiebotDialogPoweredByText {
  display: none;
}

body #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  margin-top: var(--space-between-xxsmall);
  max-width: none;
  width: 240px;
  width: var(--cookie-banner-button-width);
}

@media screen and (max-width: 1280px) {
  body #CybotCookiebotDialog[data-template="bottom"],
  body #CybotCookiebotDialog[data-template="slideup"] {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    max-width: 100%;
    min-height: 35%;
    min-height: var(--cookie-banner-height);
    position: fixed;
    right: 0;
    top: auto;
    -webkit-transform: none;
            transform: none;
    width: 100%;
  }

  body #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyButtons,
  body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper {
    justify-content: flex-start;
  }

  body #CybotCookiebotDialogBodyLevelButtonCustomize {
    order: 1;
  }

}

@media screen and (max-width: 600px) {
  body #CybotCookiebotDialogHeader {
    justify-content: center;
  }

  body #CybotCookiebotDialogHeader > div {
    justify-content: center;
    text-align: center;
  }

  body #CybotCookiebotDialogBodyLevelButtonCustomize {
    order: initial;
  }

  body #CybotCookiebotDialogBodyButtonsWrapper {
    --cookie-banner-button-width: 100%;
  }
}

