.resource__label {
  text-transform: uppercase;
}

.resource__text {
  padding-bottom: var(--space-between-xxlarge);
  padding-top: var(--space-between-medium);
}

.resource__generic-items {
  margin-top: var(--space-between-xxlarge);
}

.resource__backlink {
  margin-bottom: var(--space-between-xxxlarge);
  padding-bottom: var(--space-between-medium);
  padding-top: var(--space-between-medium);
  position: relative;
}

.resource__backlink::after {
  background: var(--color-white);
  bottom: 0;
  content: '';
  height: 1px;
  left: var(--grid-row-half-gutter);
  position: absolute;
  width: calc(100% - var(--grid-row-gutter));
}

.resource__backlink::before {
  background: var(--color-white);
  content: '';
  height: 1px;
  left: var(--grid-row-half-gutter);
  position: absolute;
  top: 0;
  width: calc(100% - var(--grid-row-gutter));
}

.resource__form-title {
  padding-bottom: var(--space-between-medium);
  padding-top: var(--space-between-xlarge);
  position: relative;
}

.resource__form-title::before {
  background: var(--color-white);
  content: '';
  height: 1px;
  left: var(--grid-row-half-gutter);
  position: absolute;
  top: 0;
  width: calc(100% - var(--grid-row-gutter));
}

.resource__form {
  margin-bottom: var(--space-between-xxxlarge);
  padding-top: var(--space-between-xlarge);
}

@media screen and (min-width: 1440px) {
  .resource__backlink .content-with-sidebar__left:not(:empty) {
    margin-bottom: 0;
  }

  .resource__backlink .content-with-sidebar__left,
  .resource__backlink .content-with-sidebar__right,
  .resource__backlink .content-with-sidebar__content {
    flex: auto;
    width: 100%;
  }
}