.typography {
  font-family: var(--font-family-default);
  font-style: normal;
  font-weight: var(--font-weight-regular);
}

.typography--h1,
.typography--h2,
.typography--h3,
.typography--h4,
.typography--h5,
.typography--h6,
.typography--big-body,
.typography--big-h1 {
  font-weight: var(--font-weight-bold);
}

.typography--h1 {
  font-size: var(--font-size-72);
  letter-spacing: var(--letter-spacing-for-72);
  line-height: var(--line-height-for-72);
}

.typography--big-h1 {
  font-size: var(--font-size-40);
  letter-spacing: var(--letter-spacing-for-40);
  line-height: var(--line-height-for-40);
}

.typography--h2 {
  font-size: var(--font-size-28);
  letter-spacing: var(--letter-spacing-for-28);
  line-height: var(--line-height-for-28);
}

.typography--h3,
.typography--h3-regular,
.typography--h4,
.typography--h5,
.typography--h6 {
  font-size: var(--font-size-24);
  letter-spacing: var(--letter-spacing-for-24);
  line-height: var(--line-height-for-24);
}

.typography--big-body {
  font-size: var(--font-size-20);
  letter-spacing: var(--letter-spacing-for-20);
  line-height: var(--line-height-for-20);
}

.typography--body {
  font-size: var(--font-size-18);
  letter-spacing: var(--letter-spacing-for-18);
  line-height: var(--line-height-for-18);
}

.typography--small-body {
  font-size: var(--font-size-16);
  letter-spacing: var(--letter-spacing-for-16);
  line-height: var(--line-height-for-16);
}

@media screen and (min-width: 320px) {
  .typography--h1 {
    font-size: calc(var(--font-size-40) + 56 * var(--proportional-width-for-responsive));
    letter-spacing: calc(var(--letter-spacing-for-40) - 2.4 * var(--proportional-width-for-responsive));
    line-height: calc(var(--line-height-for-40) + 63 * var(--proportional-width-for-responsive));
  }

  .typography--h2 {
    font-size: calc(var(--font-size-28) + 28 * var(--proportional-width-for-responsive));
    letter-spacing: calc(var(--letter-spacing-for-28) - 0.75 * var(--proportional-width-for-responsive));
    line-height: calc(var(--line-height-for-28) + 36 * var(--proportional-width-for-responsive));
  }

  .typography--h3,
  .typography--h3-regular,
  .typography--h4,
  .typography--h5,
  .typography--h6 {
    font-size: calc(var(--font-size-24) + 16 * var(--proportional-width-for-responsive));
    letter-spacing: calc(var(--letter-spacing-for-24) - 0.4 * var(--proportional-width-for-responsive));
    line-height: calc(var(--line-height-for-24) + 20 * var(--proportional-width-for-responsive));
  }

  .typography--big-body {
    font-size: calc(var(--font-size-20) + 4 * var(--proportional-width-for-responsive));
    letter-spacing: var(--letter-spacing-for-20);
    line-height: calc(var(--line-height-for-20) + 5 * var(--proportional-width-for-responsive));
  }

  .typography--body {
    font-size: calc(var(--font-size-18) + 2 * var(--proportional-width-for-responsive));
    letter-spacing: var(--letter-spacing-for-18);
    line-height: var(--line-height-for-18);
  }

  .typography--big-h1 {
    font-size: calc(var(--font-size-72) + 56 * var(--proportional-width-for-responsive));
    letter-spacing: calc(var(--letter-spacing-for-72) - 2.4 * var(--proportional-width-for-responsive));
    line-height: calc(var(--line-height-for-72) + 63 * var(--proportional-width-for-responsive));
  }
}

@media screen and (min-width: 1920px) {
  .typography--h1 {
    font-size: var(--font-size-96);
    letter-spacing: var(--letter-spacing-for-96);
    line-height: var(--line-height-for-96);
  }

  .typography--big-h1 {
    font-size: var(--font-size-136);
    letter-spacing: var(--letter-spacing-for-136);
    line-height: var(--line-height-for-136);
  }

  .typography--h2 {
    font-size: var(--font-size-56);
    letter-spacing: var(--letter-spacing-for-56);
    line-height: var(--line-height-for-56);
  }

  .typography--h3,
  .typography--h3-regular,
  .typography--h4,
  .typography--h5,
  .typography--h6 {
    font-size: var(--font-size-40);
    letter-spacing: var(--letter-spacing-for-40);
    line-height: var(--line-height-for-40);
  }

  .typography--big-body {
    font-size: var(--font-size-24);
    letter-spacing: var(--letter-spacing-for-24);
    line-height: var(--line-height-for-24);
  }

  .typography--body {
    font-size: var(--font-size-20);
    letter-spacing: var(--letter-spacing-for-20);
    line-height: var(--line-height-for-20);
  }
}
