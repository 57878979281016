.custom-content .column {
  width: 100%;
}

.custom-content__title {
  margin-bottom: var(--space-between-xlarge);
  margin-top: var(--space-between-xlarge);
}

.custom-content__card-title {
  margin-bottom: var(--space-between-xxxsmall);
  margin-top: var(--space-between-xxsmall);
}

.custom-content__grid {
  display: flex;
  flex-flow: row wrap;
}

.custom-content__grid-item {
  margin-bottom: var(--space-between-xlarge);
}

.custom-content__card-content {
  line-height: 32px;
}

.custom-content--inverted .locations {
  background-color: var(--color-white);
  color: var(--color-black);
}

.body--inverted .contact-form .hs-button {
  background-color: var(--color-white);
  border: var(--dimension-border) solid var(--color-white);
  color: var(--color-black);
}

.body--inverted .contact-form .hs-button:hover {
  background-color: var(--color-black);
  color: var(--color-white);
}

.body--inverted input:hover,
.body--inverted input:focus,
.body--inverted input::selection,
.body--inverted input:-webkit-autofill {
  background: none;
  transition: background 0.6s ease-in-out 0s;
}