@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

:root {
  --color-white: #fff;
  --color-black: #131313;
  --color-pomegranate: #f44336;
  --color-alto: #d8d8d8;
  --color-dusty-gray: #979797;
  --color-mine-shaft: #333;
  --color-error: #f44336;
  --color-red: #f44336;
  --color-blue: #04caf5;

  --font-family-default: 'Inter', sans-serif;

  --font-weight-regular: 400;
  --font-weight-bold: 700;

  --font-size-136: 136px;
  --font-size-96: 96px;
  --font-size-72: 72px;
  --font-size-56: 56px;
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-28: 28px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;

  --line-height-for-136: 142px;
  --line-height-for-96: 112px;
  --line-height-for-72: 84px;
  --line-height-for-56: 72px;
  --line-height-for-40: 49px;
  --line-height-for-32: 38px;
  --line-height-for-28: 36px;
  --line-height-for-26: 32px;
  --line-height-for-24: 29px;
  --line-height-for-20: 24px;
  --line-height-for-18: 24px;
  --line-height-for-16: 20px;
  --line-height-for-14: 16px;

  --letter-spacing-for-136-2: -8px;
  --letter-spacing-for-136: -5.67px;
  --letter-spacing-for-96: -4px;
  --letter-spacing-for-72: -3.2px;
  --letter-spacing-for-56: -2.15px;
  --letter-spacing-for-40: -1.6px;
  --letter-spacing-for-32: -1.4px;
  --letter-spacing-for-28: -1.4px;
  --letter-spacing-for-24: -1.2px;
  --letter-spacing-for-20-2: -0.8px;
  --letter-spacing-for-20: -1.2px;
  --letter-spacing-for-18: -1.2px;
  --letter-spacing-for-16: -0.96px;
  --letter-spacing-for-14: -0.6px;

  --grid-row-gutter: 32px;
  --grid-row-half-gutter: calc(var(--grid-row-gutter) / 2);
  --grid-row-quarter-gutter: calc(var(--grid-row-gutter) / 4);

  --grid-row-width: 100%;
  --grid-full-row-margin: 0;

  --space-between-xxxxsmall: 4px;
  --space-between-xxxsmall: 8px;
  --space-between-xxsmall: 8px;
  --space-between-xsmall: 16px;
  --space-between-small: 16px;
  --space-between-medium: 32px;
  --space-between-large: 40px;
  --space-between-xlarge: 64px;
  --space-between-xxlarge: 80px;
  --space-between-xxxlarge: 160px;
  --space-between-xxxxlarge: 225px;

  --aspect-ratio-9-16: 16 / 9;
  --aspect-ratio-3-4: 4 / 3;
  --aspect-ratio-16-9: 9 / 16;
  --aspect-ratio-4-3: 3 / 4;
  --aspect-ratio-3-2: 2 / 3;

  --dimension-border: 1px;
  --dimension-header-height: 80px;
  --dimension-button: 64px;

  --dimension-logo-height: 32px;
  --dimension-logo-width: 131px;
  --dimension-list-image-content-image: 84px;

  --transition-timing-none: 0s;
  --transition-timing-default: 0.3s;
  --transition-timing-fast: 0.2s;
  --transition-timing-slow: 0.5s;
  --transition-timing-slow-2: 0.6s;
  --transition-ease-default: ease-in-out;
  --transition-all: all var(--transition-timing-fast) var(--transition-ease-default);
  --transition-background-color: background-color var(--transition-timing-default) var(--transition-ease-default);
  --transition-color: color var(--transition-timing-default) var(--transition-ease-default);
  --transition-fill: fill var(--transition-timing-default) var(--transition-ease-default);
  --transition-transform: transform var(--transition-timing-default) var(--transition-ease-default);
  --transition-transform-fast: transform var(--transition-timing-fast) var(--transition-ease-default);
  --transition-height: height var(--transition-timing-fast) var(--transition-ease-default);
  --transition-opacity: opacity var(--transition-timing-default) var(--transition-ease-default);
  --transition-opacity-button: var(--transition-timing-slow-2) var(--transition-ease-default)
    var(--transition-timing-none);

  --iconography-size-default: 24px;
  --iconography-size-big: 32px;

  --z-index-menu: 30;
  --z-index-slider-gradient: 40;
  --z-index-slider-fullscreen: 50;
  --z-index-cookie-banner: 100;

  --proportional-width-for-responsive: calc((100vw - 320px) / (1920 - 320));

  --gradient-to-bottom: linear-gradient(
    to bottom,
    hsl(0, 0%, 7%) 0%,
    hsla(0, 0%, 7%, 0.987) 8.1%,
    hsla(0, 0%, 7%, 0.951) 15.5%,
    hsla(0, 0%, 7%, 0.896) 22.5%,
    hsla(0, 0%, 7%, 0.825) 29%,
    hsla(0, 0%, 7%, 0.741) 35.3%,
    hsla(0, 0%, 7%, 0.648) 41.2%,
    hsla(0, 0%, 7%, 0.55) 47.1%,
    hsla(0, 0%, 7%, 0.45) 52.9%,
    hsla(0, 0%, 7%, 0.352) 58.8%,
    hsla(0, 0%, 7%, 0.259) 64.7%,
    hsla(0, 0%, 7%, 0.175) 71%,
    hsla(0, 0%, 7%, 0.104) 77.5%,
    hsla(0, 0%, 7%, 0.049) 84.5%,
    hsla(0, 0%, 7%, 0.013) 91.9%,
    hsla(0, 0%, 7%, 0) 100%
  );
  --gradient-to-left: linear-gradient(
    to left,
    hsl(0, 0%, 7%) 0%,
    hsla(0, 0%, 7%, 0.987) 8.1%,
    hsla(0, 0%, 7%, 0.951) 15.5%,
    hsla(0, 0%, 7%, 0.896) 22.5%,
    hsla(0, 0%, 7%, 0.825) 29%,
    hsla(0, 0%, 7%, 0.741) 35.3%,
    hsla(0, 0%, 7%, 0.648) 41.2%,
    hsla(0, 0%, 7%, 0.55) 47.1%,
    hsla(0, 0%, 7%, 0.45) 52.9%,
    hsla(0, 0%, 7%, 0.352) 58.8%,
    hsla(0, 0%, 7%, 0.259) 64.7%,
    hsla(0, 0%, 7%, 0.175) 71%,
    hsla(0, 0%, 7%, 0.104) 77.5%,
    hsla(0, 0%, 7%, 0.049) 84.5%,
    hsla(0, 0%, 7%, 0.013) 91.9%,
    hsla(0, 0%, 7%, 0) 100%
  );
  --gradient-to-top: linear-gradient(
    to top,
    hsl(0, 0%, 7%) 0%,
    hsla(0, 0%, 7%, 0.987) 8.1%,
    hsla(0, 0%, 7%, 0.951) 15.5%,
    hsla(0, 0%, 7%, 0.896) 22.5%,
    hsla(0, 0%, 7%, 0.825) 29%,
    hsla(0, 0%, 7%, 0.741) 35.3%,
    hsla(0, 0%, 7%, 0.648) 41.2%,
    hsla(0, 0%, 7%, 0.55) 47.1%,
    hsla(0, 0%, 7%, 0.45) 52.9%,
    hsla(0, 0%, 7%, 0.352) 58.8%,
    hsla(0, 0%, 7%, 0.259) 64.7%,
    hsla(0, 0%, 7%, 0.175) 71%,
    hsla(0, 0%, 7%, 0.104) 77.5%,
    hsla(0, 0%, 7%, 0.049) 84.5%,
    hsla(0, 0%, 7%, 0.013) 91.9%,
    hsla(0, 0%, 7%, 0) 100%
  );
}

@media screen and (min-width: 320px) {
  :root {
    --space-between-xxsmall: calc(8px + 8 * var(--proportional-width-for-responsive));
    --space-between-xsmall: calc(16px + 8 * var(--proportional-width-for-responsive));
    --space-between-small: calc(16px + 16 * var(--proportional-width-for-responsive));
    --space-between-medium: calc(32px + 8 * var(--proportional-width-for-responsive));
    --space-between-large: calc(40px + 24 * var(--proportional-width-for-responsive));
    --space-between-xxlarge: calc(80px + 80 * var(--proportional-width-for-responsive));
    --space-between-xxxlarge: calc(160px + 40 * var(--proportional-width-for-responsive));
    --space-between-xxxxlarge: calc(225px + 115 * var(--proportional-width-for-responsive));
  }
}

@media screen and (min-width: 768px) {
  :root {
    --grid-row-gutter: 48px;

    --grid-row-width: calc(736px + 316 * calc((100vw - 768px) / (1440 - 768)));
    --grid-full-row-margin: calc(0px + 84 * calc((100vw - 768px) / (1440 - 768)));
  }
}

@media screen and (min-width: 1440px) {
  :root {
    --grid-row-width: calc(992px + 160 * calc((100vw - 1440px) / (1920 - 1440)));
    --grid-full-row-margin: calc(42px + 30 * calc((100vw - 1440px) / (1920 - 1440)));
  }
}

@media screen and (min-width: 1920px) {
  :root {
    --space-between-xxsmall: 16px;
    --space-between-xsmall: 24px;
    --space-between-small: 32px;
    --space-between-medium: 40px;
    --space-between-large: 64px;
    --space-between-xxlarge: 160px;
    --space-between-xxxlarge: 200px;
    --space-between-xxxxlarge: 340px;

    --dimension-header-height: 128px;

    --grid-row-width: calc(1152px + 384 * calc((100vw - 1920px) / (2560 - 1920)));
    --grid-full-row-margin: calc(72px + 24 * calc((100vw - 1920px) / (2560 - 1920)));
  }
}

@media screen and (min-width: 2560px) {
  :root {
    --grid-row-width: 1536px;
    --grid-full-row-margin: 96px;
  }
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body,
.body {
  background-color: #131313;
  background-color: var(--color-black);
  color: #fff;
  color: var(--color-white);
  font-family: 'Inter', sans-serif;
  font-family: var(--font-family-default);
  min-height: 100vh;

  /* overflow-x: hidden; */
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  transition: var(--transition-background-color), var(--transition-color);
}

.body--inverted {
  --color-white: #131313;
  --color-black: #fff;
  --color-error: #fff;

  --gradient-to-bottom: linear-gradient(
    to bottom,
    hsl(0, 0%, 100%) 0%,
    hsla(0, 0%, 100%, 0.987) 8.1%,
    hsla(0, 0%, 100%, 0.951) 15.5%,
    hsla(0, 0%, 100%, 0.896) 22.5%,
    hsla(0, 0%, 100%, 0.825) 29%,
    hsla(0, 0%, 100%, 0.741) 35.3%,
    hsla(0, 0%, 100%, 0.648) 41.2%,
    hsla(0, 0%, 100%, 0.55) 47.1%,
    hsla(0, 0%, 100%, 0.45) 52.9%,
    hsla(0, 0%, 100%, 0.352) 58.8%,
    hsla(0, 0%, 100%, 0.259) 64.7%,
    hsla(0, 0%, 100%, 0.175) 71%,
    hsla(0, 0%, 100%, 0.104) 77.5%,
    hsla(0, 0%, 100%, 0.049) 84.5%,
    hsla(0, 0%, 100%, 0.013) 91.9%,
    hsla(0, 0%, 100%, 0) 100%
  );

  --gradient-to-left: linear-gradient(
    to left,
    hsl(0, 0%, 100%) 0%,
    hsla(0, 0%, 100%, 0.987) 8.1%,
    hsla(0, 0%, 100%, 0.951) 15.5%,
    hsla(0, 0%, 100%, 0.896) 22.5%,
    hsla(0, 0%, 100%, 0.825) 29%,
    hsla(0, 0%, 100%, 0.741) 35.3%,
    hsla(0, 0%, 100%, 0.648) 41.2%,
    hsla(0, 0%, 100%, 0.55) 47.1%,
    hsla(0, 0%, 100%, 0.45) 52.9%,
    hsla(0, 0%, 100%, 0.352) 58.8%,
    hsla(0, 0%, 100%, 0.259) 64.7%,
    hsla(0, 0%, 100%, 0.175) 71%,
    hsla(0, 0%, 100%, 0.104) 77.5%,
    hsla(0, 0%, 100%, 0.049) 84.5%,
    hsla(0, 0%, 100%, 0.013) 91.9%,
    hsla(0, 0%, 100%, 0) 100%
  );

  --gradient-to-top: linear-gradient(
    to top,
    hsl(0, 0%, 100%) 0%,
    hsla(0, 0%, 100%, 0.987) 8.1%,
    hsla(0, 0%, 100%, 0.951) 15.5%,
    hsla(0, 0%, 100%, 0.896) 22.5%,
    hsla(0, 0%, 100%, 0.825) 29%,
    hsla(0, 0%, 100%, 0.741) 35.3%,
    hsla(0, 0%, 100%, 0.648) 41.2%,
    hsla(0, 0%, 100%, 0.55) 47.1%,
    hsla(0, 0%, 100%, 0.45) 52.9%,
    hsla(0, 0%, 100%, 0.352) 58.8%,
    hsla(0, 0%, 100%, 0.259) 64.7%,
    hsla(0, 0%, 100%, 0.175) 71%,
    hsla(0, 0%, 100%, 0.104) 77.5%,
    hsla(0, 0%, 100%, 0.049) 84.5%,
    hsla(0, 0%, 100%, 0.013) 91.9%,
    hsla(0, 0%, 100%, 0) 100%
  );
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('./../../fonts/Inter-woff/Inter-Regular.woff2') format('woff2'),
    url('./../../fonts/Inter-woff/Inter-Regular.woff') format('woff'),
    url('./../../fonts/Inter-ttf/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('./../../fonts/Inter-woff/Inter-Bold.woff2') format('woff2'),
    url('./../../fonts/Inter-woff/Inter-Bold.woff') format('woff'),
    url('./../../fonts/Inter-ttf/Inter-Bold.ttf') format('truetype');
}
