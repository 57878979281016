.contact-form .hs-form-field:not(.hs-fieldtype-booleancheckbox) {
  padding-bottom: var(--space-between-small);
}

.contact-form > .inline-button {
  margin-bottom: var(--space-between-small);
  margin-top: var(--space-between-small);
}

.contact-form .hs-form-field input:not([type='file']),
.contact-form .hs-form-field textarea {
  background: none;
  border: none;
  border-bottom: 1px solid var(--color-white);
  border-radius: 0;
  color: var(--color-white);
  font-family: var(--font-family-default);
  font-size: var(--font-size-20);
  letter-spacing: var(--letter-spacing-for-20-2);
  line-height: 1.2;
  outline: none;
  padding-bottom: var(--space-between-xxxsmall);
  padding-left: 0;
  width: 100%;
}

.contact-form .hs-form-field input::-webkit-input-placeholder {
  color: var(--color-white);
  opacity: 1;
}

.contact-form .hs-form-field input::-ms-input-placeholder {
  color: var(--color-white);
  opacity: 1;
}

.contact-form .hs-form-field input::placeholder {
  color: var(--color-white);
  opacity: 1;
}

.contact-form .hs-form-field textarea {
  background-image: linear-gradient(transparent, transparent calc(32px - 1px), var(--color-white) 0px);
  background-size: 100% 32px;
  border-bottom: 0;
  line-height: 32px;
  min-height: 100px;
}

.contact-form .hs-form-field textarea::-webkit-input-placeholder {
  opacity: 1;
}

.contact-form .hs-form-field textarea::-ms-input-placeholder {
  opacity: 1;
}

.contact-form .hs-form-field textarea::placeholder {
  opacity: 1;
}

.contact-form--inverted .hs-form-field input {
  border-bottom: 1px solid var(--color-white);
  color: var(--color-white);
}

.contact-form label.hs-error-msg,
.contact-form .hs-error-msgs {
  color: #f2545b;
  display: block;
  font-family: var(--font-family-default);
  font-size: var(--font-size-16);
  margin: 10px 0 0;
}

.contact-form .hs-richtext p,
.contact-form--inverted .hs-richtext p {
  font-size: 16px;
  line-height: 30px;
}

.contact-form .hs-richtext {
  color: var(--color-black);
  font-family: var(--font-family-default);
  letter-spacing: var(--letter-spacing-for-16);
}

.contact-form--inverted .hs-richtext {
  color: var(--color-white);
}

.contact-form .hs-button {
  background-color: var(--color-white);
  border: var(--dimension-border) solid var(--color-white);
  color: var(--color-black);
  cursor: pointer;
  font-family: var(--font-family-default);
  font-size: var(--font-size-18);
  font-weight: 300;
  height: 64px;
  letter-spacing: var(--letter-spacing-for-18);
  line-height: var(--line-height-for-18);
  padding-left: var(--space-between-xsmall);
  padding-right: var(--space-between-xsmall);
  transition: background var(--transition-opacity-button);
}

.contact-form .hs-button:hover {
  background: none;
  background-color: var(--color-black);
  border: var(--dimension-border) solid var(--color-white);
  color: var(--color-white);
  transition: background var(--transition-opacity-button);
}

.contact-form .form-columns-1 .hs-phone .input {
  margin-right: 16px;
}

.contact-form .form-columns-1 .input input[type='tel'] {
  width: 50%;
}

.contact-form .hs-form-field .input input[type='file'] {
  cursor: pointer;
  font-family: var(--font-family-default);
  font-size: var(--font-size-20);
  letter-spacing: var(--letter-spacing-for-20-2);
  padding-bottom: var(--space-between-xxxsmall);
  padding-top: var(--space-between-xxxsmall);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.contact-form .hbspt-form form > fieldset:nth-child(6) > div > div:nth-child(2) > div {
  margin-top: 10px;
}

.contact-form fieldset.form-columns-1 .hs-input:not([type='checkbox']),
.contact-form fieldset.form-columns-2 .hs-input:not([type='checkbox']) {
  border-radius: 0;
  width: 100% !important;
}

.contact-form ul.inputs-list {
  margin-top: var(--space-between-xxsmall);
  padding-left: 0;
}

.contact-form .inputs-list label {
  align-items: center;
  color: var(--color-white);
  display: flex;
  position: relative;
}

.contact-form .inputs-list input {
  /* opacity: 0; */
  position: absolute;
}

.contact-form .inputs-list {
  cursor: pointer;
  display: block;
  padding-left: 35px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkmark {
  background-color: var(--color-white);
  border-radius: 50%;
  cursor: pointer;
  height: 25px;
  left: 0;
  position: absolute;
  top: 0;
  width: 25px;
}

.contact-form .inputs-list input:checked ~ .checkmark {
  background-color: var(--color-white);
}

.checkmark:after {
  content: '';
  cursor: pointer;
  display: none;
  position: absolute;
}

.contact-form .inputs-list input:checked ~ .checkmark:after {
  display: block;
}

.contact-form .checkmark:after {
  background: var(--color-black);
  border-radius: 50%;
  height: 8px;
  left: 9px;
  top: 9px;
  width: 8px;
}

.contact-form fieldset.form-columns-1 .input {
  margin-right: 0 !important;
}

.contact-form fieldset.form-columns-2 div:not(:last-child) .input {
  margin-right: 40px !important;
}

.contact-form fieldset select {
  -webkit-appearance: none;
  background: none;
  border: none;
  border-bottom: 1px solid black;
  font-size: var(--font-size-20);
  outline: none;
  padding: var(--space-between-xxxsmall) 0;
  position: relative;
}

.contact-form.contact-form--inverted fieldset select {
  border-bottom: 1px solid var(--color-white);
  color: var(--color-white);
}

.contact-form fieldset .input {
  align-items: center;
  display: flex;
}

.contact-form fieldset .input:has(select) {
  position: relative;
}

.contact-form fieldset .input:has(select)::after {
  content: '+';
  font-size: var(--font-size-24);
  position: absolute;
  right: 0;
}

.contact-form .hs-form-field > label {
  display: block;
  padding-bottom: 5px;
}

.hs-button.primary.large {
  display: flex;
  height: 64px;
  justify-content: flex-start;
  padding: 0 0 0 var(--space-between-xsmall);
  width: 240px;
}

.hs-button.primary.large::before {
  color: white;
  content: '→';
  width: 400px;
}

.hs_submit input:not([value='→']) {
  margin-top: var(--space-between-small);
  min-width: 180px;
}

.contact-form .hs-submit input {
  font-size: var(--font-size-20);
  position: relative;
  text-align: left;
  top: var(--space-between-xxxxsmall);
}

@media (max-width: 1024px) {
  .contact-form fieldset.form-columns-2 .hs-form-field,
  .contact-form fieldset.form-columns-1 .hs-input[type='email'] {
    width: 100% !important;
  }

  .contact-form form fieldset {
    margin-bottom: 15px;
  }

  .contact-form fieldset.form-columns-2 div:not(:last-child) .input {
    margin-right: 0px !important;
  }

  .form-columns-2 div:first-child {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .contact-form .contact-form__content .hbspt-form form fieldset .hs-form-field .hs-input:not([type='checkbox']),
  .contact-form .form-columns-1 .input input[type='tel'] {
    width: 100%;
  }

  .contact-form .hs-form-field .input input[type='file'] {
    font-size: var(--font-size-16);
    width: 100%;
  }

  .hs_firstname {
    margin-bottom: 50px;
  }

  .hs_email {
    margin-bottom: 0;
  }

  .contact-form .hbspt-form form fieldset .input,
  .contact-form .hbspt-form fieldset .hs-form-field .hs-input,
  .contact-form .form-columns-1 .hs-phone .input {
    margin: 0;
  }
}

/* Change Autocomplete styles in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-black);
}

/* Change Automcomplete Styles for inverted form */
.contact-form--inverted input:-webkit-autofill,
.contact-form--inverted input:-webkit-autofill:hover,
.contact-form--inverted input:-webkit-autofill:focus,
.contact-form--inverted textarea:-webkit-autofill,
.contact-form--inverted textarea:-webkit-autofill:hover,
.contact-form--inverted textarea:-webkit-autofill:focus,
.contact-form--inverted select:-webkit-autofill,
.contact-form--inverted select:-webkit-autofill:hover,
.contact-form--inverted select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-white);
}

/* Fixed checkbox */
.contact-form__content .hs-form-field input[type='checkbox'] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: 1px solid var(--color-white);
  border-radius: 90%;
  cursor: pointer;
  height: 18px;
  margin-left: 0;
  margin-right: -10px;
  margin-top: 0;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 8px;
  position: relative;
  transition: background 0.2s ease-in-out;
}

.contact-form__content .hs-form-field input[type='checkbox']:checked {
  background: var(--color-white);
}

/* Form width 100% */
.hbspt-form fieldset {
  max-width: 100% !important;
}
