.picture {
  overflow: hidden;
  position: relative;
}

.picture picture {
  display: block;
  transition: opacity var(--transition-timing-slow) ease-in-out var(--transition-timing-slow);
}

.picture img {
  width: 100%;
}

.picture--mounted picture {
  opacity: 0;
}

.picture--mounted .picture__preload {
  opacity: 1;
}

.picture--loaded picture {
  opacity: 1;
}

.picture__preload {
  display: flex;
  -webkit-filter: blur(8px);
          filter: blur(8px);
  height: 100%;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.5s ease-in-out 0.5s;
  width: 100%;
  z-index: 10;
}

.picture--loaded .picture__preload {
  opacity: 0;
}

.picture[class*='picture--ratio-'] picture::before {
  content: '';
  display: block;
  height: 0;
  position: relative;
}

.picture[class*='picture--ratio-'] img {
  height: auto;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.picture--ratio-9-16 picture::before {
  padding-bottom: calc(var(--aspect-ratio-9-16) * 100%);
}

.picture--ratio-3-4 picture::before {
  padding-bottom: calc(var(--aspect-ratio-3-4) * 100%);
}

.picture--ratio-16-9 picture::before {
  padding-bottom: calc(var(--aspect-ratio-16-9) * 100%);
}

.picture--ratio-4-3 picture::before {
  padding-bottom: calc(var(--aspect-ratio-4-3) * 100%);
}

.picture--ratio-3-2 picture::before {
  padding-bottom: calc(var(--aspect-ratio-3-2) * 100%);
}
