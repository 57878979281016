.whatsnew .divider__item {
  padding-bottom: var(--space-between-small);
  padding-top: var(--space-between-small);
}

.whatsnew .slider__buttons {
  display: none;
}

.whatsnew .row {
  margin-bottom: var(--space-between-medium);
}

.whatsnew .whatsnew__slider {
  width: 100%;
}

.whatsnew .whatsnew__title {
  padding-bottom: var(--space-between-medium);
}

@media screen and (min-width: 769px) {
  .whatsnew .slider__buttons {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 34%;
    width: 100%;
  }

  .whatsnew .slider__buttons .slider__button-next {
    left: 100%;
    -webkit-transform: translateX(calc(-100% - var(--space-between-xxlarge)));
            transform: translateX(calc(-100% - var(--space-between-xxlarge)));
    z-index: 1;
  }

  .whatsnew .slider__buttons .slider__button-previous {
    left: 0;
    -webkit-transform: translateX(var(--space-between-large));
            transform: translateX(var(--space-between-large));
    z-index: 1;
  }

  .whatsnew .slider__buttons .slider__button-next.is-disabled,
  .whatsnew .slider__buttons .slider__button-previous.is-disabled {
    visibility: hidden;
  }

  .whatsnew .slider__buttons .button {
    background-color: var(--color-white);
    border: var(--dimension-border) solid var(--color-white);
    border-radius: 50%;
    color: var(--color-black);
    padding: var(--space-between-medium);
  }

  .whatsnew .slider__buttons .button:hover {
    background-color: var(--color-black);
    border: var(--dimension-border) solid var(--color-black);
    color: var(--color-white);
  }

  .whatsnew .column {
    width: 100%;
  }

  .whatsnew .row {
    grid-column-start: 2;
    margin-bottom: var(--space-between-xlarge);
  }

  .whatsnew .whatsnew__title {
    padding-bottom: var(--space-between-xlarge);
  }

  .whatsnew .divider__item {
    padding-bottom: var(--space-between-medium);
    padding-top: var(--space-between-medium);
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .whatsnew .slider__buttons {
    top: 30%;
  }
}
