.generic-items > * {
  margin-bottom: var(--space-between-xxlarge);
  margin-top: var(--space-between-xxlarge);
}

.generic-items .divider {
  margin-bottom: var(--space-between-large);
  margin-top: var(--space-between-xxxlarge);
}

.generic-items .divider + * {
  margin-top: var(--space-between-large);
}

.generic-items > :first-child {
  margin-top: 0;
}
