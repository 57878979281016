.job-position__generic-items {
  margin-top: var(--space-between-xxlarge);
}

.job-position__form {
  background: var(--color-white);
  justify-content: center;
  margin-left: calc(var(--grid-full-row-margin) + var(--grid-row-half-gutter));
  margin-right: calc(var(--grid-full-row-margin) + var(--grid-row-half-gutter));
  padding-bottom: var(--space-between-xxlarge);
  padding-top: var(--space-between-xlarge);
}

.job-position__form > div {
  width: 100%;
}

.job-position__form .inline-button {
  margin-bottom: var(--space-between-medium);
}

@media (min-width: 1440px) {
  .job-position__form .white-list__column {
    padding-left: var(--grid-row-half-gutter);
    padding-right: var(--grid-row-half-gutter);
  }
  .job-position__form .white-list__column > div {
    width: 100%;
  }
}