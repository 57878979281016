.header-menu {
  align-items: center;
  background: var(--color-black);
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: space-around;
  margin-top: calc(var(--dimension-header-height) * -1);
  overflow: auto;
  padding-bottom: 0;
  padding-top: var(--space-between-xlarge);
}

.header-menu > div {
  width: 100%;
}

.header-menu__top-wrapper {
  align-items: center;
  height: calc(var(--dimension-header-height) / 2);
  justify-content: space-between;
  margin-top: var(--space-between-xxxxsmall);
}

.header--inverted .header-menu {
  background-color: var(--color-white);
}

.header-menu__footer-wrapper {
  flex-direction: column;
  flex-wrap: nowrap;
  grid-gap: var(--space-between-medium);
  gap: var(--space-between-medium);
  justify-content: space-between;
}

.header-menu::before {
  background: var(--color-black);
  content: '';
  height: 101vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: -1;
}

.header--inverted .header-menu::before {
  background: var(--color-white);
}

.header-menu__logo {
  height: var(--dimension-logo-height);
  margin-top: var(--space-between-xxxsmall);
  width: var(--dimension-logo-width);
}

.header-menu__items {
  width: 100%;
}

.header-menu__item-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.header-menu__item {
  margin-bottom: var(--space-between-small);
}

.header-menu__email {
  margin-right: var(--grid-row-quarter-gutter);
  padding: var(--space-between-xxsmall) var(--grid-row-quarter-gutter) 0;
}

.header-menu__footer-wrapper-contact {
  text-align: center;
}

.header--inverted.header--opened * {
  color: var(--color-black);
  fill: var(--color-black);
}

.header-menu__contact-title,
.header-menu__social-title {
  display: block;
  margin-bottom: var(--space-between-xsmall);
}

@media screen and (min-width: 768px) {
  .header-menu {
    height: 80vh;
    padding-bottom: var(--space-between-xlarge);
    padding-top: calc(var(--space-between-medium) + var(--dimension-header-height));
  }
  .header-menu__item {
    margin-bottom: var(--space-between-medium);
  }

  .header-menu__item a {
    text-indent: calc(var(--space-between-xxxxsmall) * -1);
  }

  .header-menu__top-wrapper {
    height: var(--dimension-header-height);
    margin-top: calc(var(--dimension-header-height) * -1);
  }

  .header-menu__item-wrapper {
    align-items: center;
    flex-direction: row;
  }

  .header-menu__footer-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .header-menu__footer-wrapper .column {
    width: 40%;
  }

  .header-menu__footer-wrapper .column:last-child {
    width: 60%;
  }
}

@media screen and (min-width: 1280px) {
  .header-menu__footer-wrapper .column {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .header-menu {
    --header-menu-padding-top: 90px;
    --header-menu-min-height: 300px;
    height: 55vh;
    min-height: calc(var(--header-menu-min-height) + var(--header-menu-padding-top));
    padding-top: var(--header-menu-padding-top);
  }
  .header-menu__contact-title,
  .header-menu__social-title {
    font-size: var(--font-size-20);
  }

  .header-menu__footer-wrapper {
    grid-gap: 45px;
    gap: 45px;
  }
}

@media screen and (max-width: 767px) and (max-height: 700px) {
  .header-menu__social {
    display: none;
  }
}
