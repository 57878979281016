.business-branches {
  margin-bottom: calc(var(--space-between-medium) * -1);
}

.business-branches__item {
  margin-bottom: var(--space-between-medium);
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .column.grid__item.business-branches__item:nth-child(odd) {
    padding-left: 0;
  }
}

@media screen and (min-width: 1023px) and (max-width: 1279px) {
  .column.grid__item.business-branches__item:nth-child(even) {
    padding-right: 0;
  }
  .column.grid__item.business-branches__item:nth-child(odd) {
    padding-left: 0;
  }
}

@media screen and (min-width: 1280px) {
  .column.grid__item.business-branches__item:first-child {
    padding-left: 0;
  }

  .column.grid__item.business-branches__item:last-child {
    padding-right: 0;
  }
}
