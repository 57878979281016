.stats-summary {
  margin-bottom: calc(var(--space-between-small) * -1);
}

.stats-summary__item {
  margin-bottom: var(--space-between-small);
}

.stats-summary__item-title {
  border-top: var(--dimension-border) solid var(--color-white);
  margin-bottom: var(--space-between-xxsmall);
  padding-top: var(--space-between-xxxsmall);
}
