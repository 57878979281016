.footer {
  --color-white: #131313;
  --color-black: #fff;
  --color-error: #fff;

  background: var(--color-black);
  border-top: 1px solid var(--color-white);
  color: var(--color-white);
  margin-left: calc(var(--grid-full-row-margin) + var(--grid-row-half-gutter));
  margin-right: calc(var(--grid-full-row-margin) + var(--grid-row-half-gutter));
  padding-top: var(--space-between-xlarge);
}

.body--inverted .footer {
  --color-white: #fff;
  --color-black: #131313;
  --color-error: #131313;
}

.footer__title {
  padding-bottom: var(--space-between-medium);
}

.footer__row {
  padding-bottom: 0;
}

.footer__column {
  padding-bottom: var(--space-between-large);
}

/* .button */
.footer__row .footer__column .button {
  height: 64px;
  justify-content: flex-start;
  padding: 0 0 0 var(--space-between-xsmall);
  width: 240px;
}

/* gdpr */
.footer__gdpr {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: var(--space-between-large);
  padding-top: var(--space-between-medium);
  position: relative;
}

.footer__gdpr::before {
  background: var(--color-white);
  content: '';
  height: 1px;
  left: var(--grid-row-half-gutter);
  position: absolute;
  top: 0;
  width: calc(100% - var(--grid-row-gutter));
}

.footer__copyright {
  padding-right: var(--space-between-small);
}

.footer__legal-link {
  padding-right: var(--space-between-small);
}

/* social links */
.footer__social-links {
  justify-content: flex-start;
  padding-top: var(--space-between-xxsmall);
}

.footer__link {
  display: block;
  padding-top: var(--space-between-xxsmall);
}

/* contact form */
.footer__contact-form {
  padding-top: var(--space-between-xxsmall);
}

.footer__contact-form .contact-form__content {
  margin-top: 0;
}

.footer__contact-form .hs-form {
  font-size: var(--font-size-20);
  position: relative;
}

.footer__contact-form .hs-form .hs_email .hs-input {
  padding-bottom: var(--space-between-xxsmall);
  width: 100%;
}

.footer__contact-form .hs-form .hs_email .hs-input::-webkit-input-placeholder {
  color: var(--color-white);
}

.footer__contact-form .hs-form .hs_email .hs-input::-ms-input-placeholder {
  color: var(--color-white);
}

.footer__contact-form .hs-form .hs_email .hs-input::placeholder {
  color: var(--color-white);
}

.footer__contact-form .hs-form .legal-consent-container {
  font-size: var(--font-size-16);
}

.footer__contact-form .hs-form-field:not(.hs-fieldtype-booleancheckbox) {
  padding-bottom: var(--space-between-xxsmall);
}

.footer__contact-form .hs-form .legal-consent-container input[type='checkbox'] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 1px solid var(--color-white);
  border-radius: 90%;
  cursor: pointer;
  height: 18px;
  margin-left: 0;
  margin-right: -10px;
  margin-top: 0;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 8px;
  position: relative;
  top: 2px;
  transition: ease-in-out 0.2s;
}

.footer__contact-form .hs-form .legal-consent-container input[type='checkbox']:checked {
  background: var(--color-white);
}

.footer__contact-form .hs-form .hs-submit {
  position: absolute;
  right: 0;
  top: 0;
}

.footer__contact-form .hs-form .hs-submit .actions .hs-button {
  background: none;
  border: 0;
  color: var(--color-white);
  height: auto;
  padding-right: 0;
  transition: none;
  width: auto;
  z-index: 1;
}

.footer--hide-footer-fields {
  border-top: none;
  padding-top: var(--space-between-xxsmall);
}

@media screen and (min-width: 769px) {
  .footer__row {
    padding-bottom: var(--space-between-large);
  }

  .footer__column {
    padding-bottom: 0;
  }
}
