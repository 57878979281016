.subtitle__row {
  position: relative;
}

.subtitle--with-separator .subtitle__row {
  padding-top: var(--space-between-small);
}

.subtitle--with-separator .subtitle__row::before {
  background: var(--color-white);
  content: '';
  height: 1px;
  left: var(--grid-row-half-gutter);
  position: absolute;
  top: 0;
  width: calc(100% - var(--grid-row-gutter));
}

.subtitle__row .column {
  width: 100%;
}

.subtitle__cta {
  margin-top: var(--space-between-small);
}

.subtitle__aside-title:not(:first-of-type) {
  display: block;
  margin-top: var(--space-between-xsmall);
  width: 100%;
}

.subtitle__aside-text {
  display: block;
  margin-top: var(--space-between-xxxsmall);
  width: 100%;
}

@media screen and (max-width: 1439px) {
  .subtitle .content-with-sidebar__left {
    margin-bottom: 0;
    margin-top: var(--space-between-xxlarge);
  }
}

@media screen and (max-width: 1439px) {
  .case-study .subtitle {
    flex-direction: column-reverse;
  }
}