.newsletter-popup {
  background-color: var(--color-white);
  border-color: var(--color-black);
  border-radius: var(--space-between-small);
  color: var(--color-black);
  max-width: 510px;
  width: 100%;
}

.newsletter-popup:focus,
.newsletter-popup.focus-visible {
  border: none;
}

.newsletter-popup:focus,
.newsletter-popup:focus-visible {
  border: none;
}

.newsletter-popup .iconography {
  fill: var(--color-black);
}

.newsletter-popup div.grid .modal-top {
  display: flex;
  justify-content: space-between;
}

.newsletter-popup .modal-top__title > * {
  display: block;
  margin-bottom: var(--space-between-xxxsmall);
}

.newsletter-popup .modal-top__title .typography--big-body {
  font-size: var(--font-size-20);
}

.newsletter-popup .modalClose {
  cursor: pointer;
}

.newsletter-popup div.grid:nth-child(1) {
  padding: var(--space-between-small);
}

/* newsletter popup contact form */
.newsletter-popup__contact-form {
  color: var(--color-black);
  padding-top: var(--space-between-xxsmall);
}

.newsletter-popup__contact-form .contact-form__content {
  margin-top: 0;
}

.newsletter-popup__contact-form .hs-form {
  font-size: var(--font-size-20);
  position: relative;
}

.newsletter-popup__contact-form .hs-form .hs_email .hs-input {
  border-bottom: 1px solid var(--color-black);
  color: var(--color-black);
  padding-bottom: var(--space-between-xxsmall);
  width: 100%;
}

.newsletter-popup__contact-form .hs-form .hs_email .hs-input::-webkit-input-placeholder {
  color: var(--color-black);
}

.newsletter-popup__contact-form .hs-form .hs_email .hs-input::-ms-input-placeholder {
  color: var(--color-black);
}

.newsletter-popup__contact-form .hs-form .hs_email .hs-input::placeholder {
  color: var(--color-black);
}

.newsletter-popup__contact-form .hs-form .legal-consent-container {
  font-size: var(--font-size-16);
}

.newsletter-popup__contact-form .hs-form .legal-consent-container .hs-form-booleancheckbox-display p {
  color: var(--color-black);
}

.newsletter-popup__contact-form .hs-form .legal-consent-container .hs-form-booleancheckbox-display .hs-form-required {
  color: var(--color-black);
}

.newsletter-popup__contact-form .hs-form .hs-submit {
  position: absolute;
  right: 0;
  top: 0;
}

.newsletter-popup__contact-form .hs-form .hs-submit .actions .hs-button {
  background: none;
  border: 0;
  color: var(--color-black);
  font-size: 24px;
  height: auto;
  height: 37px;
  min-width: 32px;
  padding-right: 0;
  transition: none;
  z-index: 1;
}

.newsletter-popup__contact-form .hs-form-field input[type="checkbox"] {
  border-color: var(--color-black);
}

.newsletter-popup__contact-form .hs-form-field input[type="checkbox"]:checked {
  background: var(--color-black);
}

.newsletter-popup .hs-form-field:not(.hs-fieldtype-booleancheckbox) {
  padding-bottom: 16px;
}

.newsletter-popup .hs-submit {
  margin-top: 0;
}
