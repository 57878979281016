.counter-component__column-content {
  font-family: 'JetBrains Mono', monospace;
}

.counter-component__column-pretitle,
.counter-component__column-content,
.counter-component__column-postitle {
  display: block;
}

.counter-component__colum-people {
  margin-bottom: var(--space-between-large);
}

.counter-component__column-content {
  color: var(--color-landing);
}

.counter-component__row-top {
  margin-bottom: var(--space-between-large);
}

.counter-component__drop-list-item {
  margin: var(--space-between-xxxsmall);
  position: relative;
}

.counter-component__drop-list-item,
.counter-component__drop-list-item-icon {
  --height: calc(var(--width) * 589 / 384);

  fill: var(--color-landing);
  height: var(--height);
  width: var(--width);
}

.counter-component__drop-list-item-tooltip {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  visibility: hidden;
  z-index: 1;
}

.counter-component__drop-list-item:hover .counter-component__drop-list-item-tooltip {
  visibility: visible;
}

.counter-component__column-content-items {
  display: flex;
  flex-wrap: wrap;
}

.counter-component__column-content-item {
  min-width: 50%;
  width: 50%;
}

@media screen and (min-width: 375px) {
  .counter-component__colum-people,
  .counter-component__colum-counter {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .counter-component__column-content-item {
    min-width: 25%;
    width: 25%;
  }
}

@media screen and (min-width: 1024px) {
  .counter-component-two-columns .counter-component__colum-people,
  .counter-component-two-columns .counter-component__colum-counter {
    width: 50%;
  }
}