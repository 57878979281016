.contact {
  background: var(--color-white);
  color: var(--color-black);
  margin-top: var(--space-between-xxxlarge);
  padding-bottom: var(--space-between-xxlarge);
  padding-top: var(--space-between-large);
}

.contact > * {
  width: 100%;
}

div:has(#find-us) {
  margin-bottom: 0;
}

#oportunidades {
  margin-bottom: -1px;
}

div:has(#oportunidades) {
  margin-bottom: 0px;
}