.image-full {
  display: flex;
  flex-direction: column;
}

.image-full__caption {
  padding-bottom: 0;
  padding-top: var(--space-between-xxsmall);
}

.image-full__caption p:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .image-full--with-caption {
    flex-direction: row;
  }

  .image-full--with-caption .image-full__picture {
    width: 75%;
  }

  .image-full--with-caption .image-full__caption {
    padding: 0 0 0 var(--grid-row-half-gutter);
    width: 25%;
  }
}
