.image-content__content {
  padding-left: var(--grid-row-half-gutter);
  padding-right: var(--grid--half-gutter);
}

.image-content__title {
  display: block;
  padding-bottom: var(--space-between-medium);
  padding-top: var(--space-between-xlarge);
}

.image-content__links {
  padding-top: var(--space-between-large);
}

.image-content__link {
  margin-bottom: var(--space-between-small);
}

@media screen and (min-width: 1024px) {
  .image-content {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
  }

  .image-content__wrapper {
    width: calc((5 / 12) * 100%);
  }

  .image-content__content {
    width: calc((6 / 12) * 100%);
  }

  .image-content__links {
    display: flex;
  }

  .image-content__links > * {
    width: calc((1 / 3) * 100%);
  }
}
