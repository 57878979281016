.location-component__title {
  margin-bottom: var(--space-between-small);
}

.location-component__places-item {
  display: flex;
  padding-bottom: var(--space-between-xsmall);
}

.location-component__place-icon {
  height: 16px;
  margin-right: var(--space-between-xxxsmall);
  padding-top: var(--space-between-xxxxsmall);
  width: 10px;
}

.location-component__place-title {
  font-weight: bold;
}

.location-component__place-title,
.location-component__place-place {
  margin-bottom: var(--space-between-xxxsmall);
}

.location-component__place-title,
.location-component__place-place,
.location-component__place-schedule {
  display: block;
  width: 100%;
}
