.language-selector {
  text-align: center;
}

.language-selector__option {
  color: var(--color-alto);
  cursor: pointer;
  position: relative;
}

.language-selector__option:before {
  background-color: var(--color-white);
  bottom: -2px;
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left;
          transform-origin: left;
  transition: var(--transition-all);
  visibility: hidden;
  width: 100%;
}

.language-selector__option--selected:before,
.language-selector__option:hover:before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  visibility: visible;
}

.language-selector__option:not(:last-child) {
  margin-right: var(--grid-row-quarter-gutter);
}

.language-selector__icon {
  display: inline-block;
  margin-right: calc(var(--grid-row-quarter-gutter) / 2);
  -webkit-transform: rotate(-45deg) translateY(2%);
          transform: rotate(-45deg) translateY(2%);
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.language-selector__icon--open {
  -webkit-transform: rotate(0) translateY(0);
          transform: rotate(0) translateY(0);
}

@media screen and (min-width: 1280px) {
  .language-selector {
    margin-bottom: 0;
    text-align: left;
  }
}
