.column {
  max-width: 100%;
  padding-left: var(--grid-row-half-gutter);
  padding-right: var(--grid-row-half-gutter);
}

@media screen and (min-width: 1280px) {
  .column--50 {
    width: 50%;
  }
}