.list-grid__description:not(:last-child),
.list-grid__item {
  margin-bottom: var(--space-between-small);
}

.list-grid__grid-wrapper {
  margin-bottom: calc(var(--space-between-small) * -1);
}

.list-grid__cta {
  margin-top: var(--space-between-small);
}

.list-grid .content-with-sidebar__content > .column {
  padding: 0;
}