.iconography {
  display: inline-block;
  fill: var(--color-white);
  flex-shrink: 0;
  height: var(--iconography-size-default);
  transition: var(--transition-fill);
  width: var(--iconography-size-default);
}

.iconography--big {
  height: var(--iconography-size-big);
  width: var(--iconography-size-big);
}
