.list-image-content__title {
  display: block;
  padding-bottom: var(--space-between-xlarge);
}

.list-image-content__grid-item {
  margin-bottom: var(--space-between-xlarge);
}

.list-image-content__wrapper {
  height: var(--dimension-list-image-content-image);
  margin-bottom: var(--space-between-xsmall);
  width: var(--dimension-list-image-content-image);
}

.list-image-content__item-title {
  display: block;
  margin-bottom: var(--space-between-xsmall);
}
