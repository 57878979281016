.teamtailor .teamtailor__column {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.teamtailor__wrapper {
  background: var(--color-white);
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  padding: var(--space-between-large) 0;
}

.teamtailor__title {
  margin-bottom: var(--space-between-large);
}

.teamtailor__wrapper .contact-form__title {
  display: block;
  margin-bottom: var(--space-between-xxsmall);
  margin-top: var(--space-between-small);
}

.teamtailor-jobs__filters {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.teamtailor__form .teamtailor-jobs__select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: var(--space-between-xxxsmall) var(--space-between-small);
}

.teamtailor__form .teamtailor-jobs__select-wrapper {
  position: relative;
}

.teamtailor__form .teamtailor-jobs__select.focus-visible {
  outline: none;
}

.teamtailor__form .teamtailor-jobs__select:focus-visible {
  outline: none;
}

.teamtailor__form .teamtailor-jobs__select-wrapper::before {
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: '';
  height: 8px;
  position: absolute;
  right: 4px;
  top: 35%;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  width: 8px;
  z-index: -1;
}

.teamtailor__form .teamtailor-jobs__job-wrapper {
  border-bottom: 1px solid var(--color-black);
  border-top: 1px solid var(--color-black);
  min-height: var(--space-between-medium);
  width: 100%;
}

/* .teamtailor__form .teamtailor-jobs__job-wrapper .teamtailor-jobs__job:nth-child(even) {
  background-color: var(--color-black);
  color: var(--color-white);
} */

.teamtailor__form .teamtailor-jobs__job-wrapper .teamtailor-jobs__job:hover {
  padding-left: var(--space-between-small);
}

.teamtailor__form .teamtailor-jobs__job-wrapper .teamtailor-jobs__job:hover {
  background-color: var(--color-black);
  color: var(--color-white);
}

/* .teamtailor__form .teamtailor-jobs__job-wrapper .teamtailor-jobs__job:nth-child(even):hover {
  background-color: var(--color-white);
  color: var(--color-black);
} */

/* .teamtailor__form .teamtailor-jobs__job-wrapper .teamtailor-jobs__job:nth-child(odd):hover {
  background-color: var(--color-black);
  color: var(--color-white);
} */

.teamtailor__form .teamtailor-jobs__job {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
  padding: var(--space-between-xsmall) 0 var(--space-between-xsmall) var(--space-between-xsmall);
  position: relative;
  transition: all var(--transition-timing-default);
  width: 100%;
  z-index: 1;
}

.teamtailor__form .teamtailor-jobs__job:not(:first-child) {
  border-top: 1px solid var(--color-black);
}

.teamtailor__form .teamtailor-jobs__job::after {
  background: var(--color-black);
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: top center;
          transform-origin: top center;
  transition: -webkit-transform var(--transition-timing-default) cubic-bezier(0.4, 0, 0, 1);
  transition: transform var(--transition-timing-default) cubic-bezier(0.4, 0, 0, 1);
  transition: transform var(--transition-timing-default) cubic-bezier(0.4, 0, 0, 1), -webkit-transform var(--transition-timing-default) cubic-bezier(0.4, 0, 0, 1);
  width: 100%;
  z-index: -1;
}

.teamtailor__form .teamtailor-jobs__job:hover::after {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

/* .teamtailor__form .teamtailor-jobs__job-wrapper .teamtailor-jobs__job:nth-child(even)::after {
  background: var(--color-white);
  color: var(--color-black);
} */

.teamtailor__form .teamtailor-jobs__job-title {
  cursor: pointer;
  font-size: calc(var(--font-size-18) + 2 * var(--proportional-width-for-responsive));
  letter-spacing: var(--letter-spacing-for-18);
  line-height: var(--line-height-for-18);
  margin-bottom: var(--space-between-xxsmall);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.teamtailor__form .teamtailor-jobs__job-info {
  font-size: var(--font-size-16);
  letter-spacing: var(--letter-spacing-for-16);
  line-height: var(--line-height-for-16);
}

.teamtailor__form {
  margin-bottom: calc(var(--space-between-small));
  margin-top: var(--space-between-small);
  width: 100%;
}

.white-list__content-teamtailor {
  margin-bottom: var(--space-between-small);
  margin-top: var(--space-between-small);
}

.teamtailor .inline-button,
.teamtailor .inline-button--with-hover:before {
  --color-black: #fff;
  --color-white: #131313;
}

@media screen and (min-width: 1920px) {
  .teamtailor__form .teamtailor-jobs__select {
    font-size: var(--font-size-20);
    letter-spacing: var(--letter-spacing-for-20);
    line-height: var(--line-height-for-20);
  }
}

@media screen and (min-width: 768px) {
  .teamtailor-jobs__filters {
    flex-direction: row;
  }

  .teamtailor .teamtailor__column {
    padding-left: var(--grid-row-half-gutter);
    padding-right: var(--grid-row-half-gutter);
  }
}

@media screen and (min-width: 320px) {
  .teamtailor__form .teamtailor-jobs__select {
    font-size: calc(var(--font-size-18) + 2 * var(--proportional-width-for-responsive));
    letter-spacing: var(--letter-spacing-for-18);
    line-height: var(--line-height-for-18);
  }
}
