.white-list__wrapper {
  background: var(--color-white);
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  padding: var(--space-between-large) 0;
}

.white-list__wrapper .white-list__form {
  --color-black: #fff;
  --color-white: #000;
}

.white-list__form .contact-form--inverted {
  --color-black: #000;
  --color-white: #fff;
}

.white-list__column {
  padding-left: var(--grid-row-half-gutter);
  padding-right: var(--grid-row-half-gutter);
  width: 100%;
}

.white-list__wrapper .contact-form__title {
  display: block;
  margin-bottom: var(--space-between-xxsmall);
  margin-top: var(--space-between-small);
}

.white-list__title,
.white-list__element:not(:last-child) {
  margin-bottom: var(--space-between-large);
}

.white-list__element-wrapper {
  margin-bottom: calc(var(--space-between-small) * -1);
  margin-top: var(--space-between-small);
}

.white-list__element-item {
  border-bottom: var(--dimension-border) solid var(--color-black);
  padding: var(--space-between-xsmall) 0;
  position: relative;
  z-index: 1;
}

.white-list__element-item-text {
  display: inline-block;
  transition: padding-left var(--transition-timing-default) cubic-bezier(0.4, 0, 0, 1);
  width: 100%;
}

.white-list__element-item::after {
  background: var(--color-black);
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: top center;
          transform-origin: top center;
  transition: -webkit-transform var(--transition-timing-default) cubic-bezier(0.4, 0, 0, 1);
  transition: transform var(--transition-timing-default) cubic-bezier(0.4, 0, 0, 1);
  transition: transform var(--transition-timing-default) cubic-bezier(0.4, 0, 0, 1), -webkit-transform var(--transition-timing-default) cubic-bezier(0.4, 0, 0, 1);
  width: 100%;
  z-index: -1;
}

.white-list__element-item:hover .typography {
  color: var(--color-white);
  z-index: 2;
}

.white-list__element-item:hover .white-list__element-item-text {
  padding-left: var(--grid-row-half-gutter);
}

.white-list__element-item:hover::after {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.white-list__element-wrapper a {
  display: block;
}

.white-list__element-wrapper a:first-child {
  border-top: var(--dimension-border) solid var(--color-black);
}

#oportunidades > .white-list__column {
  padding-left: 0;
  padding-right: 0;
}

@media screen and (min-width: 768px) {
  #oportunidades > .white-list__column {
    margin-left: var(--grid-row-half-gutter);
    margin-right: var(--grid-row-half-gutter);
  }
  .white-list__element-item-text {
    width: 75%;
  }
}
