.business-branch__title-wrapper {
  margin-bottom: var(--space-between-small);
  position: relative;
}

.business-branch__title-wrapper::before {
  content: '';
  display: block;
  padding-bottom: 63%;
  position: relative;
}

.business-branch__title {
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.business-branch__title-background {
  fill: none;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  stroke: var(--color-white);
  top: 0;
  width: 100%;
}

.business-branch__title-background svg {
  height: 100%;
  width: 100%;
}

.business-branch__element:not(:last-child) {
  margin-bottom: var(--space-between-xxsmall);
}

@media screen and (min-width: 1024px) {
  .business-branch__title-wrapper::before {
    padding-bottom: 110%;
  }
}
