.video--with-ratio {
  position: relative;
}

.video--with-ratio::before {
  content: '';
  display: block;
  padding-top: calc(var(--aspect-ratio-16-9) * 100%);
}

.video--with-ratio .video__player {
  left: 0;
  position: absolute;
  top: 0;
}
