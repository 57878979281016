.donotmiss__label {
  padding-top: var(--space-between-xxsmall);
  text-transform: uppercase;
}

.donotmiss__title {
  padding-bottom: var(--space-between-medium);
}

.donotmiss__item {
  padding-bottom: var(--space-between-large);
  padding-top: var(--space-between-xsmall);
  position: relative;
}

.donotmiss__item::before {
  background: var(--color-white);
  content: '';
  height: 1px;
  left: var(--grid-row-half-gutter);
  position: absolute;
  top: 0;
  width: calc(100% - var(--grid-row-gutter));
}

.donotmiss__content {
  padding-top: var(--space-between-xxsmall);
  position: relative;
}

.donotmiss .divider__item {
  padding-top: var(--space-between-small);
}

@media screen and (min-width: 769px) {
  .donotmiss__label {
    padding-top: var(--space-between-xsmall);
  }

  .donotmiss__title {
    padding-bottom: var(--space-between-xlarge);
  }

  .donotmiss__item {
    padding-top: var(--space-between-xsmall);
  }

  .donotmiss__content {
    padding-top: var(--space-between-xsmall);
  }

  .donotmiss .divider__item {
    padding-bottom: var(--space-between-xlarge);
    padding-top: var(--space-between-medium);
  }
}