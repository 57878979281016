.content-with-sidebar__left:not(:empty) {
  margin-bottom: var(--space-between-xxsmall);
}

.content-with-sidebar__left,
.content-with-sidebar__right,
.content-with-sidebar__content {
  width: 100%;
}

@media screen and (min-width: 1440px) {
  .content-with-sidebar__left:not(:empty) {
    margin-bottom: 0;
  }

  .content-with-sidebar__left,
  .content-with-sidebar__right {
    flex: 1;
    flex-direction: column;
    width: auto;
  }

  .content-with-sidebar__content {
    flex-grow: 1;
    flex-shrink: 0;
    max-width: var(--grid-row-width);
  }
}
