.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--space-between-xxsmall);
}

.content h1 {
  font-size: var(--font-size-56);
  letter-spacing: var(--letter-spacing-for-56);
  line-height: var(--line-height-for-56);
}

.content h2 {
  font-size: var(--font-size-40);
  letter-spacing: var(--letter-spacing-for-40);
  line-height: var(--line-height-for-40);
}

.content h3,
.content h4,
.content h5,
.content h6 {
  font-size: var(--font-size-32);
  letter-spacing: var(--letter-spacing-for-32);
  line-height: var(--line-height-for-32);
}

.content dd:not(:last-child),
.content ul:not(:last-child),
.content ol:not(:last-child),
.content p:not(:last-child) {
  margin-bottom: var(--space-between-small);
}

.content strong {
  font-weight: var(--font-weight-bold);
}

.content dt {
  font-size: var(--font-size-16);
  letter-spacing: var(--letter-spacing-for-16);
  line-height: var(--line-height-for-16);
  margin-bottom: var(--space-between-xxsmall);
}

.content hr {
  border: 1px solid var(--color-alto);
  margin-bottom: var(--space-between-xxsmall);
  margin-top: var(--space-between-xxsmall);
}

.content ul {
  list-style-type: disc;
  list-style-type: initial;
  padding-left: var(--grid-row-half-gutter);
}

.content ol {
  list-style-type: decimal;
  padding-left: var(--grid-row-half-gutter);
}

.content li:not(:last-child) {
  margin-bottom: var(--space-between-xxxsmall);
}

.content sup {
  font-size: smaller;
  position: relative;
  top: -6px;
  vertical-align: baseline;
}

.content a {
  cursor: pointer;
  position: relative;
}

.content a:before {
  background-color: var(--color-white);
  bottom: 0;
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left;
          transform-origin: left;
  transition: var(--transition-all);
  visibility: hidden;
  width: 100%;
}

.content a:hover:before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  visibility: visible;
}

@media screen and (min-width: 320px) {
  .content h1 {
    font-size: calc(var(--font-size-40) + 56 * var(--proportional-width-for-responsive));
    letter-spacing: calc(var(--letter-spacing-for-40) - 2.4 * var(--proportional-width-for-responsive));
    line-height: calc(var(--line-height-for-40) + 63 * var(--proportional-width-for-responsive));
  }

  .content h2 {
    font-size: calc(var(--font-size-28) + 28 * var(--proportional-width-for-responsive));
    letter-spacing: calc(var(--letter-spacing-for-28) - 0.75 * var(--proportional-width-for-responsive));
    line-height: calc(var(--line-height-for-28) + 36 * var(--proportional-width-for-responsive));
  }

  .content h3,
  .content h4,
  .content h5,
  .content h6 {
    font-size: calc(var(--font-size-24) + 16 * var(--proportional-width-for-responsive));
    letter-spacing: calc(var(--letter-spacing-for-24) - 0.4 * var(--proportional-width-for-responsive));
    line-height: calc(var(--line-height-for-24) + 20 * var(--proportional-width-for-responsive));
  }

  .content dt {
    font-size: calc(var(--font-size-16) + 4 * var(--proportional-width-for-responsive));
    letter-spacing: calc(var(--letter-spacing-for-16) - 0.24 * var(--proportional-width-for-responsive));
    line-height: calc(var(--line-height-for-16) + 4 * var(--proportional-width-for-responsive));
  }
}

@media screen and (min-width: 1920px) {
  .content h1 {
    font-size: var(--font-size-96);
    letter-spacing: var(--letter-spacing-for-96);
    line-height: var(--line-height-for-96);
  }

  .content h2 {
    font-size: var(--font-size-56);
    letter-spacing: var(--letter-spacing-for-56);
    line-height: var(--line-height-for-56);
  }

  .content h3,
  .content h4,
  .content h5 {
    font-size: var(--font-size-40);
    letter-spacing: var(--letter-spacing-for-40);
    line-height: var(--line-height-for-40);
  }

  .content dt {
    font-size: var(--font-size-20);
    letter-spacing: var(--letter-spacing-for-20);
    line-height: var(--line-height-for-20);
  }
}
