.resource-preview-title {
  text-transform: uppercase;
}

.resource-preview-container {
  padding-bottom: var(--space-between-large);
  padding-top: var(--space-between-medium);
  position: relative;
}

.resource-preview-container::before {
  background: var(--color-white);
  content: '';
  height: 1px;
  left: var(--grid-row-half-gutter);
  position: absolute;
  top: 0;
  width: calc(100% - var(--grid-row-gutter));
}