.inline-button {
  background-color: transparent;
  border: none;
  color: var(--color-white);
  cursor: pointer;
  display: inline-block;
  margin: -2px 0 2px;
  outline: 0;
  padding: 0;
}

.inline-button--with-hover {
  cursor: pointer;
  position: relative;
}

.inline-button--with-hover:before {
  background-color: var(--color-white);
  bottom: -8px;
  content: '';
  height: 1px;
  left: 0%;
  position: absolute;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: right;
          transform-origin: right;
  transition: var(--transition-all);
  visibility: visible;
  width: 100%;
}

a:hover .inline-button--with-hover:before,
.inline-button--with-hover:hover:before {
  -webkit-animation: testingAnimation 1s;
          animation: testingAnimation 1s;
}

.body--inverted .header .inline-button--with-hover:before {
  background-color: var(--color-black);
}

.inline-button--inverted.inline-button--with-hover:before {
  background-color: var(--color-black);
}

.inline-button--inverted {
  color: var(--color-black);
}

.inline-button:disabled {
  opacity: 0.25;
  pointer-events: none;
}

.inline-button--line-on-hover::before {
  visibility: hidden;
}

a:hover .inline-button--line-on-hover:before,
.inline-button--line-on-hover:hover:before {
  visibility: visible;
}

@-webkit-keyframes testingAnimation {
  0% {
    left: 0;
    width: 100%;
  }

  49% {
    left: 100%;
    width: 0;
  }

  50% {
    left: 0;
    width: 0;
  }

  100% {
    left: 0;
    width: 100%;
  }
}

@keyframes testingAnimation {
  0% {
    left: 0;
    width: 100%;
  }

  49% {
    left: 100%;
    width: 0;
  }

  50% {
    left: 0;
    width: 0;
  }

  100% {
    left: 0;
    width: 100%;
  }
}
