.inline-tag {
  background-color: transparent;
  border: 1px solid var(--color-white);
  border-radius: 16px;
  color: var(--color-white);
  cursor: pointer;
  display: inline-block;
  margin: 2px 0 2px;
  outline: 0;
  padding: 2px 12px;
  text-transform: uppercase;
}

.inline-tag--with-hover {
  cursor: pointer;
  position: relative;
}

.body--inverted .header .inline-tag--with-hover:before {
  background-color: var(--color-black);
}

.header a:hover .inline-tag--with-hover:before,
.footer a:hover .inline-tag--with-hover:before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  visibility: visible;
}

.inline-tag--inverted.inline-tag--with-hover:before {
  background-color: var(--color-black);
}

.inline-tag--with-hover:hover {
  background-color: var(--color-white);
  color: var(--color-black);
}

.inline-tag--inverted {
  background-color: var(--color-white);
  color: var(--color-black);
}

.inline-tag:disabled {
  opacity: 0.25;
  pointer-events: none;
}
