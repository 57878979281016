.header {
  position: fixed;
  top: 0;
  transition: var(--transition-transform);
  width: 100%;
  z-index: var(--z-index-menu);
}

.header--inverted {
  position: absolute;
}

.header::before {
  background: linear-gradient(
    to bottom,
    hsl(0, 0%, 0%) 0%,
    hsla(0, 0%, 0%, 0.987) 2.4%,
    hsla(0, 0%, 0%, 0.951) 6%,
    hsla(0, 0%, 0%, 0.896) 10.7%,
    hsla(0, 0%, 0%, 0.825) 16.3%,
    hsla(0, 0%, 0%, 0.741) 22.6%,
    hsla(0, 0%, 0%, 0.648) 29.6%,
    hsla(0, 0%, 0%, 0.55) 37.1%,
    hsla(0, 0%, 0%, 0.45) 45%,
    hsla(0, 0%, 0%, 0.352) 53.1%,
    hsla(0, 0%, 0%, 0.259) 61.4%,
    hsla(0, 0%, 0%, 0.175) 69.7%,
    hsla(0, 0%, 0%, 0.104) 77.8%,
    hsla(0, 0%, 0%, 0.049) 85.6%,
    hsla(0, 0%, 0%, 0.013) 93.1%,
    hsla(0, 0%, 0%, 0) 100%
  );
  content: '';

  /* height: calc(var(--dimension-header-height) * 2.5); */
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: var(--transition-opacity);
  width: 100%;
  z-index: -1;
}

.header--inverted::before {
  content: none;
}

.header--hidden {
  -webkit-transform: translateY(calc(var(--dimension-header-height) * -1));
          transform: translateY(calc(var(--dimension-header-height) * -1));
}

.header--hidden::before {
  opacity: 0;
}

.header__nav {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  height: var(--dimension-header-height);
  justify-content: center;
}

.header__nav > .column {
  z-index: 1;
}

.header--opened {
  height: calc(var(--dimension-header-height) * 2.5);
}

@media screen and (min-width: 768px) {
  .header--opened {
    cursor: url('./../../svg/close-wide.svg') 32 32, auto;
  }
}
