.header-menu-toggle .iconography:not(.header-menu-toggle__logo) {
  cursor: pointer;
}

.header-menu-toggle__menu,
.header-menu-toggle__logo {
  height: var(--dimension-logo-height);
  width: var(--dimension-logo-width);
}

.header-menu-toggle__menu svg :first-child {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
  transition: var(--transition-transform-fast);
}

.header-menu-toggle__menu svg :last-child {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  transition: var(--transition-transform-fast);
}

.header-menu-toggle__menu:hover svg :first-child {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.header-menu-toggle__menu:hover svg :last-child {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
