.row {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.row .row {
  margin-left: calc(var(--grid-row-half-gutter) * -1);
  margin-right: calc(var(--grid-row-half-gutter) * -1);
  width: calc(100% + var(--grid-row-gutter));
}

.row--top-32 {
  margin-top: var(--space-between-medium);
}

.row--bottom-32 {
  margin-bottom: var(--space-between-medium);
}

.row--top-80 {
  margin-top: var(--space-between-xlarge);
}

@media screen and (min-width: 768px) {
  .row {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--grid-row-width);
  }
}
