.relation__grid {
  margin-bottom: calc(var(--space-between-small) * -1);
}

.relation .section__header {
  margin-bottom: var(--space-between-xxlarge);
}

.relation__grid-item {
  margin-bottom: var(--space-between-small);
}
